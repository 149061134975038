import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebcaseEmailusCompleteModalComponent } from './webcase-emailus-complete-modal.component';
import { ModalModule } from 'navigation/nav-shared/components/modal/modal.module';

@NgModule({
  declarations: [WebcaseEmailusCompleteModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [WebcaseEmailusCompleteModalComponent],
})
export class WebcaseEmailusCompleteModalModule {}
