import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultBannerComponent } from './default-banner.component';
import { AcgModule } from '../../acg/acg.module';

@NgModule({
  declarations: [DefaultBannerComponent],
  imports: [CommonModule, AcgModule],
  exports: [DefaultBannerComponent],
})
export class DefaultBannerModule {}
