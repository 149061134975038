import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ModalService } from 'navigation/nav-shared/components/modal/modal.service';

@Component({
  selector: 'app-footer-subscribe-modal',
  templateUrl: './footer-subscribe-modal.component.html',
})
export class FooterSubscribeModalComponent implements AfterViewInit {
  @Input() status: string;

  @Output() onClose = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {}

  ngAfterViewInit(): void {
    this.modalService.open('footer-subscribe-modal');
  }

  dismissModal() {
    this.onClose.emit(true);
    this.modalService.close('footer-subscribe-modal');
  }

  handleModalOnClose(evt) {
    if (evt) {
      this.onClose.emit(true);
    }
  }

  getModalStyle(): string {
    if (this.status === 'success') {
      return 'color: #155724; background-color: #d4edda; border-color: #c3e6cb';
    } else {
      return 'color: #721c24; background-color: #f8d7da; border-color: #f5c6cb';
    }
  }
}
