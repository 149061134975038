import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '',
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
