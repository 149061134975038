import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOCUMENT } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import * as dayjs from 'dayjs';

import { DropdownForm } from '../../../form-elements.interface';
import { environment } from '../../../../environments/environment';
import { HeaderService } from '../../../header/header.service';
import { NavigationService } from '../../../../navigation/nav-shared/navigation.service';
import { pushDataLayer } from 'navigation/nav-shared/helpers/googleAnalytics';
import {
  addDashesToPhone,
  createCookie,
  excludedValue,
  getCookie,
} from '../../helpers';
import {
  emailValidator,
  isValidName,
  zipCodeValidator,
} from '../../helpers/customValidators';
import {
  AveryCustomGenericConfig,
  DotCMSGraphQLACGConfigPayload,
} from 'navigation/nav-shared/models/interfaces';
import { FreeSamplePackageCompleteModal } from '../free-sample-pack-complete-modal/free-sample-pack-complete-modal.model';
@Component({
  selector: 'app-free-sample-pack',
  templateUrl: './free-sample-pack.component.html',
  styleUrls: ['./free-sample-pack.component.scss'],
})
export class FreeSamplePackComponent implements OnInit, OnDestroy {
  @Input() messagetypes: string[] = ['promotions'];
  @Input() type: string;
  @Input() sendemail2consumer: string;
  @Input() skipmagentoflow: string = 'N';
  @Input() skipsamplerequestdynamoinsert: string = 'N';
  @Input() skipsugarcrm: string = 'N';
  @Output() oCompleteStatus = new EventEmitter<string>();
  @Output() openedCompleteModal = new EventEmitter<
    FreeSamplePackageCompleteModal
  >();

  // store all ACG contents related weprint sample packs
  wpSamplePackACGContents: AveryCustomGenericConfig;

  cookieNameSamplePack: string = 'stonehenge_1530057785_gblsp';
  dropdownAllData: any;
  dropdownIndustry: DropdownForm[];
  dropdownReferral: DropdownForm[];
  dropdownUSA: DropdownForm[];
  fspForm: FormGroup;
  phone: string;
  trackingPixel: Renderer2;
  sampleImgUrl: string = `${environment.image}/f_auto,q_auto,c_scale,w_200/web/weprint/wp-sample-pack.jpg`;
  samplePkType: string = 'sheet';
  showLimitFirstName: boolean;
  showLimitLastName: boolean;
  srcImg: string = `${environment.image}/web/weprint`;
  togglesData: any;
  togglesRolls: boolean;
  togglesSheets: boolean;
  togglesValidationText: string;
  togglesPleaseChooseText: string;
  validFirstName: boolean = true;
  validLastName: boolean = true;
  subscriptions: Array<Subscription> = [];

  // Message Flags
  isInvalidFormMessage: boolean = false;
  isVisible: boolean = false;
  isZipFormatMessage: boolean = false;
  rollsRadioSelected: boolean = false;
  samplePackBtnSubmitted: boolean = false;
  samplePackLoader: boolean = false;

  // Required Field Form Flags
  isSamplePackSelected: boolean = true; // Set to true if the check boxes have not been touched initially
  isInvalidCheckName: boolean = false;
  isInvalidCheckLastName: boolean = false;
  isInvalidCheckAddress1: boolean = false;
  isInvalidCheckCity: boolean = false;
  isInvalidCheckState: boolean = false;
  isInvalidCheckZip: boolean = false;
  isInvalidCheckEmail: boolean = false;
  isInvalidCheckPhone: boolean = false;
  isInvalidCheckIndustry: boolean = false;

  // For Phone Numbers
  isPhoneNumberFormatMessage: boolean = false;

  constructor(
    @Inject(DOCUMENT) private _document,
    private fb: FormBuilder,
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private renderer: Renderer2,
    private http: HttpClient
  ) {}

  ngOnInit() {
    pushDataLayer({
      event: 'weprint-sample-modal-form-open',
    });

    this.http
      .post<DotCMSGraphQLACGConfigPayload>(
        `${environment.domain}/api/v1/graphql`,
        {
          query: `{
            averyCustomGenericConfigCollection(query: "+(AveryCustomGenericConfig.name_dotraw:wp-sample-pack)") {
              name
              acgs{
                name
                body
              }
            }
          }`,
        }
      )
      .pipe(first())
      .subscribe((dataPayload) => {
        this.wpSamplePackACGContents =
          dataPayload.data.averyCustomGenericConfigCollection[0];
      });

    this.getSamplePackFormData();
    this.getSamplePackConfigData();
    this.subscriptions = this.createFreeSamplePackForm();
  }

  ngOnDestroy() {
    if (this.trackingPixel) {
      // Remove the tracking pixel ONLY if it was created. This would be when the user has succesfully submitted the sample pack.
      this.renderer.removeChild(this._document.body, this.trackingPixel);
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the form controls and returns an array of subscription for all
   * value changes on some of the form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof FreeSamplePackComponent
   */
  createFreeSamplePackForm(): Array<Subscription> {
    this.fspForm = this.fb.group({
      firstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      companyName: [''],
      address1: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      address2: [''],
      cityName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      stateName: [
        'State',
        Validators.compose([Validators.required, excludedValue(/State/i)]),
      ],
      zipCode: [
        '',
        Validators.compose([Validators.required, zipCodeValidator]),
      ],
      emailAddress: [
        '',
        Validators.compose([Validators.required, emailValidator]),
      ],
      phoneNumber: ['', Validators.compose([Validators.required])],
      industryField: [
        'Choose One',
        Validators.compose([Validators.required, excludedValue(/Choose One/i)]),
      ],
      referralSource: ['Choose One'],
      optIn: [true],
    });
    return [
      this.fspForm.get('firstName').valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitFirstName = true;
        } else {
          this.showLimitFirstName = false;
        }
        this.validFirstName = isValidName(data);
      }),
      this.fspForm.get('lastName').valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitLastName = true;
        } else {
          this.showLimitLastName = false;
        }
        this.validLastName = isValidName(data);
      }),
    ];
  }
  // Form getters
  get firstName(): AbstractControl {
    return this.fspForm.get('firstName');
  }
  get stateName(): AbstractControl {
    return this.fspForm.get('stateName');
  }
  get industryField(): AbstractControl {
    return this.fspForm.get('industryField');
  }

  /**
   * Logic that determines which button to highlight
   *
   * @param {string} format
   * @returns {boolean}
   * @memberof FreeSamplePackComponent
   */
  highlightFormat(format: string): boolean {
    return this.samplePkType === format;
  }

  /**
   * Users are required to toggle at least one Sample Pack layout.
   * The Check fails if no sample pack is toggled.
   *
   * @param {string} format
   * @memberof FreeSamplePackComponent
   */
  checkSelectedSamplePacks(format: string) {
    this.samplePkType = format;
    if (format === '' || isEmpty(format)) {
      this.isSamplePackSelected = false;
    } else {
      this.isSamplePackSelected = true;
    }
  }

  /**
   * Checks whether to display Invalid Sample Pack form message.
   *
   * @memberof FreeSamplePackComponent
   */
  checkFormValidationMessage() {
    if (this.isSamplePackSelected && this.fspForm.valid) {
      this.isInvalidFormMessage = false;
    } else {
      this.isInvalidFormMessage = true;
    }
  }

  /**
   * Checks if the Zip Code is in the correct US foramt.
   *
   * @memberof FreeSamplePackComponent
   */
  checkZipFormat() {
    if (has(this.fspForm.get('zipCode').errors, 'invalidZip')) {
      this.isZipFormatMessage = this.fspForm.get('zipCode').errors.invalidZip;
    } else {
      this.isZipFormatMessage = false;
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   * If no parameter is supplied, it will check all Sample Pack required form fields.
   *
   * @param {string} formControlName [Optional]
   * @memberof FreeSamplePackComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'firstName':
        this.isInvalidCheckName = !this.firstName.valid;
        break;
      case 'lastName':
        this.isInvalidCheckLastName = !this.fspForm.get('lastName').valid;
        break;
      case 'address1':
        this.isInvalidCheckAddress1 = !this.fspForm.get('address1').valid;
        break;
      case 'cityName':
        this.isInvalidCheckCity = !this.fspForm.get('cityName').valid;
        break;
      case 'stateName':
        const state = this.dropdownUSA.find(
          (stateCode: DropdownForm) => stateCode.value === this.stateName.value
        );
        this.isInvalidCheckState = this.stateName.touched && state.disable;
        break;
      case 'zipCode':
        this.isInvalidCheckZip = !this.fspForm.get('zipCode').valid;
        break;
      case 'emailAddress':
        this.isInvalidCheckEmail = !this.fspForm.get('emailAddress').valid;
        break;
      case 'phoneNumber':
        this.isInvalidCheckPhone = !this.fspForm.get('phoneNumber').valid;
        break;
      case 'industryField':
        const industry = this.dropdownIndustry.find(
          (industryDropdown: DropdownForm) =>
            industryDropdown.value === this.industryField.value
        );
        this.isInvalidCheckIndustry =
          this.industryField.touched && industry.disable;
        break;
      default:
        // Check All of the the Required Fields:
        this.isInvalidCheckName = !this.firstName.valid;
        this.isInvalidCheckLastName = !this.fspForm.get('lastName').valid;
        this.isInvalidCheckAddress1 = !this.fspForm.get('address1').valid;
        this.isInvalidCheckCity = !this.fspForm.get('cityName').valid;
        this.isInvalidCheckState = !this.stateName.touched;
        this.isInvalidCheckZip = !this.fspForm.get('zipCode').valid;
        this.isInvalidCheckEmail = !this.fspForm.get('emailAddress').valid;
        this.isInvalidCheckPhone = !this.fspForm.get('phoneNumber').valid;
        this.isInvalidCheckIndustry = !this.industryField.touched;
    }
  }

  /**
   * This function adds dashes for the entered phone number which in this case 2 dashes.
   *
   * @param {Event} event
   * @memberof FreeSamplePackComponent
   */
  dashPhone(event: Event) {
    let phone = (event.target as HTMLInputElement).value;
    this.phone = addDashesToPhone(phone);

    // Updates the form with the latest value of phone after the reformat.
    this.fspForm.get('phoneNumber').setValue(this.phone);
  }

  /**
   * Verifies that the Phone number follows the specified format.
   * It will clear out the user input if invalid, because the phone number is optional.
   *
   * @memberof FreeSamplePackComponent
   */
  formatPhone() {
    const phone = this.fspForm.get('phoneNumber').value;
    if (phone.length === 0 || phone.length === 12) {
      this.isPhoneNumberFormatMessage = false;
    } else if (phone.length < 12) {
      this.isPhoneNumberFormatMessage = true;
    }
  }

  /**
   * Sample pack submit form function using topic/queue based flow.
   *
   * @memberof FreeSamplePackComponent
   */
  fspSubmitToTopic() {
    this.checkSelectedSamplePacks(this.samplePkType);
    this.checkFormValidationMessage();

    // Form must be valid, and user must select at least 1 sample pack upon submission.
    if (
      this.isSamplePackSelected &&
      this.fspForm.valid &&
      this.validFirstName &&
      this.validLastName
    ) {
      if (!isEmpty(this.fspForm)) {
        let modalCompletionType = 'ok';
        const errorCode = '';

        if (getCookie(this.cookieNameSamplePack) === null) {
          this.samplePackBtnSubmitted = true;
          this.samplePackLoader = true;
          this.isVisible = true;

          // Calls endpoint to publish this request to an SNS topic
          this.headerService
            .addSampleToTopic(this.setEmailServiceFormat(this.fspForm))
            .subscribe(
              (resp) => {
                createCookie(
                  this.cookieNameSamplePack,
                  dayjs().unix(),
                  1,
                  '.avery.com'
                );
                this.openCompleteModal(errorCode, modalCompletionType);
              },
              (error) => {
                console.error(error);
                modalCompletionType = 'error';
                this.openCompleteModal('500', modalCompletionType);
              }
            );
        } else {
          modalCompletionType = 'shipped';
          this.openCompleteModal(errorCode, modalCompletionType);
        }
      }
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * This function opens a completion modal to render the status of the request.
   *
   * @param {string} [errorCode='']
   * @param {string} modalCompletionType
   * @memberof FreeSamplePackComponent
   */
  openCompleteModal(errorCode: string = '', modalCompletionType: string) {
    // turns off the spinner
    this.isVisible = false;
    if (this.samplePkType === 'roll') {
      this.setTrackingPixel('Roll Label Sample Pack');
    } else {
      this.setTrackingPixel('Sheet Label and Cards Sample Pack');
    }

    if (isEmpty(this.type)) {
      this.openedCompleteModal.emit({
        modalCompletionType: modalCompletionType,
        errorCode: errorCode,
        samplePkType: this.samplePkType,
      });
    } else {
      this.oCompleteStatus.emit(modalCompletionType);
    }
  }

  /**
   * Adds Tracking Pixel. To be called on successful Sample Pack submission.
   *
   * @param {string} samplePackType
   * @memberof FreeSamplePackComponent
   */
  setTrackingPixel(samplePackType: string) {
    // Default: Sheets
    let pixel =
      'https://16.xg4ken.com/pixel/v1?track=1&token=45558d75-65e0-453f-9636-44bb17e27dbf&conversionType=WePrint_Sheet_Samp&revenue=0.0&currency=USD&orderId=&promoCode=&customParam1=&customParam2=';

    if (samplePackType === 'Roll Label Sample Pack') {
      pixel =
        'https://16.xg4ken.com/pixel/v1?track=1&token=45558d75-65e0-453f-9636-44bb17e27dbf&conversionType=WePrint_Roll_Samp&revenue=0.0&currency=USD&orderId=&promoCode=&customParam1=&customParam2=';
    }

    this.trackingPixel = this.renderer.createElement('img');
    this.renderer.setAttribute(this.trackingPixel, 'src', pixel);
    this.renderer.setAttribute(this.trackingPixel, 'width', '1');
    this.renderer.setAttribute(this.trackingPixel, 'height', '1');
    this.renderer.appendChild(this._document.body, this.trackingPixel);
  }

  /**
   * This function will used by the sample pack email service endpoint.
   *
   * @param {FormGroup} form
   * @returns {any}
   * @memberof FreeSamplePackComponent
   */
  setEmailServiceFormat(form: FormGroup): any {
    const samplePackData = {
      email: this.fspForm.controls['emailAddress'].value,
      firstName: this.firstName.value,
      lastName: this.fspForm.controls['lastName'].value,
      optIn: this.fspForm.controls['optIn'].value,
      formType:
        this.samplePkType === 'roll'
          ? 'Rolls SampleRequest'
          : 'Sheets SampleRequest',
      subtype: this.samplePkType,
      sourceId: this.samplePkType === 'roll' ? '21964' : '64409',
      messageTypes: this.messagetypes,
      country: 'US',
      spName: this.samplePkType,
      sendEmail2Consumer: this.sendemail2consumer,
      skipSugarcrm: this.skipsugarcrm,
      skipSampleRequestDynamoInsert: this.skipsamplerequestdynamoinsert,
      skipMagentoFlow: this.skipmagentoflow,
    };
    return {
      ...samplePackData,
      ...this.navigationService.setSamplePackOtherFields(form, true),
    };
  }

  /**
   * This function formats the object for the sample request API call.
   *
   * @param {FormGroup} form
   * @returns {string}
   * @memberof FreeSamplePackComponent
   */
  setSampleReqCustomerDb(form: FormGroup): string {
    const samplePackData = {
      email: this.fspForm.controls['emailAddress'].value,
      firstName: this.firstName.value,
      lastName: this.fspForm.controls['lastName'].value,
      optIn: this.fspForm.controls['optIn'].value,
      formType:
        this.samplePkType === 'roll'
          ? 'Rolls SampleRequest'
          : 'Sheets SampleRequest',
      sourceId: this.samplePkType === 'roll' ? '21964' : '64409',
      messageTypes: this.messagetypes,
      country: 'US',
      complete: true,
    };
    const newObj = {};
    const aryStrings = [
      'companyName',
      'address1',
      'address2',
      'cityName',
      'stateName',
      'zipCode',
      'industryField',
      'referralSource',
      'phoneNumber',
    ];
    const aryAttributes = [
      'company',
      'address1',
      'address2',
      'city',
      'region',
      'postcode',
      'industry',
      'hearAboutUs',
      'phone',
    ];
    aryStrings.forEach((element, idx) => {
      if (!isEmpty(this.fspForm.controls[element].value)) {
        newObj[aryAttributes[idx]] = this.fspForm.controls[element].value;
      }
    });
    return JSON.stringify({ ...samplePackData, ...newObj });
  }

  /**
   * Get sample pack data for form dropdowns (state, industry, referral)
   *
   * @memberof FreeSamplePackComponent
   */
  async getSamplePackFormData() {
    try {
      this.dropdownAllData = await this.navigationService.getSamplePackData();
      if (!isEmpty(this.dropdownAllData)) {
        this.dropdownIndustry = this.dropdownAllData.industryForm;
        this.dropdownReferral = this.dropdownAllData.referralSourcesForm;
        this.dropdownUSA = this.dropdownAllData.usStatesForm;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.navigationService
        .fetchSamplePackData()
        .pipe(filter((results: any) => !isEqual(results, this.dropdownAllData)))
        .subscribe((data) => {
          this.navigationService.setSamplePackData(data);
          this.dropdownAllData = data;
          this.dropdownIndustry = this.dropdownAllData.industryForm;
          this.dropdownReferral = this.dropdownAllData.referralSourcesForm;
          this.dropdownUSA = this.dropdownAllData.usStatesForm;
        });
    }
  }

  /**
   * Get sample pack config data
   *
   * @memberof FreeSamplePackComponent
   */
  async getSamplePackConfigData() {
    try {
      this.togglesData = await this.navigationService.getSamplePackToggles();
    } catch (e) {
      console.error(e);
    } finally {
      this.navigationService.fetchSamplePackToggles().subscribe((data) => {
        this.navigationService.setSamplePackToggles(data);
        this.togglesData = data;
        this.togglesRolls = this.togglesData.toggleFlags.rolls === 'show';
        this.togglesSheets = this.togglesData.toggleFlags.sheets === 'show';
        this.togglesValidationText = this.togglesData.togglesValidationText
          ? this.togglesData.togglesValidationText
          : '';
        this.togglesPleaseChooseText = this.togglesData.togglesPleaseChooseText
          ? this.togglesData.togglesPleaseChooseText
          : '';
      });
    }
  }

  getAcgContent(name: string): string {
    const foundAcg = this.wpSamplePackACGContents.acgs.find(
      (eachAcg) => eachAcg.name === name
    );
    if (foundAcg) return foundAcg.body;
    return '';
  }
}
