import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountMenuComponent } from './my-account-menu.component';
import { SignInFormModule } from '../../nav-shared/components/user-forms/sign-in-form/sign-in-form.module';

@NgModule({
  declarations: [MyAccountMenuComponent],
  imports: [CommonModule, SignInFormModule],
  exports: [MyAccountMenuComponent],
})
export class MyAccountMenuModule {}
