import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavSharedModule } from '../../nav-shared/nav-shared.module';
import { MegaMenuModule } from './mega-menu/mega-menu.module';

import { HeaderService } from '../header.service';

import { AcgService } from 'navigation/nav-shared/services/acg.service';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';
import { ColumnMenuModule } from './column-menu/column-menu.module';
import { HeaderNavComponent } from './header-nav.component';

@NgModule({
  imports: [
    ColumnMenuModule,
    CommonModule,
    MegaMenuModule,
    NgbModule,
    NavSharedModule,
  ],
  exports: [HeaderNavComponent],
  declarations: [HeaderNavComponent],
  providers: [HeaderService, AcgService, NavDataService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderNavModule {}
