import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailSignupMdComponent } from './email-signup-md.component';
import { ContentLoaderModule } from '../../content-loader/content-loader.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';

@NgModule({
  declarations: [EmailSignupMdComponent],
  imports: [ContentLoaderModule, CommonModule, ReactiveFormsModule],
  exports: [EmailSignupMdComponent],
  providers: [AvryModalService],
})
export class EmailSignupMdModule {}
