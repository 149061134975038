import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { emailValidator } from '../../../helpers/customValidators';
import { FooterSubscribeModalComponent } from '../../../../footer/footer-subscribe-form/footer-subscribe-modal/footer-subscribe-modal.component';

// Services
import { AuthenticationService } from '../../../authentication.service';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import { ModalSize } from 'navigation/nav-shared/models/types';
import { ModalService } from '../../modal/modal.service';
import { emitNewsletterSignUp } from 'navigation/nav-shared/helpers';

@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss'],
})
export class EmailSignupComponent implements OnInit {
  @Input() btntext: string = 'Get my free guide';
  @Input() messagetype: string = 'industrial';
  @Input() modalSize: string = 'lg';
  @Input() placeholder: string = 'Enter Email Address';
  @Input() sourceid: string = '21412';
  @Input() thememode: string = 'default';
  @ViewChild('emailAddress', { static: false }) emailAddress;
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer;

  emailSignupForm: FormGroup;
  isInvalidCheckEmail: boolean = false;
  titleDescriptionContent: SafeHtml;

  constructor(
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private avryModalService: AvryModalService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  /**
   * Create the email signup form.
   *
   * @memberof EmailSignupComponent
   */
  createForm() {
    this.emailSignupForm = this.fb.group({
      emailAddress: [
        '',
        Validators.compose([Validators.required, emailValidator]),
      ],
    });
  }

  get emailControl() {
    return this.emailSignupForm.get('emailAddress');
  }
  /**
   * Validate input email address.
   *
   * @param {string} [formControlName='']
   * @memberof EmailSignupComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'emailAddress':
        this.isInvalidCheckEmail = !this.emailControl.valid;
        break;
      default:
        this.isInvalidCheckEmail = !this.emailControl.valid;
    }
  }

  /**
   * Call backend to process email signup.
   *
   * @memberof EmailSignupComponent
   */
  emailSubmit() {
    if (this.emailAddress.nativeElement.validity.valid) {
      this.isInvalidCheckEmail = false;
      this.authService
        .emailSignUp({
          email: this.emailSignupForm.controls['emailAddress'].value,
          sourceid: this.sourceid,
          optIn: true,
          messageTypes: [this.messagetype],
        })
        .subscribe(
          () => {
            // emit GA event for newsletter sign-up
            emitNewsletterSignUp();

            const modalRef = this.avryModalService.init(
              this.modalContainer,
              FooterSubscribeModalComponent,
              'footer-subscribe-modal',
              this.modalSize as ModalSize
            );
            modalRef.instance.status = 'success';
            // TODO: find out if this service needs to called.
            // this.navService.DYFooterNewsletterSignup();
            setTimeout(
              () => this.modalService.close('footer-subscribe-modal'),
              2000
            );
          },
          (err) => {
            console.error(err);
            if (err.error === 'Email already exists.') {
              status = 'exists';
            } else {
              status = 'error';
            }

            const modalRef = this.avryModalService.init(
              this.modalContainer,
              FooterSubscribeModalComponent,
              'footer-subscribe-modal',
              this.modalSize as ModalSize
            );
            modalRef.instance.status = status;
          }
        );
    } else {
      this.isInvalidCheckEmail = true;
    }
  }
}
