import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternationalVisitorModalComponent } from './international-visitor-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [InternationalVisitorModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [InternationalVisitorModalComponent],
})
export class InternationalVisitorModalModule {}
