import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ModalSize } from 'navigation/nav-shared/models/types';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-footer-subscribe-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent implements AfterViewInit {
  @Input() id: string = 'message-modal';
  @Input() size: ModalSize = 'sm';

  @Input() status: string;
  @Input() aryMessage: Array<any> = [];
  @Input() closeX: boolean = true;

  @Output() result = new EventEmitter<string>();

  constructor(private modalService: ModalService) {}

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  dismissModal() {
    this.result.next(this.status);
    this.modalService.close(this.id);
  }

  handleCloseByDimmerClick() {
    this.result.next(this.status);
  }
}
