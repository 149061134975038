import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterSubscribeModalComponent } from './footer-subscribe-modal.component';
import { ModalModule } from 'navigation/nav-shared/components/modal/modal.module';

@NgModule({
  declarations: [FooterSubscribeModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [FooterSubscribeModalComponent],
})
export class FooterSubscribeModalModule {}
