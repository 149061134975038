import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalSize } from 'navigation/nav-shared/models/types';

import { ModalService } from './modal.service';

@Component({
  selector: 'avry-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() size: ModalSize = 'auto';
  @Input() active: boolean = true;
  @Input() utilClasses: string = '';
  @Input() closeOnDimmerClick: boolean = true;

  @Output() onCloseByDimmerClick = new EventEmitter<any>();

  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute is not null
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    if (this.closeOnDimmerClick) {
      this.element.addEventListener('click', (el) => {
        if (
          el.target.className === 'avry-modal' ||
          el.target.id === 'modal-action-overlay'
        ) {
          this.close();
        }
      });
    }

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    document.body.classList.remove('avry-modal-open');
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('avry-modal-open');
  }

  // close modal
  close(): void {
    this.onCloseByDimmerClick.emit(true);
    this.element.style.display = 'none';
    document.body.classList.remove('avry-modal-open');
    document.body.removeChild(this.element);
  }

  remove() {
    document.body.classList.remove('avry-modal-open');
    this.element.remove();
  }
}
