import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-email-quote-completion',
  templateUrl: './email-quote-completion.component.html',
})
export class EmailQuoteCompletionComponent implements OnInit, AfterViewInit {
  @Input() acgCompletionRequestError: string;
  @Input() acgCompletionRequestSuccess: string;
  @Input() acgCompletionTitle: string;
  @Input() status: string;

  @Output() onClose = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.modalService.open('email-quote-completion-modal');
  }

  /**
   * Closes the modal.
   *
   * @memberof EmailQuoteCompletionComponent
   */
  dismissModal() {
    this.onClose.emit(true);
    this.modalService.close('email-quote-completion-modal');
  }

  handleSelfClosing(evt) {
    if (evt) {
      this.onClose.emit(true);
    }
  }
}
