import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieDisclaimerComponent } from './cookie-disclaimer.component';

@NgModule({
  declarations: [CookieDisclaimerComponent],
  imports: [CommonModule],
  exports: [CookieDisclaimerComponent],
})
export class CookieDisclaimerModule {}
