import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { map, take, tap } from 'rxjs/operators';
import localForage from 'localforage';

@Injectable()
export class NavDataService {
  private BASE_URL: string = `${environment.domain}/app/json`;
  private averyCacheInst: LocalForage = localForage.createInstance({
    name: 'Avery Cache',
  });

  constructor(private http: HttpClient) {}

  async fetchDotCMSJSONData<T>(jsonFileName: string): Promise<T> {
    const fetchData = (): Promise<T> => {
      return this.http
        .get<T>(`${this.BASE_URL}/${jsonFileName}.json`)
        .pipe(
          take(1),
          tap((payload) => {
            // caching data in user browser after fetching data successfully
            this.averyCacheInst.setItem(jsonFileName, payload).catch((err) => {
              console.error('Local stroage is not available');
              console.error(err);
            });
          }),
          map((payload) => {
            return payload;
          })
        )
        .toPromise();
    };

    try {
      let cachedData = await this.averyCacheInst
        .getItem(jsonFileName)
        .then((data) => {
          if (data) {
            setTimeout(() => {
              fetchData();
            }, 20000);

            return data as T;
          }
          data as T;
        })
        .catch((err) => {
          console.error('Local storage is not available');
          console.error(err);
        });

      if (!cachedData) {
        cachedData = await fetchData();
      }

      return cachedData;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchDotCMSData<T>(
    name: string,
    fetchData: () => Promise<T>
  ): Promise<T> {
    try {
      let data;
      try {
        data = await this.averyCacheInst.getItem<T>(name).then((data) => {
          if (data) {
            setTimeout(() => {
              fetchData();
            }, 20000);

            return data as T;
          }
          return null;
        });
      } catch (error) {
        console.log('local storage is not available');
        console.log(error);
      }

      if (!data) {
        data = await fetchData();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
