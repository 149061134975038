import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonTypeaheadComponent } from './common-typeahead.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CommonTypeaheadComponent],
  imports: [CommonModule, NgbTypeaheadModule, FormsModule],
  exports: [CommonTypeaheadComponent],
})
export class CommonTypeaheadModule {}
