// import { Action } from '@ngrx/store';
import { Action } from '../actions';

import { GET_PROFILE_EMAIL, GET_PROFILE_NAME, GET_PROFILE, UPDATE_PROFILE_STATUS } from '../actions'

const l_objProfile = {
  email: '',
  firstName: '',
  lastName: ''
};

const INITIAL_PROFILESTATE = {
  firstName: '',
  lastName: '',
  email: '',
  newEmail: '',
  updateStatus: true,
  profileData: l_objProfile
};

export function FilterProfileState(state = INITIAL_PROFILESTATE, action: Action) {
  switch (action.type) {
    case GET_PROFILE_NAME:
      const l_objProfileName = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        profileData: Object.assign({}, state.profileData, { firstName: action.payload.firstName, lastName: action.payload.lastName})
      };
      return Object.assign({}, state, l_objProfileName);

    case GET_PROFILE_EMAIL:
      const l_objEmail = {
        email: action.payload.email,
        newEmail: action.payload.email,
        profileData: Object.assign({}, state.profileData, {email: action.payload.email})
      };
      return Object.assign({}, state, l_objEmail);
    case GET_PROFILE:
      const l_objProfile = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        profileData: action.payload
      };
      return Object.assign({}, state, l_objProfile);
    case UPDATE_PROFILE_STATUS:
      const l_objStatus = {
        updateStatus: action.payload.profStatusUpdate
      };
      return Object.assign({}, state, l_objStatus);
    default:
      return state;
  }
}
