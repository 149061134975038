import { Component, Input, OnInit } from '@angular/core';

import { NavigationService } from '../../navigation.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'avery-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit {
  environment: any = environment;
  @Input() customLoaderMessage: string = 'Please wait...';
  useSVG: boolean = true;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    if (
      this.navigationService.getClientBrowserName() === 'Microsoft Edge' ||
      this.navigationService.getClientBrowserName() === 'Internet Explorer'
    ) {
      this.useSVG = false;
    }
  }
}
