import isEmpty from 'lodash-es/isEmpty';
import { getCookie, localStorageGetter } from './browserAbstraction';
import {
  ClickProducts,
  DetailViewProducts,
  ProductClick,
  ProductDetailView,
  Promo,
  PromoClick,
  PromoImpression,
} from '../models/interfaces/google-analytics.interface';
import { config } from '../../../environments/environment';
import { UserInfoInterface } from '../models/interfaces';
import has from 'lodash-es/has';

/**
 * Emits data layer initialized event
 *
 * @export
 */
export function emitCoreDataLayer() {
  const path = document.location.pathname.split('/');
  path.shift();
  const consumerData: UserInfoInterface = localStorageGetter('u_i');

  (<any>window).dataLayer.push({
    event: 'dataLayer-initialized',
    siteVersion: config.version,
    language: 'en_us',
    id: has(consumerData, 'consumerId') ? consumerData.consumerId : undefined,
    customerType: undefined,
    loggedIn: !isEmpty(consumerData),
    pageType: determinePageType(path),
  });
}

/**
 * Emit newsletter sign up event when user subscribes to newsletter
 */
export function emitNewsletterSignUp() {
  (<any>window).dataLayer.push({
    event: 'newsletter-sign-up',
  });
}

/**
 * A switch case that determines the GA page type given a path. Should only be utilized by Angular app and not dotcms
 * or magento pages
 *
 * @param {string[]} [path=[]]
 * @returns {(string | undefined)}
 */
function determinePageType(path: string[] = []): string | undefined {
  switch (path[0]) {
    case 'blank': {
      if (
        path[1] === 'shape' ||
        path[1] === 'usage' ||
        path[1] === 'material'
      ) {
        return 'List Page';
      } else if (path[1] === 'labels' && path.length === 3) {
        return 'Product Page';
      }
      break;
    }
    case 'custom-printing': {
      if (path[1] === 'predesign') {
        return 'List Page';
      } else if (path[1] === 'labels' && path[2] === 'calculator') {
        return 'Product Page';
      }
      break;
    }
    case 'myaccount': {
      if (path[1] === 'projects') {
        return 'MyAccount Project Page';
      } else if (path[1] === 'files') {
        return 'MyAccount File Page';
      } else if (path[1] === 'final-print-templates') {
        return 'MyAccount Final Print Templates Page';
      } else if (path[1] === 'orders') {
        return 'MyAccount Order Page';
      } else if (path[1] === 'profile') {
        return 'MyAccount Profile Page';
      } else {
        return 'MyAccount Page';
      }
    }
    case 'predesign': {
      if (path.length === 3) {
        return 'List Page';
      }
      break;
    }
    case 'products': {
      if (
        path.length === 2 ||
        ((path[1] === 'labels' || path[1] === 'cards') && path.length > 3)
      ) {
        return 'List Page';
      } else if (path.length === 3) {
        return 'Product Page';
      }
      break;
    }
    case 'srch': {
      return 'Search Page';
    }
    case 'templates': {
      if (path[1] === 'category') {
        return 'List Page';
      } else if (path.length === 2) {
        return 'Product Page';
      }
      break;
    }
  }

  return undefined;
}

/**
 * Push a new event to the GA dataLayer
 *
 * @export
 * @param {*} [obj={}]
 */
export function pushDataLayer(obj: any = {}) {
  (<any>window).dataLayer = (<any>window).dataLayer || [];
  (<any>window).dataLayer.push(obj);
}

/**
 * GA Product Click event
 *
 * @export
 * @param {string} [field='']
 * @param {ClickProducts[]} [products=[]]
 */
export function emitProductClick(
  field: string = '',
  products: ClickProducts[] = []
) {
  const dataLayer: ProductClick = {
    event: 'product-click',
    ecommerce: {
      currencyCode: 'USD',
      click: {
        actionField: {
          list: field,
        },
        products: products,
      },
    },
  };

  (<any>window).dataLayer.push(dataLayer);
}

/**
 * GA Product Detail View event
 *
 * @export
 * @param {DetailViewProducts[]} [products=[]]
 */
export function emitProductDetailView(products: DetailViewProducts[] = []) {
  const dataLayer: ProductDetailView = {
    event: 'product-detail-view',
    ecommerce: {
      currencyCode: 'USD',
      detail: { products },
    },
  };

  (<any>window).dataLayer.push(dataLayer);
}

/**
 * GA Promo Impressions event
 *
 * @export
 */
export function emitPromoImpressions() {
  // Set a short delay to give time for banner to render in front-end.
  setTimeout(() => {
    const bannerCollection = document.querySelectorAll('[data-promo-id]');

    if (bannerCollection.length > 0) {
      const promoCollection: Promo[] = [];

      for (let i = 0; i < bannerCollection.length; i++) {
        const banner: any =
          bannerCollection[i].tagName.toLowerCase() === 'a'
            ? bannerCollection[i]
            : bannerCollection[i].querySelector('a');
        const href: string =
          banner &&
          banner.getAttribute('href') &&
          banner.getAttribute('href') !== ''
            ? banner.getAttribute('href')
            : null;
        const onClickElement: any = bannerCollection[i].hasAttribute('onclick')
          ? bannerCollection[i]
          : bannerCollection[i].querySelector('[onclick]');
        const promo: Promo = {
          id: bannerCollection[i].getAttribute('data-promo-id'),
          name:
            bannerCollection[i].getAttribute('data-promo-name') || undefined,
          creative:
            bannerCollection[i].getAttribute('data-promo-creative') ||
            undefined,
          position: String(i + 1),
        };

        if (href || onClickElement) {
          banner.addEventListener('click', (evt) => {
            if (href) {
              evt.preventDefault();
              emitPromoClick(promo, href);
            } else {
              emitPromoClick(promo);
            }
          });
        }

        promoCollection.push(promo);
      }

      const dataLayer: PromoImpression = {
        event: 'promotion-impression',
        ecommerce: {
          promoView: { promotions: promoCollection },
        },
      };

      (<any>window).dataLayer.push(dataLayer);
    }
  }, 100);
}

/**
 * GA Promo Click event
 *
 * @param {Promo} promo
 * @param {string} [href=null]
 */
function emitPromoClick(promo: Promo, href: string = null) {
  const dataLayer: PromoClick = {
    event: 'promotion-click',
    ecommerce: {
      promoClick: { promotions: [promo] },
    },
  };

  (<any>window).dataLayer.push(dataLayer);

  // Set a short delay to give time for banner push `emitPromoClick`.
  if (href) {
    setTimeout(() => {
      (<any>window).location.href = href;
    }, 100);
  }
}

/**
 * GA GHS subscription events
 *
 * @export
 * @param {('ghs-subscription-clicked'
 *     | 'ghs-subscription-plan-chosen'
 *     | 'dpo-ghs-subscription-plan-chosen'
 *     | 'ghs-subscription-completed'
 *     | 'dpo-ghs-subscription-completed')} event
 * @param {('monthly' | 'annual')} [billingCycle]
 */
export function emitGhsSubscriptionEvent(
  event:
    | 'ghs-subscription-clicked'
    | 'ghs-subscription-plan-chosen'
    | 'dpo-ghs-subscription-plan-chosen'
    | 'ghs-subscription-completed'
    | 'dpo-ghs-subscription-completed',
  billingCycle?: 'monthly' | 'annual'
) {
  const dataLayer = { event };
  (window as any).dataLayer = (window as any).dataLayer || [];

  if (billingCycle) {
    dataLayer['billing-cycle'] = billingCycle;
  }

  (window as any).dataLayer.push(dataLayer);
}
