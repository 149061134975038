import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossSellSearchBarComponent } from './cross-sell-search-bar.component';
import { CommonTypeaheadModule } from '../common-typeahead/common-typeahead.module';

@NgModule({
  declarations: [CrossSellSearchBarComponent],
  imports: [CommonModule, CommonTypeaheadModule],
  exports: [CrossSellSearchBarComponent],
})
export class CrossSellSearchBarModule {}
