import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';

import unescape from 'lodash-es/unescape';
@Injectable({
  providedIn: 'root'
})
export class EmailSignupService {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }


  /**
   * Get Avery Custom Generic content from dotcms
   *
   * @param {type} string
   * @param {urlTitle} string
   * @returns {Observable | json}
   * @memberof EmailSignupService
   */
  fetchCustomGeneric(type: string, urlTitle: string): Observable<any> {
    return this.http.get(`${environment.domain}/rest/custom/acg/${type}/${urlTitle}`);
  }

  /**
   * Sanitize HTML Content
   *
   * @param {data} string
   * @returns {SafeHtml}
   * @memberof EmailSignupService
   */
  sanitizeContent(data: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml( unescape(data));
  }
}
