import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { HeaderService } from '../../../header/header.service';

@Component({
  selector: 'shadow-overlay',
  template: `
    <div
      class="nav-shadow"
      *ngIf="activeOverlay"
      (pointerup)="resetMobileNavigation()"
    ></div>
  `,
  styleUrls: ['./shadow-overlay.component.scss'],
})
export class ShadowOverlayComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  activeOverlay: boolean = false;

  constructor(private headerService: HeaderService) {}

  ngOnInit() {
    this.subscription = this.headerService.shadowOverlay.subscribe(
      (value) => (this.activeOverlay = value)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * reset the mobile navigation
   * setTimeout prevents clicking on elements below overlay
   *
   * @memberof ShadowOverlayComponent
   */
  resetMobileNavigation() {
    setTimeout(() => {
      this.headerService.navMenuActive.next(false);
      this.headerService.myAccountActive.next(false);
      this.headerService.mobileNavActive.next(false);
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }, 200);
  }
}
