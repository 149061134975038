import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// rxjs and more
import { emailValidator } from '../../../helpers';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
// Services
import { AuthenticationService } from '../../../authentication.service';
import { NavigationService } from '../../../navigation.service';

@Component({
  selector: 'usr-guest-form',
  templateUrl: './guest-form.component.html',
})
export class GuestFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formFocus: boolean = false;
  @Input() optIn: boolean = false;
  @Input() sourceId: string;

  guestForm: FormGroup;
  themeMode: string = '';
  userFocus: string;
  subscriptions: Subscription[] = [];

  @ViewChild('email', { static: false }) emailInput;
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.createForm();
    this.subscriptions = [this.themeModeSubcription()];
  }

  ngAfterViewInit() {
    if (this.formFocus) {
      this.emailInput.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Returns the subscription for the current theme.
   * @memberOf GuestFormComponent
   */
  themeModeSubcription(): Subscription {
    return this.navigationService.themeMode
      .pipe(filter((theme) => !isEmpty(theme)))
      .subscribe((theme) => {
        this.themeMode = theme;
      });
  }
  /**
   * Handles creating the reactive form-group for the guest form.
   *
   * @memberOf GuestFormComponent
   */
  createForm() {
    this.guestForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
      optIn: [this.optIn],
    });
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf GuestFormComponent
   */
  get emailControl() {
    return this.guestForm.get('email');
  }
  get optInControl() {
    return this.guestForm.get('optIn');
  }

  changeFocus(formControl: string) {
    if (!this.userFocus) {
      this.userFocus = formControl;
    } else {
      this.userFocus = '';
    }
  }

  /**
   * Function handles the guest sign-in
   *
   * @param event
   * @memberOf GuestFormComponent
   */
  submitGuestSignIn(event: any) {
    event.preventDefault();
    if (this.guestForm.valid) {
      const messageTypes = this.optInControl.value
        ? this.themeMode === 'default'
          ? ['promotions']
          : ['industrial']
        : [];
      const formObj = {
        email: this.emailControl.value.toLowerCase(),
        sourceid: this.sourceId,
        optIn: this.optInControl.value,
        messageTypes: messageTypes,
      };
      this.authService.emailSignUp(formObj).subscribe(
        (res) => {
          this.authService.downloadStatus.next('download');
        },
        (err) => {
          if (err.error === 'Email already exists.') {
            this.authService.downloadStatus.next('download');
          }
        }
      );
    }
  }
}
