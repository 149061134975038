import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebcaseEmailUsFormComponent } from './webcase-email-us-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '../../content-loader/content-loader.module';
import { WebcaseEmailusCompleteModalModule } from './webcase-emailus-complete-modal/webcase-emailus-complete-modal.module';
import { ModalModule } from '../../modal/modal.module';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';

@NgModule({
  declarations: [WebcaseEmailUsFormComponent],
  imports: [
    CommonModule,
    ContentLoaderModule,
    ReactiveFormsModule,
    WebcaseEmailusCompleteModalModule,
    ModalModule,
  ],
  exports: [WebcaseEmailUsFormComponent],
  providers: [AvryModalService],
})
export class WebcaseEmailUsFormModule {}
