import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AcgModule } from '../../../nav-shared/components/acg/acg.module';
import { ColumnMenuModule } from '../column-menu/column-menu.module';
import { MegaMenuComponent } from './mega-menu.component';

@NgModule({
  declarations: [MegaMenuComponent],
  imports: [AcgModule, ColumnMenuModule, CommonModule],
  exports: [MegaMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MegaMenuModule {}
