import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';

// Services
import { NavigationService } from '../nav-shared/navigation.service';
import { AcgService } from '../nav-shared/services/acg.service';
import { take } from 'rxjs/operators';
import set from 'lodash-es/set';

// Interfaces
import { AveryCustomGenericInterface } from '../nav-shared/models/interfaces';
import { DomSanitizer } from '@angular/platform-browser';
import { unescape } from 'lodash-es';

@Component({
  // selector: 'app-footer', // commented this out since it is now exported as web component
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  // Hide cookie banner for US-based visitors
  EXCLUDED_COUNTRIES: string[] = ['US', 'CA', 'MX', 'AU', 'PR'];
  ACG_NAME_LIST = [
    'footer-navigation',
    'footer-navigation-industrial',
    'footer-second-menu',
    'footer-second-menu-industrial',
    'footer-navigation-brands',
    'footer-copywrite',
  ];
  SITE_URL: string = environment.domain;
  ACG_CONFIG_NAME: string = 'nav-acgs';

  currentUrl: string = 'https://' + window.location.hostname;
  kspACGs: AveryCustomGenericInterface[];
  footers: any;
  showCookiesDisclaimer: boolean = true;
  showReviewBadge: boolean = true;
  themeMode: string = null;
  subscriptions: Subscription[] = [];
  URLregex = /https:\/\/\w*-cart\d*.avery.com/;

  constructor(
    private navigationService: NavigationService,
    private acgService: AcgService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.showReviewBadge = !this.navigationService.checkRouteMatch([
      '/industrial/ghs-subscription-payment',
    ]);

    this.subscriptions = [this.averyThemeModeSubscription()];

    this.updateCookieAcknowledgement();
    this.acgService
      .fetchACGConfig('nav-acgs')
      .then((acgConfig) => {
        if (acgConfig) {
          let acgs: any = {};
          this.kspACGs = acgConfig.acgs.filter((acg) => acg.type === 'ksp');
          this.ACG_NAME_LIST.forEach((name) => {
            set(
              acgs,
              name,
              this.sanitizer.bypassSecurityTrustHtml(
                unescape(acgConfig.acgs.find((acg) => acg.name === name).body)
              )
            );
          });
          this.footers = acgs;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Return subscription for the theme mode
   *
   * @returns {Subscription}
   * @memberof FooterComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe(
      (type: string) => (this.themeMode = type)
    );
  }

  /**
   * Update cookie acknowledgement banner boolean status
   *
   * @memberof FooterComponent
   */
  updateCookieAcknowledgement(): void {
    if (
      this.currentUrl === environment.magento ||
      this.URLregex.test(this.currentUrl)
    )
      // hide cookies disclaimer when url === env.magento, tst-cart4, tsts-cart5
      this.showCookiesDisclaimer = false;
    else this.checkCookieAcknowledgement();
  }

  /**
   * Hide cookie acknowledgement banner if the user is from the excluded countries
   *
   * @returns {subscription}
   * @memberof FooterComponent
   */
  checkCookieAcknowledgement(): void {
    this.navigationService
      .getCountryCode()
      .pipe(take(1))
      .subscribe((res) => {
        const countryCode: string = res.headers.get(
          'cloudfront-viewer-country'
        );
        this.showCookiesDisclaimer = !this.EXCLUDED_COUNTRIES.includes(
          countryCode
        );
      });
  }
}
