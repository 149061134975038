import { Action as ngrxAction } from '@ngrx/store';

export interface Action extends ngrxAction {
  payload ?: any;
}

// Actions that will occur in the myaccount/profile page
export const GET_PROFILE_EMAIL = 'GET_PROFILE_EMAIL';
export const GET_PROFILE_NAME = 'GET_PROFILE_NAME';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE_STATUS = 'UPDATE_PROFILE_STATUS';
