import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { FilterProfileState } from './profile';

export function getReducers() {
  return {
    profile: FilterProfileState,
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<any>>('Reducers');

export const reducerProvider = [
  { provide: reducerToken, useFactory: getReducers },
];
