import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'usr-tos-notice',
  templateUrl: './tos-notice.component.html',
  styles: [
    `
      .text-link {
        color: #105bd2 !important;
      }
    `,
  ],
})
export class TosNoticeComponent {
  @Input() formType: string = 'sign-in';
  tosLink: string = `${environment.domain}/about-us/legal/terms-and-conditions`;
  privacyLink: string = `${environment.domain}/about-us/legal/privacy-policy`;
}
