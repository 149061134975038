import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ModalService } from 'navigation/nav-shared/components/modal/modal.service';
import { ModalSize } from 'navigation/nav-shared/models/types';

@Component({
  selector: 'app-webcase-emailus-complete-modal',
  templateUrl: './webcase-emailus-complete-modal.component.html',
  styleUrls: ['./webcase-emailus-complete-modal.component.scss'],
})
export class WebcaseEmailusCompleteModalComponent implements AfterViewInit {
  @Input() id: string = 'webcase-email-us-complete-modal';
  @Input() size: ModalSize = 'md';
  @Input() modalCompletionType: string;

  @Output() onClose = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {}

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  dismissModal() {
    this.onClose.emit(true);
    this.modalService.close(this.id);
  }

  handleSelfClosing(evt) {
    if (evt) {
      this.onClose.emit(true);
    }
  }
}
