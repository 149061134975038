import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { unescape } from 'lodash-es';
import { GenericModalComponent } from 'navigation/nav-shared/components/generic-modal/generic-modal.component';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';

import {
  AveryCustomGenericInterface,
  GenericModal,
} from '../../nav-shared/models/interfaces';

@Component({
  selector: 'nav-footer-ksp',
  templateUrl: './footer-ksp.component.html',
  styles: [
    `
      body[data-avry-mode='industrial'] .text-link {
        color: #242424 !important;
      }
      .sk-block {
        height: 121px;
      }
    `,
  ],
})
export class FooterKspComponent implements OnChanges {
  @Input() acgs: AveryCustomGenericInterface[];
  @Input() themeMode: string = 'default';
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer;

  genericModalContent: GenericModal;
  acgKeyList = [
    'ksp-footer-quality',
    'ksp-footer-guarantee',
    'ksp-footer-cs-hours',
    'ksp-footer-cs-hours-industrial',
  ];
  acgList: SafeHtml[] = [];

  constructor(
    private avryModalService: AvryModalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.acgs && changes.acgs.currentValue) {
      this.acgKeyList.forEach((key) => {
        this.acgList.push(
          this.sanitizer.bypassSecurityTrustHtml(
            unescape(this.acgs.find((acg) => acg.name === key).body)
          )
        );
      });
    }
  }

  openSatisfactionGuaranteed() {
    const modalRef = this.avryModalService.init(
      this.modalContainer,
      GenericModalComponent,
      'satisfaction-guarantee-modal',
      'lg'
    );

    modalRef.instance.genericModalContent = {
      acgType: 'help-modal',
      acgURL: 'satisfaction_guaranteed',
    };
  }
}
