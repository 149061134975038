import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcgModule } from '../../nav-shared/components/acg/acg.module';
import { FooterKspComponent } from './footer-ksp.component';
import { GenericModalModule } from 'navigation/nav-shared/components/generic-modal/generic-modal.module';

@NgModule({
  declarations: [FooterKspComponent],
  imports: [CommonModule, AcgModule, GenericModalModule],
  exports: [FooterKspComponent],
})
export class FooterKspModule {}
