import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BlankLabelTypeaheadEntry,
  TemplateTypeahead,
  TemplateTypeaheadEntry,
  TypeaheadType,
} from 'navigation/nav-shared/models/interfaces/typeahead.interface';
import { NavigationService } from 'navigation/nav-shared/navigation.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit, OnDestroy {
  @Input() themeMode: string = 'default';
  @Input() type: TypeaheadType;
  dropdownBlankLabels: BlankLabelTypeaheadEntry[] = [];
  dropdownTemplates: TemplateTypeaheadEntry[] = [];
  subscription: Subscription;

  constructor(private navService: NavigationService) {}

  ngOnInit(): void {
    switch (this.type) {
      case 'blankLabels':
        this.subscription = this.getBlankLabelsTypeahead();
        break;
      case 'templates':
        this.subscription = this.getTemplateTypeaheadData();
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Get typeahead dropdown results
   *
   * @memberof ProductSearchComponent
   */
  getBlankLabelsTypeahead(): Subscription {
    return forkJoin([
      this.navService.getBlankLabelsCPGTypeahead(),
      this.navService.getBlankLabelsOATypeAhead(this.themeMode),
    ]).subscribe(
      ([cpgLabels, oaLabels]) =>
        (this.dropdownBlankLabels = [...cpgLabels, ...oaLabels])
    );
  }

  /**
   * Gets the template typeahead data
   *
   * @memberof ProductSearchComponent
   */
  getTemplateTypeaheadData() {
    return this.navService
      .getTemplateTypeahead()
      .pipe(map((data: TemplateTypeahead) => data.contentlets))
      .subscribe((templates: TemplateTypeaheadEntry[]) => {
        this.dropdownTemplates = templates.sort(
          (t1, t2) => t2.popularity - t1.popularity
        );
      });
  }
}
