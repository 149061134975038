import { UserInfoInterface } from 'navigation/nav-shared/models/interfaces';

// Reference docs: https://support.cordial.com/hc/en-us/articles/360016285852--Embedded-JavaScript-Listener-v2

type CordialEventType = 'contact' | 'event' | 'connect' | 'identify' | 'debug';

interface AuthData {
  consumer_id: string | undefined;
}

interface Properties {
  url: string;
}

declare global {
  interface Window {
    crdl: (
      event: CordialEventType,
      arg2?: AuthData | string,
      arg3?: Properties | undefined
    ) => void;
  }
}

function emitCordialCoreEvents(url: string) {
  // Emit page_view event
  emitPageViewEvent(url);
  // Emit contact event
  emitContactEvent();
}

function emitContactEvent() {
  const authData = localStorage.getItem('u_i');
  let consumerData: UserInfoInterface | null = null;
  if (authData) {
    try {
      consumerData = JSON.parse(authData);
    } catch (e) {
      console.error('Error parsing authData JSON: ', e);
    }
  }
  if (consumerData) {
    try {
      window.crdl('contact', { consumer_id: consumerData?.consumerId });
    } catch (e) {
      console.error('[Cordial] error emitting contact event: ', e);
    }
  }
}

function emitPageViewEvent(url: string) {
  try {
    window.crdl('event', 'page_viewed', { url });
  } catch (e) {
    console.error('[Cordial] error emitting page_viewed event: ', e);
  }
}

// function to check if crdl is available
export function checkCrdlWithRetry(url: string) {
  let retryCount = 0;
  const checkCrdl = () => {
    if (window.crdl !== undefined) {
      // if crdl is available, emit cordial event on every page
      emitCordialCoreEvents(url);
    } else if (retryCount < 5) {
      // If not available and retry count is less than 5, set a timeout for the next check
      setTimeout(() => {
        retryCount++; // Increment the retry count
        checkCrdl(); // Retry
      }, 1000); // 1-second delay
    } else {
      // Retry limit reached, handle the error or move on
      console.error(`[Cordial] crdl not available after ${retryCount} retries`);
    }
  };
  checkCrdl(); // Start checking for crdl
}
