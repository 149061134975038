import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndustrialScreeningProductComponent } from './industrial-screening-product.component';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [IndustrialScreeningProductComponent],
  imports: [CommonModule, ContentLoaderModule, ReactiveFormsModule],
  exports: [IndustrialScreeningProductComponent],
})
export class IndustrialScreeningProductModule {}
