import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import unescape from 'lodash-es/unescape';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(htmlInString: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unescape(htmlInString));
  }
}
