import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { ContentLoaderModule } from './components/content-loader/content-loader.module';

// Components
import { AcgComponent } from './components/acg/acg.component';
import { CommonTypeaheadComponent } from './components/common-typeahead/common-typeahead.component';
import { CrossSellSearchBarComponent } from './components/cross-sell-search-bar/cross-sell-search-bar.component';
import { CustomCardsModalComponent } from './components/custom-cards-modal/custom-cards-modal.component';
import { EmailQuoteCompletionComponent } from './components/email-quote-completion/email-quote-completion.component';
import { EmailQuoteComponent } from './components/email-quote/email-quote.component';
import { FreeSamplePackComponent } from './components/free-sample-pack/free-sample-pack.component';
import { FreeSamplePackModalComponent } from './components/free-sample-pack-modal/free-sample-pack-modal.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { IndustrialScreeningProductComponent } from './components/industrial-screening-product/industrial-screening-product.component';
import { IndWhitepaperSamplePackComponent } from './components/ind-whitepaper-sample-pack/ind-whitepaper-sample-pack.component';
import { InternationalVisitorModalComponent } from './components/international-visitor-modal/international-visitor-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { PromoAlertComponent } from './components/promo-alert/promo-alert.component';
import { ShadowOverlayComponent } from './components/shadow-overlay/shadow-overlay.component';
import { WebcaseEmailUsFormComponent } from './components/user-forms/webcase-email-us-form/webcase-email-us-form.component';

// Directives
import { NewsletterSignupModule } from './components/newsletter-signup/newsletter-signup.module';
import { ModalModule } from './components/modal/modal.module';
import { AcgModule } from './components/acg/acg.module';
import { CommonTypeaheadModule } from './components/common-typeahead/common-typeahead.module';
import { CrossSellSearchBarModule } from './components/cross-sell-search-bar/cross-sell-search-bar.module';
import { CustomCardsModalModule } from './components/custom-cards-modal/custom-cards-modal.module';
import { CustomGenericModalModule } from './components/custom-generic-modal/custom-generic-modal.module';
import { EmailQuoteCompletionModule } from './components/email-quote-completion/email-quote-completion.module';
import { EmailQuoteModule } from './components/email-quote/email-quote.module';
import { FreeSamplePackModule } from './components/free-sample-pack/free-sample-pack.module';
import { FreeSamplePackCompleteModalModule } from './components/free-sample-pack-complete-modal/free-sample-pack-complete-modal.module';
import { FreeSamplePackModalModule } from './components/free-sample-pack-modal/free-sample-pack-modal.module';
import { GenericModalModule } from './components/generic-modal/generic-modal.module';
import { IndustrialScreeningProductModule } from './components/industrial-screening-product/industrial-screening-product.module';
import { IndWhitepaperSamplePackModule } from './components/ind-whitepaper-sample-pack/ind-whitepaper-sample-pack.module';
import { InternationalVisitorModalModule } from './components/international-visitor-modal/international-visitor-modal.module';
import { MessageModalModule } from './components/message-modal/message-modal.module';
import { ProductSearchModule } from './components/product-search/product-search.module';
import { PromoAlertModule } from './components/promo-alert/promo-alert.module';
import { ShadowOverlayModule } from './components/shadow-overlay/shadow-overlay.module';
import { WebcaseEmailUsFormModule } from './components/user-forms/webcase-email-us-form/webcase-email-us-form.module';
import { StaticModalModule } from './components/static-modal/static-modal.module';

@NgModule({
  imports: [
    AcgModule,
    CommonModule,
    CommonTypeaheadModule,
    ContentLoaderModule,
    CrossSellSearchBarModule,
    CustomCardsModalModule,
    CustomGenericModalModule,
    EmailQuoteModule,
    EmailQuoteCompletionModule,
    FormsModule,
    FreeSamplePackModule,
    FreeSamplePackModalModule,
    FreeSamplePackCompleteModalModule,
    GenericModalModule,
    IndustrialScreeningProductModule,
    IndWhitepaperSamplePackModule,
    InternationalVisitorModalModule,
    MessageModalModule,
    NewsletterSignupModule,
    NgbModule,
    ProductSearchModule,
    PromoAlertModule,
    ReactiveFormsModule,
    ModalModule,
    ShadowOverlayModule,
    StaticModalModule,
    WebcaseEmailUsFormModule,
  ],
  exports: [
    AcgComponent,
    CommonTypeaheadComponent,
    CrossSellSearchBarComponent,
    CustomCardsModalComponent,
    EmailQuoteCompletionComponent,
    EmailQuoteComponent,
    FreeSamplePackComponent,
    FreeSamplePackModalComponent,
    GenericModalComponent,
    IndustrialScreeningProductComponent,
    IndWhitepaperSamplePackComponent,
    InternationalVisitorModalComponent,
    MessageModalComponent,
    ProductSearchComponent,
    PromoAlertComponent,
    ShadowOverlayComponent,
    WebcaseEmailUsFormComponent,
  ],
  declarations: [],
})
export class NavSharedModule {}
