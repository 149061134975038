import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticModalComponent } from './static-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [StaticModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [StaticModalComponent],
})
export class StaticModalModule {}
