import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndWhitepaperSamplePackComponent } from './ind-whitepaper-sample-pack.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '../content-loader/content-loader.module';

@NgModule({
  declarations: [IndWhitepaperSamplePackComponent],
  imports: [CommonModule, ContentLoaderModule, ReactiveFormsModule],
  exports: [IndWhitepaperSamplePackComponent],
})
export class IndWhitepaperSamplePackModule {}
