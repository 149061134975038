import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  addDashesToPhone,
  emailValidator,
  isValidName,
} from '../../../nav-shared/helpers';

import { HeaderService } from '../../../header/header.service';

import { LabelsEmailQuoteFormInterface } from '../../../nav-shared/models/interfaces/email-quote.interface';

import EmailQuoteComplete from '../email-quote-completion/email-quote-completion.interface';

import isEmpty from 'lodash-es/isEmpty';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-email-quote',
  templateUrl: './email-quote.component.html',
  styleUrls: ['./email-quote.component.scss'],
})
export class EmailQuoteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() acgCompletionRequestError: string = 'email_quote_request_error';
  @Input() acgCompletionRequestSuccess: string = 'email_quote_request_success';
  @Input() acgCompletionTitle: string = 'email_quote_complete_modal_title';
  @Input() acgMainModalSubtitle: string;
  @Input() acgMainModalPrivacy: string;
  @Input() btntext: string = 'Submit';
  @Input() isQueueSubmission: string = 'Y';
  @Input() themeMode: string = 'default';
  @Input() title: string = 'Metallic & Holographic Roll Label Orders';

  emailQuoteFormGrp: FormGroup;
  emptyPhoneNum: boolean;
  isInvalidFormMessage: boolean = false;
  isInvalidCheckFirstName: boolean = false;
  isInvalidCheckLastName: boolean = false;
  isInvalidCheckEmail: boolean = false;
  isInvalidPhone: boolean = false;
  isLoading: boolean = false;
  phone: string = '';
  properPhoneLength: boolean = false;
  showLimitFirstName: boolean;
  showLimitLastName: boolean;
  subscriptions: Array<Subscription> = [];
  userFocus: string;
  validCompanyName: boolean = true;
  validFirstName: boolean = true;
  validLastName: boolean = true;

  emailQuoteComplete: EmailQuoteComplete;

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions = this.createForm();
  }

  ngOnDestroy() {
    this.modalService.close('');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.modalService.open('email-quote-modal');
  }

  /**
   * Initialize form controls
   * Return subscription array for all
   * value changes on some form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof EmailQuoteComponent
   */
  createForm(): Array<Subscription> {
    this.emailQuoteFormGrp = this.fb.group({
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.compose([Validators.pattern('[0-9,-]*')])],
      company: [''],
    });
    return [
      this.firstName.valueChanges.subscribe((data) => {
        this.showLimitFirstName = data.length > 50;
        this.validFirstName = isValidName(data);
        if (this.validFirstName) {
          this.validFirstName = this.validateMore(data);
        }
      }),
      this.lastName.valueChanges.subscribe((data) => {
        this.showLimitLastName = data.length > 50;
        this.validLastName = isValidName(data);
        if (this.validLastName) {
          this.validLastName = this.validateMore(data);
        }
      }),
      this.phoneNumber.valueChanges.subscribe((data) => {
        this.properPhoneLength = data.length === 12;
        this.emptyPhoneNum = data.length === 0;
      }),
      this.companyName.valueChanges.subscribe((data) => {
        this.validCompanyName = this.validateMore(data);
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf EmailQuoteComponent
   */
  get newEmail() {
    return this.emailQuoteFormGrp.get('email');
  }
  get firstName() {
    return this.emailQuoteFormGrp.get('fName');
  }
  get lastName() {
    return this.emailQuoteFormGrp.get('lName');
  }
  get companyName() {
    return this.emailQuoteFormGrp.get('company');
  }
  get phoneNumber() {
    return this.emailQuoteFormGrp.get('phone');
  }

  /**
   * Validate entered first name, last name and company for invalid character/s.
   *
   * @param {string} input
   * @returns {boolean}
   * @memberof EmailQuoteComponent
   */
  validateMore(input: string): boolean {
    if (
      input.indexOf('--') !== -1 ||
      input.indexOf('<') !== -1 ||
      input.indexOf('>') !== -1 ||
      (input.indexOf('-') !== -1 && input.length === 1)
    ) {
      return false;
    }

    return true;
  }

  /**
   * Trigger email send quote to the user's email address.
   *
   * @memberof EmailQuoteComponent
   */
  sendEmailQuote() {
    if (
      this.emailQuoteFormGrp.valid &&
      this.validFirstName &&
      this.validLastName &&
      this.validCompanyName
    ) {
      // Call final validation of the entered phone number. This is so because the dashPhone function
      // does not pick up all the entered characters from the phone input field.
      const isInvalid = this.checkForValidPhone(this.phoneNumber.value);

      if (!isInvalid) {
        const emailForm: LabelsEmailQuoteFormInterface = {
          email: this.newEmail.value,
          fName: this.firstName.value,
          lName: this.lastName.value,
          company: this.companyName.value,
          phone: this.phoneNumber.value,
        };

        const emailQuoteData = {
          email: this.newEmail.value,
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          company: this.companyName.value,
          phone: this.phoneNumber.value,
          quoteRequestType: 'WePrint_Roll_Embellishments',
        };

        this.isLoading = true;

        if (this.isQueueSubmission === 'Y' || this.isQueueSubmission === 'y') {
          this.headerService
            .pubSugarcrmStandaloneQueue(emailQuoteData)
            .subscribe(
              (resp) => {
                this.openCompletionModal('success');
              },
              (error) => {
                console.error(error);
                this.openCompletionModal('error');
              }
            );
        } else {
          this.headerService.postSugarCrmQuoteRequest(emailQuoteData).subscribe(
            (resp) => {
              this.openCompletionModal('success');
            },
            (error) => {
              console.error(error);
              this.openCompletionModal('error');
            }
          );
        }
      }
    } else {
      if (this.firstName.invalid) {
        this.isInvalidFormMessage = true;
        this.firstName.markAsDirty();
      }
      if (this.lastName.invalid) {
        this.isInvalidFormMessage = true;
        this.lastName.markAsDirty();
      }
      if (this.newEmail.invalid) {
        this.isInvalidFormMessage = true;
        this.newEmail.markAsDirty();
      }
      if (this.phoneNumber.invalid) {
        this.isInvalidFormMessage = true;
        this.phoneNumber.markAsDirty();
      }
      this.checkRequiredFields();
    }
  }

  /**
   * Opens the completion modal after submitting the email quote request.
   *
   * @param {string} status
   * @memberof EmailQuoteComponent
   */
  openCompletionModal(status: string) {
    this.emailQuoteComplete = {
      status: status,
      acgCompletionTitle: this.acgCompletionTitle,
      acgCompletionRequestSuccess: this.acgCompletionRequestSuccess,
      acgCompletionRequestError: this.acgCompletionRequestError,
    };

    this.dismissModal();
  }

  /**
   * Add dashes for entered phone number
   * in this case 2 dashes
   *
   * @param {Event} event
   * @memberof EmailQuoteComponent
   */
  dashPhone(event: Event) {
    let phone = (event.target as HTMLInputElement).value;
    this.phone = addDashesToPhone(phone);

    // Updates the form with the latest value of phone after the reformat.
    this.phoneNumber.setValue(this.phone);

    this.ref.detectChanges();
  }

  /**
   * Validate phone number
   *
   * @param {string} [value='']
   * @returns {boolean}
   * @memberof EmailQuoteComponent
   */
  checkForValidPhone(value: string = ''): boolean {
    if (isEmpty(value)) {
      return false;
    }
    const arr = value.split('');
    this.isInvalidPhone = false;
    let ctr = 0;
    arr.forEach((item, index) => {
      if (item === '-' && index !== 3 && item === '-' && index !== 7) {
        this.isInvalidPhone = true;
      }
      if (item !== '-') {
        ++ctr;
      }
    });
    if (ctr !== 10) {
      this.isInvalidPhone = true;
    }
    return this.isInvalidPhone;
  }

  /**
   * Prevent in focus input from validateing until focus changes
   *
   * @param formControl
   * @memberof EmailQuoteComponent
   */
  formFocus(formControl: string = '') {
    this.userFocus = formControl;
  }

  /**
   * Validate required fields.
   *
   * @param {string} [formControl='']
   * @memberof EmailQuoteComponent
   */
  checkRequiredFields(formControl: string = '') {
    switch (formControl) {
      case 'fName':
        this.isInvalidCheckFirstName = this.firstName.invalid;
        break;
      case 'lName':
        this.isInvalidCheckLastName = this.lastName.invalid;
        break;
      case 'email':
        this.isInvalidCheckEmail = this.newEmail.invalid;
        break;
      default:
        this.isInvalidCheckFirstName = this.firstName.invalid;
        this.isInvalidCheckLastName = this.lastName.invalid;
        this.isInvalidCheckEmail = this.newEmail.invalid;
    }
  }

  /**
   * Validate character limit to 100
   *
   * @param event
   * @param {string} value
   * @memberof EmailQuoteComponent
   */
  isValidInput(event, value: string) {
    // NOTE: the value length is one-off.
    let isValidKey = value.length <= 100;
    // if the length greater or equal to 100, then allow the user to delete or move the cursor.
    if (value.length >= 100) {
      if (event.key) {
        // NOTE: `event.key` is the recomended property but some browsers might still not support this property. `event.keyIdentifier` or `event.keyCode` will be the fall back.
        isValidKey =
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Backspace' ||
          event.key === 'Del';
      } else if (event.keyIdentifier) {
        const keyValue = event.keyIdentifier.includes('U+')
          ? String.fromCharCode(event.keyIdentifier.replace('U+', '0x'))
          : event.keyIdentifier;

        isValidKey =
          keyValue === 'Left' ||
          keyValue === 'Right' ||
          event.keyIdentifier === 'U+0008' ||
          event.keyIdentifier === 'U+007F';
      } else if (event.keyCode) {
        isValidKey =
          event.keyCode === 8 ||
          event.keyCode === 37 ||
          event.keyCode === 39 ||
          event.keyCode === 46;
      }
    }

    if (!isValidKey) {
      event.preventDefault();
    }
  }

  // handle email quote modal close
  dismissModal() {
    this.modalService.close('email-quote-modal');
  }

  // handle email quote modal close
  handleCloseEmailQuoteModal(evt) {
    if (evt) {
      this.modalService.close('email-quote-modal');
    }
  }

  handleCloseEmailCompletionModal(evt) {
    if (evt) {
      this.emailQuoteComplete = undefined;
    }
  }
}
