import { Component, OnInit } from '@angular/core';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';
import { Subscription } from 'rxjs';

import {
  MultiSiteData,
  MultiSiteDataPayload,
} from '../../multisite-links.interface';

@Component({
  selector: 'nav-footer-multisite-dropdown',
  templateUrl: './footer-multisite-dropdown.component.html',
  styleUrls: ['./footer-multisite-dropdown.scss'],
})
export class FooterMultisiteDropdownComponent {
  multisiteLinks: MultiSiteData[] = null;
  hasDropdownOpen: boolean = false;

  constructor(private navDataService: NavDataService) {}

  /**
   * Get the mutisite data
   *
   * @returns {subscription}
   * @memberof FooterMultisiteDropdownComponent
   */
  fetchMultisiteData(): any {
    if (this.hasDropdownOpen) {
      // click on selection again to close
      this.hasDropdownOpen = false;
    } else if (!this.multisiteLinks) {
      this.navDataService
        .fetchDotCMSJSONData<MultiSiteDataPayload>('multisite-links')
        .then((data) => {
          this.multisiteLinks = data.multisiteData;
          this.hasDropdownOpen = true;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.hasDropdownOpen = true;
    }
  }

  /**
   * Navigate to the selected multiSite
   *
   * @param {string} multisite
   * @memberof FooterMultisiteDropdownComponent
   */
  handleMultisiteLink(multisite) {
    window.location.href = `${multisite.url}`;
  }

  closeDropdown() {
    this.hasDropdownOpen = false;
  }
}
