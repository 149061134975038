import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalSize } from 'navigation/nav-shared/models/types';

import { createCookie } from '../../helpers/browserAbstraction';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-international-visitor-modal',
  templateUrl: './international-visitor-modal.component.html',
  styleUrls: ['./international-visitor-modal.component.scss'],
})
export class InternationalVisitorModalComponent
  implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() size: ModalSize;
  @Input() utilClassess: string = 'modal-avry modal-avry--lg smooth-scroll';

  @Input() cookieName: string;
  @Input() countryCode: string;

  modalTitle: string = null;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.setModalTitle();
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  /**
   * Creates Cookie that the Australian/New Zealander visitor has opt-in to continue to the US site.
   *
   * @memberof AustraliaNewZelandVisitorComponent
   */
  confirmedToContinueSite() {
    createCookie(this.cookieName, this.countryCode, 1);
    this.dismissModal();
  }

  /**
   * Closes Modal
   *
   * @memberof InternationalVisitorModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }

  /**
   * Configures Modal Title
   *
   * @memberof InternationalVisitorModalComponent
   */
  setModalTitle() {
    switch (this.countryCode) {
      case 'CA':
        this.modalTitle = 'Visiting Our Site From Canada?';
        break;
      case 'AU':
      case 'NZ':
        this.modalTitle = 'Visiting Our Site From Australia / New Zealand?';
        break;
    }
  }
}
