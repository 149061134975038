import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DefaultBannerComponent } from './default-banner/default-banner.component';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import { EmailSignupComponent } from './email-signup/email-signup.component';
import { EmailSignupMdComponent } from './email-signup-md/email-signup-md.component';
import { EmailSignupService } from './email-signup/email-signup.service';
import { GhsSamplePackComponent } from '../ghs-sample-pack/ghs-sample-pack.component';
import { GhsSamplePackModule } from '../ghs-sample-pack/ghs-sample-pack.module';
import { EmailSignupModule } from './email-signup/email-signup.module';
import { DefaultBannerModule } from './default-banner/default-banner.module';
import { EmailSignupMdModule } from './email-signup-md/email-signup-md.module';

@NgModule({
  imports: [
    CommonModule,
    ContentLoaderModule,
    DefaultBannerModule,
    EmailSignupModule,
    EmailSignupMdModule,
    FormsModule,
    GhsSamplePackModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  exports: [
    DefaultBannerComponent,
    EmailSignupComponent,
    EmailSignupMdComponent,
    GhsSamplePackComponent,
  ],
  providers: [EmailSignupService],
})
export class NewsletterSignupModule {}
