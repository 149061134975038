import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInModalComponent } from './sign-in-modal.component';
import { ProxySignInFormModule } from '../proxy-sign-in-form/proxy-sign-in-form.module';
import { SignInFormModule } from '../sign-in-form/sign-in-form.module';
import { CreateAccountFormModule } from '../create-account-form/create-account-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuestFormModule } from '../guest-form/guest-form.module';
import { AccountBenefitsModule } from '../account-benefits/account-benefits.module';
import { ModalModule } from '../../modal/modal.module';

@NgModule({
  declarations: [SignInModalComponent],
  imports: [
    CommonModule,
    AccountBenefitsModule,
    CreateAccountFormModule,
    GuestFormModule,
    ProxySignInFormModule,
    FormsModule,
    ReactiveFormsModule,
    SignInFormModule,
    ModalModule,
  ],
  exports: [SignInModalComponent],
})
export class SignInModalModule {}
