import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';

import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';

// Header Modules
import { HeaderNavModule } from './header-nav/header-nav.module';

// Header Components
import { ProductSearchComponent } from 'navigation/nav-shared/components/product-search/product-search.component';
import { BulkOrderComponent } from '../nav-shared/components/bulk-order/bulk-order.component';
import { EmailQuoteComponent } from '../nav-shared/components/email-quote/email-quote.component';
import { GhsSamplePackComponent } from '../nav-shared/components/ghs-sample-pack/ghs-sample-pack.component';
import { IndWhitepaperSamplePackComponent } from '../nav-shared/components/ind-whitepaper-sample-pack/ind-whitepaper-sample-pack.component';
import { IndustrialScreeningProductComponent } from '../nav-shared/components/industrial-screening-product/industrial-screening-product.component';
import { DefaultBannerComponent } from '../nav-shared/components/newsletter-signup/default-banner/default-banner.component';
import { EmailSignupMdComponent } from '../nav-shared/components/newsletter-signup/email-signup-md/email-signup-md.component';
import { EmailSignupComponent } from '../nav-shared/components/newsletter-signup/email-signup/email-signup.component';
import { HeaderComponent } from './header.component';

// Header Services
import { HeaderService } from './header.service';

import { AcgComponent } from 'navigation/nav-shared/components/acg/acg.component';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import { BulkOrderModule } from '../nav-shared/components/bulk-order/bulk-order.module';
import { EmailQuoteModule } from '../nav-shared/components/email-quote/email-quote.module';
import { IndWhitepaperSamplePackModule } from '../nav-shared/components/ind-whitepaper-sample-pack/ind-whitepaper-sample-pack.module';
import { IndustrialScreeningProductModule } from '../nav-shared/components/industrial-screening-product/industrial-screening-product.module';
import { NewsletterSignupModule } from '../nav-shared/components/newsletter-signup/newsletter-signup.module';
import { ProductSearchModule } from '../nav-shared/components/product-search/product-search.module';
import { PromoAlertModule } from '../nav-shared/components/promo-alert/promo-alert.module';
import { ShadowOverlayModule } from '../nav-shared/components/shadow-overlay/shadow-overlay.module';
import { SiteLogoModule } from '../nav-shared/components/site-logo/site-logo.module';
import { SignInModalModule } from '../nav-shared/components/user-forms/sign-in-modal/sign-in-modal.module';
import { HeaderSearchModule } from './header-search/header-search.module';
import { HeaderThemeTabsModule } from './header-theme-tabs/header-theme-tabs.module';
import { MyAccountMenuModule } from './my-account-menu/my-account-menu.module';

@NgModule({
  imports: [
    BulkOrderModule,
    CommonModule,
    EmailQuoteModule,
    HeaderNavModule,
    HeaderSearchModule,
    HeaderThemeTabsModule,
    IndustrialScreeningProductModule,
    IndWhitepaperSamplePackModule,
    MyAccountMenuModule,
    NewsletterSignupModule,
    ProductSearchModule,
    PromoAlertModule,
    ShadowOverlayModule,
    SignInModalModule,
    SiteLogoModule,
  ],
  exports: [HeaderComponent],
  declarations: [HeaderComponent],
  providers: [HeaderService, AvryModalService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {
  constructor(injector: Injector) {
    const elementObj = [
      { component: HeaderComponent, tag: 'app-header' },
      { component: EmailSignupComponent, tag: 'avy-ng-subscribe' },
      { component: DefaultBannerComponent, tag: 'avy-ng-banner' },
      { component: EmailSignupMdComponent, tag: 'avy-ng-subscribe-md' },
      { component: GhsSamplePackComponent, tag: 'avy-ng-ghs-sample-pack' },
      {
        component: IndustrialScreeningProductComponent,
        tag: 'avy-ng-industrial-screening-product',
      },
      {
        component: IndWhitepaperSamplePackComponent,
        tag: 'avy-ng-whitepaper-sample-pack',
      },
      {
        component: ProductSearchComponent,
        tag: 'avy-ng-product-search-typeahead',
      },
      {
        component: EmailQuoteComponent,
        tag: 'avy-ng-email-quote',
      },
      {
        component: BulkOrderComponent,
        tag: 'avy-ng-bulk-order',
      },
      {
        component: AcgComponent,
        tag: 'avy-ng-acg',
      },
    ];

    // Create web components to be consumed by non-angular projects
    elementObj.forEach((elem) => {
      const newElement = createCustomElement(elem.component, { injector });
      customElements.define(elem.tag, newElement);
    });
  }
}
