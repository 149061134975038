import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9,]/g, ''); // Allow only digits and commas
    input.value = sanitizedValue;
  }
}
