import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { CookieDisclaimerModule } from '../nav-shared/components/cookie-disclaimer/cookie-disclaimer.module';
import { AcgModule } from '../nav-shared/components/acg/acg.module';
import { SiteLogoModule } from '../nav-shared/components/site-logo/site-logo.module';
import { FooterSubscribeFormModule } from './footer-subscribe-form/footer-subscribe-form.module';
import { FooterMultisiteDropdownModule } from './footer-multisite-dropdown/footer-multisite-dropdown.module';
import { FooterKspModule } from './footer-ksp/footer-ksp.module';
import { AcgService } from '../nav-shared/services/acg.service';
import { NavigationService } from 'navigation/nav-shared/navigation.service';
import { DefaultBannerModule } from 'navigation/nav-shared/components/newsletter-signup/default-banner/default-banner.module';
import { EmailSignupModule } from 'navigation/nav-shared/components/newsletter-signup/email-signup/email-signup.module';

@NgModule({
  imports: [
    AcgModule,
    CommonModule,
    CookieDisclaimerModule,
    FooterKspModule,
    FooterMultisiteDropdownModule,
    FooterSubscribeFormModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SiteLogoModule,
    DefaultBannerModule,
    EmailSignupModule,
  ],
  exports: [FooterComponent],
  declarations: [FooterComponent],
  providers: [NavigationService, AcgService],
})
export class FooterModule {
  constructor(injector: Injector) {
    // Create footer as web component to be consumed by non-angular projects
    const el = createCustomElement(FooterComponent, { injector });
    customElements.define('app-footer', el);
  }
}
