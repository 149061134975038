import { AuthenticationService } from '../../../authentication.service';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ModalService } from '../../modal/modal.service';
import { Breakpoint, useBreakpoint } from 'navigation/nav-shared/utils';
import { ModalSize } from 'navigation/nav-shared/models/types';
@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
})
export class SignInModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string = 'sign-in-modal';
  @Input() size: ModalSize = 'lg';

  @Input() bypassInitFieldFocus: boolean = false;
  @Input() formMode: string = 'modalForm';
  @Input() sourceId: string = '';
  @Input() redirectLink?: string = '';

  @Output() onClose = new EventEmitter<string>();

  emailFocus: boolean = false;
  forwardEmail: string = '';
  guestDownload = false;
  guestForm = false;
  subscriptions: Subscription[] = [];
  userSignedIn: boolean;

  curBreakpoint: Breakpoint = useBreakpoint();

  // is used to detect user screen size dynamically based on resize event
  @HostListener('window:resize', [])
  onResize() {
    this.curBreakpoint = useBreakpoint();
  }

  constructor(
    private modalService: ModalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.subscriptions = [
      this.authService.downloadStatus.subscribe((closeModal) => {
        if (closeModal === 'download') {
          this.onSignedIn();
        }
      }),
      this.authService.includeGuest.subscribe(
        (guestForm) => (this.guestForm = guestForm)
      ),
    ];
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Toggles the boolean variable that keeps track of the email input focus.
   *
   * @memberOf SignInModalComponent
   */
  changeFocus() {
    this.emailFocus = !this.emailFocus;
  }

  /**
   * Closes the template download modal.
   *
   * @memberOf SignInModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }

  /**
   * When user successfully logs into their account, this function will trigger the modal to close.
   * @memberOf SignInModalComponent
   */
  onSignedIn() {
    this.onClose.emit('success');
    this.modalService.close(this.id);
    if (this.redirectLink !== '') {
      window.location.href = this.redirectLink;
    }
  }

  /**
   * Sets the form mode for the download modal.
   *
   * @param {string} formMode
   * @memberOf SignInModalComponent
   */
  renderForm(formMode: string) {
    this.formMode = formMode;
  }

  // Generate dynamic style based on current break point for login form section
  getLoginFormStyle(): string {
    let utilClasses = 'col-12 col-md-6';
    let order = '';
    if (this.curBreakpoint === 'sm' || this.curBreakpoint === 'xs') {
      order = 'order-first';
    } else {
      order = 'order-last';
    }

    return `${utilClasses} ${order}`;
  }

  // Generate dynamic style based on current break point for benefit section
  getUserBenefitStyle(): string {
    let utilClasses = 'col-12 col-md-6';
    let order = '';
    if (this.curBreakpoint === 'sm' || this.curBreakpoint === 'xs') {
      order = 'order-last';
    } else {
      order = 'order-first';
    }

    return `${utilClasses} ${order}`;
  }
}
