import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
} from '@angular/core';
import { ModalSize } from 'navigation/nav-shared/models/types';

@Injectable()
export class AvryModalService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  // is used to open a modal without listening to result returning from the modal
  // good use for notification, helps, material comparision
  openModal(
    modalContainer: any,
    modalComp: any,
    id: string,
    size: ModalSize,
    props: Object,
    isNested: boolean = false
  ) {
    if (!isNested) {
      modalContainer.clear();
    }

    let modalCompRef: ComponentRef<typeof modalComp> = modalContainer.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(modalComp)
    );

    modalCompRef.instance.id = id;
    modalCompRef.instance.size = size;

    Object.keys(props).forEach((key) => {
      modalCompRef.instance[key] = props[key];
    });
  }

  init(
    modalContainer: any,
    modalComp: any,
    id: string,
    size: ModalSize,
    isNested: boolean = false
  ): ComponentRef<any> {
    if (!isNested) {
      modalContainer.clear();
    }

    let modalCompRef: ComponentRef<typeof modalComp> = modalContainer.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(modalComp)
    );

    modalCompRef.instance.id = id;
    modalCompRef.instance.size = size;

    return modalCompRef;
  }
}
