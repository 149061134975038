import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

// Services
import { HeaderService } from '../../../header/header.service';
import { NavigationService } from '../../navigation.service';

// Helpers
import {
  addDashesToPhone,
  invalidEmailValidator,
  NAME_REGEX,
  positiveIntegerValidator,
} from '../../helpers';

// Interfaces
import { DropdownForm } from '../../../form-elements.interface';
import {
  BulkFormConfig,
  OrderBulkFormInterface,
} from '../../models/interfaces';
import isEmpty from 'lodash-es/isEmpty';

@Component({
  selector: 'app-bulk-order',
  templateUrl: './bulk-order.component.html',
})
// NOTE: this is Angular element component. Any changes made here will need to checked in dotcms.
export class BulkOrderComponent implements OnInit, OnDestroy {
  @Input() btntext: string = 'Submit';
  @Input() formtype: string = 'Sales Bulk Order Form Type';
  @Input() messagetypes: string[] = ['promotions'];
  @Input() optintext: string = 'Sign up for Avery Newsletter';
  @Input() sendemail2consumer: string = 'N';
  @Input() skipmagentoflow: string = 'Y';
  @Input() skipsamplerequestdynamoinsert: string = 'N';
  @Input() skipsugarcrm: string = 'N';
  @Input() sourceid: string = '22094';
  @Input() type: string = 'bulk-form';

  // Form variables
  dropdownAllData: any;
  dropdownMoreAllData: any;
  dropdownProductCategoryForm: DropdownForm[];
  dropdownResaleRawForm: DropdownForm[];
  dynamicFieldsData: BulkFormConfig;
  isInvalidCheckProductCategoryDropdown: boolean = false;
  isInvalidCheckResaleRawDropdown: boolean = false;
  isInvalidDropdowns: boolean = false;
  submitError: boolean = false;
  orderReceived: boolean = false;
  orderRequested: boolean = false;
  exportedRequired: boolean = false;
  productCategoryDropdownRequired: boolean = false;
  resaleRawDropdownRequired: boolean = false;
  resaleRawOtherSelectedRequired: boolean = false;
  orderForm: FormGroup;

  // Flags
  showField1: boolean = false;
  showField2: boolean = false;
  showField3: boolean = false;
  showField4: boolean = false;
  showField5: boolean = false;
  showField6: boolean = false;
  showField7: boolean = false;
  showField10: boolean = false;
  showField11: boolean = false;
  showField12: boolean = false;
  otherSelectedShowField10: boolean = false;
  showWhereProductBeingExport: boolean = false;

  // Labels text copy
  labelTextField1: string = '';
  labelTextField2: string = '';
  labelTextField3: string = '';
  labelTextField4: string = '';
  labelTextField4Required: string = '';
  labelTextField5: string = '';
  labelTextField5Required: string = '';
  labelTextField6: string = '';
  labelTextField7: string = '';
  labelTextField7Required: string = '';
  labelTextField10: string = '';
  labelTextField11: string = '';
  labelTextField12: string = '';
  labelOtherSelectedShowField10: string = '';
  submitButtonResponseField1: string = '';
  submitButtonErrorResponseField1: string = '';

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.getMoreFormData();
    this.getDynamicFieldsConfigData();
    this.createForm();
  }

  ngOnDestroy() {}

  /**
   * Creates the form.
   *
   * @memberof BulkOrderComponent
   */
  createForm() {
    this.orderForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(NAME_REGEX),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(NAME_REGEX),
        ],
      ],
      title: [''],
      email: ['', [Validators.required, invalidEmailValidator()]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
          Validators.pattern('[0-9,-]*'),
        ],
      ],
      phoneExt: [''],
      companyName: [''],
      companyWebsite: [''],
      productCategoryDropdown: ['Choose One', [Validators.required]],
      productDescription: ['', [Validators.required]],
      unitsOfProductNeeded: [
        '',
        [
          Validators.required,
          positiveIntegerValidator(),
          Validators.min(1),
          Validators.max(999999),
        ],
      ],
      optIn: [true],
      resaleRawDropdown: ['Choose One', [Validators.required]],
      resaleRawOtherSelected: [''],
    });
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf BulkOrderComponent
   */
  get firstName() {
    return this.orderForm.get('firstName');
  }
  get lastName() {
    return this.orderForm.get('lastName');
  }
  get title() {
    return this.orderForm.get('title');
  }
  get email() {
    return this.orderForm.get('email');
  }
  get phone() {
    return this.orderForm.get('phone');
  }
  get phoneExt() {
    return this.orderForm.get('phoneExt');
  }
  get companyName() {
    return this.orderForm.get('companyName');
  }
  get companyWebsite() {
    return this.orderForm.get('companyWebsite');
  }
  get productDescription() {
    return this.orderForm.get('productDescription');
  }
  get unitsOfProductNeeded() {
    return this.orderForm.get('unitsOfProductNeeded');
  }
  get optIn() {
    return this.orderForm.get('optIn');
  }
  get productCategoryDropdown() {
    return this.orderForm.get('productCategoryDropdown');
  }
  get resaleRawDropdown() {
    return this.orderForm.get('resaleRawDropdown');
  }
  get resaleRawOtherSelected() {
    return this.orderForm.get('resaleRawOtherSelected');
  }

  /**
   * Updates the form phone number value to include dashes
   *
   * @param {Event} event
   * @memberof BulkOrderComponent
   */
  dashPhone(event: Event) {
    let phone = (event.target as HTMLInputElement).value;
    this.phone.setValue(addDashesToPhone(phone));
  }

  /**
   * Demo form function submit request to topic.
   *
   * @memberof BulkOrderComponent
   */
  submitForm() {
    this.checkRequiredDropdowns();

    if (
      this.orderForm.valid &&
      !this.isInvalidDropdowns &&
      !this.resaleRawOtherSelectedRequired
    ) {
      this.orderRequested = true;
      let bulkData = {
        title: this.title.value,
        phoneExt: this.phoneExt.value,
        companyWebsite: this.companyWebsite.value,
        productCategory: this.productCategoryDropdown.value,
        productDescription: this.productDescription.value,
        unitsOfProductNeeded: this.unitsOfProductNeeded.value,
      };

      bulkData = this.processDynamicFields(bulkData);

      const topicFormat = {
        email: this.email.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        company: this.companyName.value,
        optIn: this.optIn.value,
        phone: this.phone.value,
        formType: this.formtype,
        sourceId: this.sourceid,
        messageTypes: this.messagetypes,
        country: 'US',
        sendEmail2Consumer: this.sendemail2consumer,
        skipSugarcrm: this.skipsugarcrm,
        skipSampleRequestDynamoInsert: this.skipsamplerequestdynamoinsert,
        skipMagentoFlow: this.skipmagentoflow,
        bulkData,
      };

      this.headerService
        .addSampleToTopic(topicFormat)
        .pipe(
          map((res) => {
            if (res.status === 'error') {
              throw res.status;
            }
            return res;
          })
        )
        .subscribe(
          (res) => {
            if (res.status === 'error') {
              throw Error;
            }

            if (res.status.indexOf('success') !== -1) {
              this.orderReceived = true;
              setTimeout(() => {
                this.resetAfterSuccess();
              }, 2000);
            }
          },
          (err) => {
            this.submitError = true;
            this.orderRequested = false;
            console.error(err);
          }
        );
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * Update data payload if flag is set to show yes
   *
   * @param {*} data
   * @returns
   * @memberof BulkOrderComponent
   */
  processDynamicFields(data: any): any {
    let bulkData = data;

    if (this.showField10) {
      bulkData = {
        ...bulkData,
        resaleRawDropdown: this.resaleRawDropdown.value,
      };

      if (
        this.resaleRawOtherSelected &&
        this.resaleRawOtherSelected.value !== ''
      ) {
        bulkData = {
          ...bulkData,
          resaleRawOtherSelected: this.resaleRawOtherSelected.value,
        };
      }
    }

    return bulkData;
  }

  /**
   * Resets flags to initial state of the form.
   *
   * @memberof BulkOrderComponent
   */
  resetAfterSuccess(calledBy: string = '') {
    this.orderForm.markAllAsTouched();
    if (calledBy === 'submit') {
      this.checkRequiredDropdowns();
    }

    if (this.orderRequested) {
      this.submitError = false;
      this.orderRequested = false;
      this.orderReceived = false;
      this.orderForm.reset();
      this.resaleRawDropdown.setValue('Choose One');
      this.productCategoryDropdown.setValue('Choose One');
      this.resaleRawOtherSelectedRequired = false;
      this.showWhereProductBeingExport = false;
      this.otherSelectedShowField10 = false;
    }
  }

  /**
   * Get more form data for dropdowns (resale, loading dock)
   *
   * @memberof BulkOrderComponent
   */
  async getMoreFormData() {
    try {
      this.dropdownMoreAllData = await this.navigationService.getDataFromLF(
        'moreFormDropdowns'
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.navigationService
        .fetchConfigData('bulk-form-elements.json')
        .subscribe((data: BulkFormConfig) => {
          this.navigationService.setDataToLF('moreFormDropdowns', data);
          this.dropdownMoreAllData = data;
          this.dropdownProductCategoryForm = this.dropdownMoreAllData.productCategoryForm;
          this.dropdownResaleRawForm = this.dropdownMoreAllData.resaleRawForm;
        });
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   *
   * @param {string} formControlName [Optional]
   * @memberof BulkOrderComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'productCategoryDropdown':
        this.isInvalidCheckProductCategoryDropdown =
          !this.productCategoryDropdown.touched &&
          this.productCategoryDropdown.invalid;
        this.productCategoryDropdownRequired = false;
        break;
      case 'resaleRawDropdown':
        this.isInvalidCheckResaleRawDropdown =
          !this.resaleRawDropdown.touched && this.resaleRawDropdown.invalid;
        this.resaleRawDropdownRequired = false;
        this.otherSelectedShowField10 =
          this.resaleRawDropdown.value === 'Other';
        break;
      default:
        this.isInvalidCheckProductCategoryDropdown = !this
          .productCategoryDropdown.touched;
        this.isInvalidCheckResaleRawDropdown = !this.resaleRawDropdown.touched;
    }
  }

  /**
   * Checks for required dropdown/s. There should be a value selected and not the default value.
   *
   * @memberof BulkOrderComponent
   */
  checkRequiredDropdowns() {
    if (this.productCategoryDropdown.value === 'Choose One') {
      this.productCategoryDropdownRequired = true;
      this.isInvalidDropdowns = true;
    }

    if (this.resaleRawDropdown.value === 'Choose One') {
      this.resaleRawDropdownRequired = true;
      this.isInvalidDropdowns = true;
    } else {
      this.resaleRawDropdownRequired = false;
      this.isInvalidDropdowns = false;
      if (
        this.resaleRawDropdown.value === 'Other' &&
        (this.resaleRawOtherSelected.value === null ||
          this.resaleRawOtherSelected.value === '')
      ) {
        this.resaleRawOtherSelectedRequired = true;
      } else {
        this.resaleRawOtherSelectedRequired = false;
      }
    }
  }

  /**
   * Get dynamic fields config data
   *
   * @memberof FreeSamplePackComponent
   */
  async getDynamicFieldsConfigData() {
    try {
      this.dynamicFieldsData = await this.navigationService.getDataFromLF(
        'formDynaFields'
      );
      this.checkDataExist(this.dynamicFieldsData);
    } catch (e) {
      console.error(e);
    } finally {
      this.navigationService
        .fetchConfigData('dynamic-fields-config2.json')
        .subscribe((data: BulkFormConfig) => {
          this.navigationService.setDataToLF('formDynaFields', data);
          this.dynamicFieldsData = data;
          this.checkDataExist(this.dynamicFieldsData);
        });
    }
  }

  /**
   * Update flags if pass object has data in it.
   *
   * @param {BulkFormConfig} data
   * @memberof BulkOrderComponent
   */
  checkDataExist(data: BulkFormConfig) {
    if (!isEmpty(data)) {
      if (this.type === this.dynamicFieldsData.configs[0].type) {
        this.updateShowFlags(this.dynamicFieldsData.configs[0].dynamicFields);
      }
    }
  }

  /**
   * Update show flags based from config file values.
   *
   * @param {OrderBulkFormInterface[]} data
   * @memberof BulkOrderComponent
   */
  updateShowFlags(dynamicFieldsData: OrderBulkFormInterface[]) {
    dynamicFieldsData.forEach((item: OrderBulkFormInterface) => {
      switch (item !== null) {
        case item.field === 'field10' && item.show === 'Yes':
          this.showField10 = true;
          this.labelTextField10 = item.label;
          break;
        case item.field === 'field11' && item.show === 'Yes':
          this.showField11 = true;
          this.labelTextField11 = item.label;
          break;
        case item.field === 'field12' && item.show === 'Yes':
          this.showField12 = true;
          this.labelTextField12 = item.label;
          break;
        case item.field === 'field1' && item.show === 'Yes':
          this.showField1 = true;
          this.labelTextField1 = item.label;
          break;
        case item.field === 'field2' && item.show === 'Yes':
          this.showField2 = true;
          this.labelTextField2 = item.label;
          break;
        case item.field === 'field3' && item.show === 'Yes':
          this.showField3 = true;
          this.labelTextField3 = item.label;
          break;
        case item.field === 'field4' && item.show === 'Yes':
          this.showField4 = true;
          this.labelTextField4 = item.label;
          break;
        case item.field === 'field5' && item.show === 'Yes':
          this.showField5 = true;
          this.labelTextField5 = item.label;
          break;
        case item.field === 'field6' && item.show === 'Yes':
          this.showField6 = true;
          this.labelTextField6 = item.label;
          break;
        case item.field === 'field7' && item.show === 'Yes':
          this.showField7 = true;
          this.labelTextField7 = item.label;
          break;
        case item.field === 'otherSelectedShowField10':
          this.labelOtherSelectedShowField10 = item.label;
          break;
        case item.field === 'submitButtonResponseField1':
          this.submitButtonResponseField1 = item.label;
          break;
        case item.field === 'submitButtonErrorResponseField1':
          this.submitButtonErrorResponseField1 = item.label;
          break;
      }
    });
  }
}
