import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator, isValidName } from '../../../helpers/customValidators';
import isEmpty from 'lodash-es/isEmpty';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../authentication.service';
import { FooterSubscribeModalComponent } from '../../../../footer/footer-subscribe-form/footer-subscribe-modal/footer-subscribe-modal.component';
import { environment } from 'environments/environment';
import { mergeMap } from 'rxjs/operators';
import { HeaderService } from '../../../../header/header.service';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import { ModalService } from '../../modal/modal.service';
import { emitNewsletterSignUp } from 'navigation/nav-shared/helpers';

@Component({
  selector: 'app-email-signup-md',
  templateUrl: './email-signup-md.component.html',
  styleUrls: ['./email-signup-md.component.scss'],
})
export class EmailSignupMdComponent implements OnInit, OnDestroy {
  @Input() altvalue: string = 'Avery Trade Show Signup Form';
  @Input() btntext: string = 'SIGN UP';
  @Input() formtype: string = 'default';
  @Input() headersubtext: string =
    'Sign up to receive exclusive offers, helpful tips and the latest label digital news from Avery!';
  @Input() headertext: string =
    'Want to save money? Need help with a labeling project?';
  @Input()
  imageurl: string = `${environment.image}/f_auto,q_auto,c_scale,w_700/web/avery-tile-newslettersignup`;
  @Input() messagetypes: string = 'promotions';
  @Input() optintext: string;
  @Input() placeholder: string = 'Enter Email Address';
  @Input() sourceid: string = '22049';
  @Input() thememode: string = 'default';
  @Input() skipsugarcrm: string = 'N';
  @Input() sugarcrmsubject: string = 'Natural Products Expo';
  @ViewChild('email', { static: false }) emailInput;
  @ViewChild('fNameInput', { static: false }) firstNameInput;
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer;

  signupForm: FormGroup;
  creatingAccount: boolean = false;
  emailExists: boolean = false;
  invalidEmail: boolean = false;
  overFirstNameMaxLength: boolean;
  overLastNameMaxLength: boolean;
  passwordType: string = 'password';
  queryParams: any = {};
  subscriptions: Array<Subscription> = [];
  submitStatus: string = null;
  userFocus: string;
  validFirstName: boolean = true;
  validLastName: boolean = true;
  viewHideMsg: string = 'View';

  // Required Field Form Flags
  isInvalidCheckCompanyName: boolean = false;
  isInvalidCheckJobTitle: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private avryModalService: AvryModalService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   *  Prevents any errors messages from showing when an input is focused
   *
   * @param {string} formControl
   * @memberof EmailSignupMdComponent
   */
  changeFocus(formControl: string) {
    if (formControl === 'email') {
      this.emailExists = false;
      this.invalidEmail = false;
    }
    this.userFocus = formControl;
  }

  /**
   * Initializes the creat account form
   *
   * @memberof EmailSignupMdComponent
   */
  createForm() {
    this.signupForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      companyName: [
        '',
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      jobTitle: [
        '',
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      optIn: [true],
    });

    this.subscriptions = [
      this.firstName.valueChanges.subscribe((data) => {
        if (!isEmpty(data)) {
          this.overFirstNameMaxLength = data.length > 50;
          this.validFirstName = isValidName(data);
        }
      }),
      this.lastName.valueChanges.subscribe((data) => {
        if (!isEmpty(data)) {
          this.overLastNameMaxLength = data.length > 50;
          this.validLastName = isValidName(data);
        }
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf EmailSignupMdComponent
   */
  get newEmail() {
    return this.signupForm.get('email');
  }
  get firstName() {
    return this.signupForm.get('fName');
  }
  get lastName() {
    return this.signupForm.get('lName');
  }
  get companyName() {
    return this.signupForm.get('companyName');
  }
  get jobTitle() {
    return this.signupForm.get('jobTitle');
  }
  get optIn() {
    return this.signupForm.get('optIn');
  }

  /**
   * Function handles calling the service to create an acoount and then redirect the user to a myaccount page.
   * Otherwise it will display an error message for the missing form values.
   * @param event
   * @memberOf EmailSignupMdComponent
   */
  doSignup(event) {
    event.preventDefault();
    this.emailExists = false;

    if (this.signupForm.valid && this.validFirstName && this.validLastName) {
      this.creatingAccount = true;

      this.authService
        .emailSignUp({
          email: this.newEmail.value,
          fName: this.firstName.value,
          lName: this.lastName.value,
          optIn: this.optIn.value,
          sourceid: this.sourceid,
          messageTypes: [this.messagetypes],
        })
        .pipe(
          mergeMap(() => {
            const reqObj = this.setEmailServiceFormat();
            // Call sugarcrm service
            return this.headerService.postSugarCrmWebCaseRequest(reqObj);
          })
        )
        .subscribe(
          () => {
            // emit GA event for newsletter sign-up
            emitNewsletterSignUp();
            switch (this.formtype) {
              case 'form-only':
                this.creatingAccount = false;
                this.submitStatus = 'success';
                break;
              default:
                const modalRef = this.avryModalService.init(
                  this.modalContainer,
                  FooterSubscribeModalComponent,
                  'footer-subscrib-modal',
                  'lg'
                );
                modalRef.instance.status = 'success';
                setTimeout(
                  () => this.modalService.close('footer-subscrib-modal'),
                  5000
                );
                this.signupForm.reset();
                setTimeout(() => (this.creatingAccount = false), 500);
            }
          },
          (err) => {
            console.error(err);
            if (err.error === 'Email already exists.') {
              status = 'exists';
            } else {
              status = 'error';
            }

            const modalRef = this.avryModalService.init(
              this.modalContainer,
              FooterSubscribeModalComponent,
              'footer-subscrib-modal',
              'lg'
            );
            modalRef.instance.status = status;
          }
        );
    } else {
      // if the form values are invalid when the user submits then they will get an error message.
      if (!this.newEmail.valid) {
        this.newEmail.markAsDirty();
      }
      if (!this.firstName.valid) {
        this.firstName.markAsTouched();
      }
      if (!this.lastName.valid) {
        this.lastName.markAsTouched();
      }
    }
  }

  /**
   * Formats data for sugarcrm.
   *
   * @returns {any}
   * @memberof EmailSignupMdComponent
   */
  setEmailServiceFormat(): any {
    return {
      name: `${this.firstName.value} ${this.lastName.value}`,
      email: this.newEmail.value,
      phone: '',
      subject: this.sugarcrmsubject || '',
      description: this.headertext || '',
      company_name: this.companyName.value || '',
      job_title: this.jobTitle.value || '',
      optIn: this.optIn.value || false,
    };
  }

  /**
   * This valid input check will check if total amount of inputs is less than 50. Once the input value reaches
   * the limit, an error message will pop in the form indicating that the user has reached it's limit for key input.
   * @param event
   * @param {string} value
   * @memberOf EmailSignupMdComponent
   */
  isValidInput(event, value: string) {
    // NOTE: the value length is one-off.
    let isValidKey = value.length <= 50;
    // if the length greater or equal to 50, then allow the user to delete or move the cursor.
    if (value.length >= 50) {
      if (event.key) {
        // NOTE: `event.key` is the recomended property but some browsers might still not support this property. `event.keyIdentifier` or `event.keyCode` will be the fall back.
        isValidKey =
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Backspace' ||
          event.key === 'Del';
      } else if (event.keyIdentifier) {
        const keyValue = event.keyIdentifier.includes('U+')
          ? String.fromCharCode(event.keyIdentifier.replace('U+', '0x'))
          : event.keyIdentifier;

        isValidKey =
          keyValue === 'Left' ||
          keyValue === 'Right' ||
          event.keyIdentifier === 'U+0008' ||
          event.keyIdentifier === 'U+007F';
      } else if (event.keyCode) {
        isValidKey =
          event.keyCode === 8 ||
          event.keyCode === 37 ||
          event.keyCode === 39 ||
          event.keyCode === 46;
      }
    }

    if (!isValidKey) {
      event.preventDefault();
    }
  }
}
