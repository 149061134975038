import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhsSamplePackComponent } from './ghs-sample-pack.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import {
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
} from 'ng-recaptcha';
import { config } from '../../../../environments/environment';

@NgModule({
  declarations: [GhsSamplePackComponent],
  imports: [
    CommonModule,
    ContentLoaderModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [GhsSamplePackComponent],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: config.recaptchaKey,
      } as RecaptchaSettings,
    },
  ],
})
export class GhsSamplePackModule {}
