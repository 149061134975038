import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSearchComponent } from './product-search.component';
import { CommonTypeaheadModule } from '../common-typeahead/common-typeahead.module';

@NgModule({
  declarations: [ProductSearchComponent],
  imports: [CommonModule, CommonTypeaheadModule],
  exports: [ProductSearchComponent],
})
export class ProductSearchModule {}
