import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkOrderComponent } from './bulk-order.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NumericInputDirective } from 'navigation/nav-shared/directive/input/numeric-input.directive';

@NgModule({
  declarations: [BulkOrderComponent, NumericInputDirective],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [BulkOrderComponent],
})
export class BulkOrderModule {}
