import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// Helpers
import {
  emailValidator,
  getQueryParamByName,
  localStorageSetter,
} from '../../../helpers';

// Store and observable operators
import { Store } from '@ngrx/store';
import { GET_PROFILE } from '../../../../../ngrx/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';

// miscellaneous
import isEmpty from 'lodash-es/isEmpty';
import { environment } from '../../../../../environments/environment';

// services
import { AuthenticationService } from '../../../authentication.service';
import { HeaderService } from '../../../../header/header.service';
import { NavigationService } from '../../../navigation.service';

// Interface
import { SignInInterface } from '../../../models/interfaces';

@Component({
  selector: 'usr-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() checkbox: boolean = true;
  @Input() emailAddress = '';
  @Input() signInFocus: string = '';
  @Input() signInMode: string;

  @Output() pageRedirect = new EventEmitter();
  @ViewChild('inputEmail', { static: false }) emailInput;
  @ViewChild('pwInput', { static: false }) passwordInput;

  forgotPwLink = `${environment.appDomain}/secure/password-reset-request`;
  instanceId = Math.floor(Math.random() * (100 - 1));
  invalidEmail: boolean = false;
  invalidPassword: boolean = false;
  passwordLength: number = 0;
  accountMenuActive: boolean = false;
  passwordReset: boolean = false;
  privacyLink: string = `${environment.domain}/about-us/legal/privacy-policy`;
  signInForm: FormGroup;
  subscriptions: Subscription[] = [];
  themeMode: string = null;
  userFocus = false;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private navService: NavigationService,
    private headerService: HeaderService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.createSignInForm();

    // TODO: Use the ActivatedRoute dependency injection, in order to get the querystring values.
    if (this.signInMode === 'sign-in') {
      const resetRedirect = getQueryParamByName('prevPage');
      if (resetRedirect === 'pwReset') {
        this.passwordReset = true;
      }
    }

    this.subscriptions = [
      this.averyThemeModeSubscription(),
      this.myAccountActiveSubscription(),
    ];
  }

  ngAfterViewInit() {
    if (this.signInFocus === 'email') {
      this.emailInput.nativeElement.focus();
    } else if (this.signInFocus === 'password') {
      this.passwordInput.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Return subscription for the theme mode
   *
   * @returns {Subscription}
   * @memberof SignInFormComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navService.themeMode.subscribe(
      (type: string) => (this.themeMode = type)
    );
  }

  /**
   * Return subscription for My Account Menu Activation.
   *
   * @returns {Subscription}
   * @memberof SignInFormComponent
   */
  myAccountActiveSubscription(): Subscription {
    return this.headerService.myAccountActive.subscribe((value) => {
      this.accountMenuActive = value;
      if (value) {
        this.signInForm.reset();
      }
    });
  }

  /**
   * Handles creating to form-group controllers for the sign-in form.
   *
   * @memberOf SignInFormComponent
   */
  createSignInForm() {
    // this condition will determine whether or not checkbox in the form is required for validation.
    if (this.checkbox) {
      this.signInForm = this.fb.group({
        email: [
          this.emailAddress,
          Validators.compose([Validators.required, emailValidator]),
        ],
        password: ['', Validators.compose([Validators.required])],
        acknowledgeTOS: [false, Validators.required],
      });
    } else {
      this.signInForm = this.fb.group({
        email: [
          this.emailAddress,
          Validators.compose([Validators.required, emailValidator]),
        ],
        password: ['', Validators.compose([Validators.required])],
      });
    }
  }

  /**
   * Redirects the user to the registration page
   * @memberOf MyAccountMenuComponent
   */
  openCreateAccount(event) {
    event.preventDefault();
    window.location.href = `${environment.appDomain}/secure/register`;
    this.headerService.shadowOverlay.next(false);
    this.headerService.navMenuActive.next(false);
  }

  /**
   * Angular Forms boilerplate to include interactive sign-in form
   *
   * @return {AbstractControl | null}
   */
  get signInEmail() {
    return this.signInForm.get('email');
  }
  get signInPass() {
    return this.signInForm.get('password');
  }

  /**
   * Handles boolean variable that is used to detect if an input is focused or not.
   *
   * @memberOf SignInFormComponent
   */
  changeFocus() {
    if (!this.userFocus) {
      this.userFocus = true;
    } else {
      this.userFocus = false;
    }

    if (this.invalidPassword) {
      this.invalidPassword = !this.invalidPassword;
    }
    if (this.invalidEmail) {
      this.invalidEmail = !this.invalidEmail;
    }
  }

  passwordHandler() {
    this.passwordLength = this.passwordInput.nativeElement.value.length;
  }

  /**
   * Handles the user's submission to sign into my accounts, and handle the authentication verification of a user
   * after they've successfully signed in.
   *
   * @memberOf SignInFormComponent
   */
  signInUser() {
    let signInErr = false;
    let consumerId = null;
    if (this.signInForm.valid) {
      this.authenticationService
        .signInUser(
          this.signInEmail.value.trim().toLowerCase(),
          this.signInPass.value
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            signInErr = true;
            return throwError(error);
          }),
          switchMap((authData: SignInInterface) => {
            const accessData = JSON.stringify({
              u_at: authData.access_token,
              expiry: Date.now() + authData.expires_in * 1000,
            });
            consumerId = authData.uid;
            localStorageSetter('u_at', accessData);
            localStorageSetter('u_rt', authData.refresh_token);

            return this.authenticationService.getUserInfo(
              authData.access_token
            );
          }),
          switchMap((data: any) => {
            this.store.dispatch({
              type: GET_PROFILE,
              payload: {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                optIn: data.optIn,
              },
            });
            localStorageSetter('u_i', JSON.stringify(data));
            this.authenticationService.userSignedInUpdate(true);
            if (!isEmpty(consumerId)) {
              this.authenticationService.setGAUser(consumerId);
              this.authenticationService.DYEventLogin(consumerId);
            }

            return this.authenticationService.getStencilList();
          })
        )
        .subscribe(
          (data) => {
            if (!isEmpty(data)) {
              const aryStencils = data.filter((stencil: any) => {
                if (stencil.stencilOrders.length > 0) {
                  return stencil;
                }
              });
              this.authenticationService.updateStencilStorage(aryStencils);
              this.authenticationService.stencilExists.next(
                aryStencils.length > 0
              );
            } else {
              this.authenticationService.stencilExists.next(false);
            }

            // Checks to see if the user is signing from the sign in page, and handle redirecting users to the appropriate
            // my account page.
            switch (this.signInMode) {
              case 'sign-in':
                const urlRedirect = getQueryParamByName('returnUrl');
                const pageRedirect = getQueryParamByName('returnPage');
                const _window = this.navService.getNativeWindow();

                if (!isEmpty(urlRedirect)) {
                  _window.location.href = urlRedirect;
                } else if (
                  !isEmpty(pageRedirect) &&
                  this.navService.routeIsInAngularApp(pageRedirect)
                ) {
                  // _window.location.href = `${_window.location.origin}${pageRedirect}`;
                  this.pageRedirect.emit(pageRedirect);
                } else {
                  this.pageRedirect.emit('/myaccount/projects');
                  // _window.location.href = `${_window.location.origin}/myaccount/projects`;
                }
                break;
              case 'sign-in-modal':
                this.authenticationService.downloadStatus.next('download');
              default:
                if (this.navService.routeIsMagentoApp()) {
                  this.navService.getMagento().subscribe();
                }
            }
          },
          (error: HttpErrorResponse) => {
            if (signInErr) {
              const errResponse = error.error;
              this.authenticationService.userSignedIn.next(false);
              if (errResponse.errorMessage === 'Invalid password.') {
                this.invalidPassword = true;
              } else if (errResponse.errorMessage === 'User does not exist.') {
                this.invalidEmail = true;
              }
            } else {
              // Handles errors from the stencil services, and continues to redirect the user.
              this.authenticationService.stencilExists.next(false);

              if (this.signInMode === 'sign-in') {
                const urlRedirect = getQueryParamByName('returnUrl');
                const pageRedirect = getQueryParamByName('returnPage');
                const _window = this.navService.getNativeWindow();
                if (!isEmpty(urlRedirect)) {
                  _window.location.href = `${urlRedirect}`;
                } else if (
                  !isEmpty(pageRedirect) &&
                  this.navService.routeIsInAngularApp(pageRedirect)
                ) {
                  _window.location.href = `${_window.location.origin}/${pageRedirect}`;
                } else {
                  _window.location.href = `${_window.location.origin}/myaccount/projects`;
                }
              } else {
                if (this.navService.routeIsMagentoApp()) {
                  this.navService.getMagento().subscribe();
                }
              }
            }
          }
        );
    } else {
      this.signInEmail.markAsDirty();
      this.signInPass.markAsDirty();
    }
  }
}
