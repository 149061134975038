import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';

// Observables and rxjs
import { Subscription, throwError } from 'rxjs';

// Services
import { HeaderService } from '../../../header/header.service';
import { NavigationService } from '../../../../navigation/nav-shared/navigation.service';

// Helpers
import { emailValidator, isValidName } from '../../helpers';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ind-whitepaper-sample-pack',
  templateUrl: './ind-whitepaper-sample-pack.component.html',
})
export class IndWhitepaperSamplePackComponent implements OnInit, OnDestroy {
  @Input() acgconfirmationtext: string;
  @Input() acgimagegallery: string;
  @Input() acglimitone: string;
  @Input() acgoverview: string;
  @Input() btntext: string;
  @Input() completemessagetype: string;
  @Input() formtitle1: string;
  @Input() formtitle2: string;
  @Input() formtype: string;
  @Input() messagetypes: string[] = ['promotions'];
  @Input() optintext: string;
  @Input() pdflocation: string;
  @Input() samplepackid: string;
  @Input() sendemail2consumer: string;
  @Input() skipmagentoflow: string;
  @Input() skipsamplerequestdynamoinsert: string;
  @Input() skipsugarcrm: string;
  @Input() sourceid: string;
  @Input() sourceidarray: string;
  @Input() sourceidinfo1: string;
  @Input() sourceidinfo2: string;
  @Input() title: string;
  @Input() title2: string;

  arySourceId: Array<any> = [];
  acgConfirmationText: SafeHtml;
  acgImageGallery: SafeHtml;
  acgLimitOne: SafeHtml;
  acgOverview: SafeHtml;
  acgTitle1: SafeHtml;
  acgTitle2: SafeHtml;
  creatingAccount: boolean = false;
  dropdownAllData: any;
  errorFlag: boolean = false;
  formTitle1: SafeHtml;
  formTitle2: SafeHtml;
  initflag: boolean = true;
  isSourceIdSelected: boolean = true;
  fspForm: FormGroup;
  pdfURL: string;
  samplePackType: string = 'default';
  showLimitFirstName: boolean;
  showLimitLastName: boolean;
  sourceIdSet: Set<string> = new Set();
  sourceIdStr: string = '';
  successFlag: boolean = false;
  validFirstName: boolean = true;
  validLastName: boolean = true;
  validCompanyName: boolean = true;
  validJobTitle: boolean = true;
  subscriptions: Array<Subscription> = [];
  thememode: string;

  // Message Flags
  isInvalidFormMessage: boolean = false;
  isVisible: boolean = true;
  samplePackBtnSubmitted: boolean = false;
  samplePackLoader: boolean = false;

  // Required Field Form Flags
  isInvalidCheckFirstName: boolean = false;
  isInvalidCheckLastName: boolean = false;
  isInvalidCheckCompanyName: boolean = false;
  isInvalidCheckJobTitle: boolean = false;
  isInvalidCheckEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private navigationService: NavigationService
  ) {}

  async ngOnInit() {
    this.getPDFUrl();
    this.fetchAcgData();
    this.subscriptions = this.createFreeSamplePackForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the form controls and returns an array of subscription for all
   * value changes on some of the form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof IndWhitepaperSamplePackComponent
   */
  createFreeSamplePackForm(): Array<Subscription> {
    this.fspForm = this.fb.group({
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      companyName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      jobTitle: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      optIn: [true],
    });

    return [
      this.firstName.valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitFirstName = true;
        } else {
          this.showLimitFirstName = false;
        }
        this.validFirstName = isValidName(data);
        if (this.validFirstName) {
          this.validFirstName = this.validateMore(data);
        }
      }),
      this.lastName.valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitLastName = true;
        } else {
          this.showLimitLastName = false;
        }
        this.validLastName = isValidName(data);
        if (this.validLastName) {
          this.validLastName = this.validateMore(data);
        }
      }),
      this.companyName.valueChanges.subscribe((data) => {
        this.validCompanyName = this.validateMore(data);
      }),
      this.jobTitle.valueChanges.subscribe((data) => {
        this.validJobTitle = this.validateMore(data);
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf IndWhitepaperSamplePackComponent
   */
  get newEmail() {
    return this.fspForm.get('email');
  }
  get firstName() {
    return this.fspForm.get('fName');
  }
  get lastName() {
    return this.fspForm.get('lName');
  }
  get companyName() {
    return this.fspForm.get('companyName');
  }
  get jobTitle() {
    return this.fspForm.get('jobTitle');
  }
  get optIn() {
    return this.fspForm.get('optIn');
  }

  /** TODO: finish switch case
   * Populate PDF URL based off sourceId input.
   *
   * @memberof IndWhitepaperSamplePackComponent
   */
  getPDFUrl() {
    if (isEmpty(this.pdflocation)) {
      switch (this.sourceid) {
        case '22024':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/whitepaper-ghs-labeling-requirements-industrial.pdf`;
          break;
        case '22027':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2018_Whitepages_Avery_Surface_Safe_Signs_for_Industrial_Spaces.pdf`;
          break;
        case '22041':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2017_Durable_vs_Paper_Article.pdf`;
          break;
        case '22042':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2018_Whitepages_Avery_Surface_Safe_Signs_6S_for_Manufacturing_FINAL.pdf`;
          break;
        case '22067':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2018_Whitepages_Avery_Surface_Safe_Signs_5S_for_Manufacturing.pdf`;
          break;
        case '22068':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2018_Whitepages_Avery_Sign_Labels_for_Commercial_Use.pdf`;
          break;
        case '22070':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/Avery_Asset_Tags_Buyers_Guide_PN12715449_OL.pdf`;
          break;
        case '22074':
          this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/2020-industrial-whitepaper-asset-tag-evaluators-guide.pdf`;
          break;
      }
    } else {
      this.pdfURL = `${environment.domain}/app/docs/industrial/whitepaper/${this.pdflocation}`;
    }
  }

  /**
   * Validate entered first name, last name and company for invalid character/s.
   *
   * @param {string} input
   * @returns {boolean}
   * @memberof IndWhitepaperSamplePackComponent
   */
  validateMore(input: string): boolean {
    if (
      input.indexOf('--') !== -1 ||
      input.indexOf('<') !== -1 ||
      input.indexOf('>') !== -1 ||
      (input.indexOf('-') !== -1 && input.length === 1)
    ) {
      return false;
    }

    return true;
  }

  /**
   * Fetch static content from ACG dotCMS contents.
   *
   * @memberof IndWhitepaperSamplePackComponent
   */
  async fetchAcgData() {
    if (!isEmpty(this.title)) {
      this.acgTitle1 = this.navigationService.sanitizeContent(this.title);
    }
    if (!isEmpty(this.title2)) {
      this.acgTitle2 = this.navigationService.sanitizeContent(this.title2);
    }
    if (!isEmpty(this.formtitle1)) {
      this.formTitle1 = this.navigationService.sanitizeContent(this.formtitle1);
    }
    if (!isEmpty(this.formtitle2)) {
      this.formTitle2 = this.navigationService.sanitizeContent(this.formtitle2);
    }
    if (!isEmpty(this.acgoverview)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgoverview, 'form')
        .subscribe(
          (data) =>
            (this.acgOverview = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acglimitone)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acglimitone, 'form')
        .subscribe(
          (data) =>
            (this.acgLimitOne = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgconfirmationtext)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgconfirmationtext, 'form')
        .subscribe(
          (data) =>
            (this.acgConfirmationText = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgimagegallery)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgimagegallery, 'form')
        .subscribe(
          (data) =>
            (this.acgImageGallery = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
  }

  /**
   * Checks whether to display Invalid Sample Pack form message.
   *
   * @memberof IndWhitepaperSamplePackComponent
   */
  checkFormValidationMessage() {
    if (this.fspForm.valid) {
      this.isInvalidFormMessage = false;
    } else {
      this.isInvalidFormMessage = true;
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   * If no parameter is supplied, it will check all Sample Pack required form fields.
   *
   * @param {string} formControlName [Optional]
   * @memberof IndWhitepaperSamplePackComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'firstName':
        this.isInvalidCheckFirstName = this.firstName.invalid;
        break;
      case 'lastName':
        this.isInvalidCheckLastName = this.lastName.invalid;
        break;
      case 'companyName':
        this.isInvalidCheckCompanyName = this.companyName.invalid;
        break;
      case 'jobTitle':
        this.isInvalidCheckJobTitle = this.jobTitle.invalid;
        break;
      case 'email':
        this.isInvalidCheckEmail = this.newEmail.invalid;
        break;
      default:
        // Check All of the the Required Fields:
        this.isInvalidCheckFirstName = this.firstName.invalid;
        this.isInvalidCheckLastName = this.lastName.invalid;
        this.isInvalidCheckCompanyName = this.companyName.invalid;
        this.isInvalidCheckJobTitle = this.jobTitle.invalid;
        this.isInvalidCheckEmail = this.newEmail.invalid;
    }
  }

  /**
   * Sample pack submit form function using topic/queue based flow.
   *
   * @memberof IndWhitepaperSamplePackComponent
   */
  fspSubmitToTopic() {
    this.checkFormValidationMessage();

    if (
      this.fspForm.valid &&
      this.validFirstName &&
      this.validLastName &&
      this.validCompanyName &&
      this.validJobTitle
    ) {
      this.creatingAccount = true;
      if (!isEmpty(this.fspForm)) {
        this.samplePackBtnSubmitted = true;
        this.samplePackLoader = true;

        // Calls endpoint to publish this request to an SNS topic
        this.headerService
          .addSampleToTopic(this.setEmailServiceFormat(this.fspForm))
          .subscribe(
            (resp) => {
              if (resp.status === 'error') {
                this.errorFlag = true;
              } else {
                this.successFlag = true;
              }

              this.initflag = false;
              this.isVisible = false;
              if (this.successFlag) {
                setTimeout(() => {
                  this.creatingAccount = false;
                  this.openWhitepaperDocument();
                }, 300);
              } else {
                setTimeout(() => (this.creatingAccount = false), 300);
              }
            },
            (error) => {
              console.error(error);
              this.initflag = false;
              this.errorFlag = true;
              this.isVisible = false;
              setTimeout(() => (this.creatingAccount = false), 500);
            }
          );
      }
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * Open a new tab rendering the PDF document to be downloaded.
   *
   * @memberof IndWhitepaperSamplePackComponent
   */
  openWhitepaperDocument() {
    window.open(this.pdfURL, '_blank');
  }

  /**
   * This function will structure the form data before publishing it to AWS topic.
   *
   * @param {FormGroup} form
   * @returns {any}
   * @memberof IndWhitepaperSamplePackComponent
   */
  setEmailServiceFormat(form: FormGroup): any {
    const samplePackData = {
      email: this.newEmail.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      company: this.companyName.value,
      optIn: this.optIn.value,
      formType: this.formtype,
      sourceId: this.sourceid,
      jobTitle: this.jobTitle.value,
      messageTypes: this.messagetypes,
      country: 'US',
      spName: this.samplePackType,
      type: this.samplePackType,
      sendEmail2Consumer: this.sendemail2consumer,
      skipSugarcrm: this.skipsugarcrm,
      skipSampleRequestDynamoInsert: this.skipsamplerequestdynamoinsert,
      skipMagentoFlow: this.skipmagentoflow,
    };

    return samplePackData;
  }
}
