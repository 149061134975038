import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModalComponent } from './message-modal.component';
import { ModalModule } from '../modal/modal.module';
@NgModule({
  declarations: [MessageModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [MessageModalComponent],
})
export class MessageModalModule {}
