import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailQuoteCompletionComponent } from './email-quote-completion.component';
import { AcgModule } from '../acg/acg.module';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [EmailQuoteCompletionComponent],
  imports: [CommonModule, AcgModule, ModalModule],
  exports: [EmailQuoteCompletionComponent],
})
export class EmailQuoteCompletionModule {}
