export function getBreakPoint() {
  const breakpointContent = window
    .getComputedStyle(document.body, ':before')
    .getPropertyValue('content')
    .replace(/[\"|\']/g, '')
    .split(':');
  const breakpointLabel = breakpointContent[0];
  const breakpoint = Number(breakpointContent[1].replace('px', ''));

  const result = [breakpointLabel, breakpoint];

  return result;
}

export function isMobile() {
  const breakpoint = getBreakPoint();

  if (breakpoint[0] === 'xs' || breakpoint[0] === 'sm') {
    return true;
  }

  return false;
}
