import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailSignupComponent } from './email-signup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';

@NgModule({
  declarations: [EmailSignupComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [EmailSignupComponent],
  providers: [AvryModalService],
})
export class EmailSignupModule {}
