import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcgComponent } from './acg.component';
import { AcgService } from 'navigation/nav-shared/services/acg.service';
// import { SafeHtmlModule } from 'navigation/nav-shared/pipes/safe-html/safe-html.module';

@NgModule({
  declarations: [AcgComponent],
  imports: [CommonModule],
  exports: [AcgComponent],
  providers: [AcgService],
})
export class AcgModule {}
