import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import {
  AveryCustomGenericConfig,
  AveryCustomGenericInterface,
  DotCMSGraphQLACGConfigPayload,
  DotCMSGraphQLACGPayload,
} from '../models/interfaces';
import { map, take, tap } from 'rxjs/operators';
import localForage from 'localforage';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AcgService {
  private BASE_URL: string = `${environment.domain}/api/v1/graphql`;
  private averyCacheInst: LocalForage = localForage.createInstance({
    name: 'Avery Cache',
  });

  navACGs = new BehaviorSubject<AveryCustomGenericInterface[]>(null);

  constructor(private http: HttpClient) {}

  async fetchACGConfig(name: string): Promise<AveryCustomGenericConfig | null> {
    const fetchData = (): Promise<AveryCustomGenericConfig | null> => {
      return this.http
        .post<DotCMSGraphQLACGConfigPayload>(this.BASE_URL, {
          query: `{
            averyCustomGenericConfigCollection(query: "+(AveryCustomGenericConfig.name:${name} AveryCustomGenericConfig.name_dotraw:${name})") {
              name
              acgs{
                name
                urlTitle
                type
                body
              }
            }
          }`,
        })
        .pipe(
          take(1),
          tap((payload) => {
            this.navACGs.next(
              payload.data.averyCustomGenericConfigCollection[0].acgs
            );
            this.averyCacheInst
              .setItem(name, payload.data.averyCustomGenericConfigCollection[0])
              .catch((err) => {
                console.error('Error caching data');
                console.error(err);
              });
          }),
          map((payload) => {
            return payload.data.averyCustomGenericConfigCollection[0];
          })
        )
        .toPromise();
    };

    try {
      let data;
      try {
        data = await this.averyCacheInst
          .getItem<AveryCustomGenericConfig>(name)
          .then((data) => {
            if (data) {
              this.navACGs.next(data.acgs);
              setTimeout(() => {
                fetchData();
              }, 20000);

              return data as AveryCustomGenericConfig;
            }
            return null;
          });
      } catch (error) {
        console.log('local storage is not available');
        console.log(error);
      }

      if (!data) {
        data = await fetchData();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchACG(
    urlTitle: string,
    type: string
  ): Promise<AveryCustomGenericInterface | null> {
    const fetchData = (): Promise<AveryCustomGenericInterface | null> => {
      return this.http
        .post<DotCMSGraphQLACGPayload>(this.BASE_URL, {
          query: `{
            averycustomgenericCollection(query: "+(Averycustomgeneric.urlTitle:${urlTitle} Averycustomgeneric.urlTitle_dotraw:${urlTitle}) +Averycustomgeneric.type_dotraw:*${type}*") {
              name
              urlTitle
              body
            }
          }`,
        })
        .pipe(
          take(1),
          tap((payload) =>
            this.averyCacheInst
              .setItem(urlTitle, payload.data.averycustomgenericCollection[0])
              .catch((err) => {
                console.error('Error caching data');
                console.error(err);
              })
          ),
          map((payload) => {
            return payload.data.averycustomgenericCollection[0];
          })
        )
        .toPromise();
    };

    try {
      let data;
      try {
        data = await this.averyCacheInst
          .getItem<AveryCustomGenericConfig>(urlTitle)
          .then((data) => {
            if (data) {
              setTimeout(() => {
                fetchData();
              }, 20000);

              return data as AveryCustomGenericConfig;
            }
            return null;
          });
      } catch (error) {
        console.log('local storage is not available');
        console.log(error);
      }

      if (!data) {
        data = await fetchData();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
