/**
 * Function used to reformat a phone number by adding 2 dashes.
 *
 * @export
 * @param {string} phoneNumber
 * @returns {string}
 */
export function addDashesToPhone(phoneNumber: string) {
  let phone: string;
  phone = phoneNumber.replace(/\D/g, '');
  if (phone.length > 3 && phone.length < 8 ) {
    phone = phone.replace(/(\d{3})-*(\d{1,3})/, '$1-$2');
  } else if ( phone.length >= 8 ) {
    phone = phone.replace(/(\d{3})-*(\d{3})-*(\d{1,4})/, '$1-$2-$3');
  }
  return phone;
}
