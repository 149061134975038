import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  HostListener,
} from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { Subscription } from 'rxjs';
import { useBreakpoint } from '../../../utils/index';

@Component({
  selector: 'app-default-banner',
  templateUrl: './default-banner.component.html',
})
export class DefaultBannerComponent implements OnChanges, OnInit {
  @Input() public acgkey: string;
  @Input() public bannerclass: string = '';
  @Input() public background: string;
  @Input() public bgurl: string;
  @Input() public thememode: string;
  @Input() type: string = 'form';
  isScreenSmall: boolean = false;
  subscriptions: Subscription[];
  _class: string = '';

  ngOnInit(): void {
    this.isScreenSmall = useBreakpoint() === 'xs' || false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onWindowResize();
    if (!isEmpty(this.thememode)) {
      if (this.thememode === 'industrial') {
        this._class = this.background ? '' : 'bg-inds-gray-gradient';
        this._class = this._class + this.bannerclass;
      } else {
        this._class = this.bannerclass;
      }
    }
  }

  /**
   * Check for window resize
   *
   * @param {event} evt
   * @memberof DefaultBannerComponent
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isScreenSmall = useBreakpoint() === 'xs' || false;
  }
}
