import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import unescape from 'lodash-es/unescape';

// Service
import { AcgService } from 'navigation/nav-shared/services/acg.service';

@Component({
  selector: 'nav-acg',
  template: ` <div class="d-inline" [innerHTML]="acgContent"></div> `,
})
export class AcgComponent implements OnInit {
  @Input() acgtype: string = 'html';
  @Input() acgurltitle: string;

  acgContent: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private acgService: AcgService
  ) {}

  ngOnInit() {
    this.acgService
      .fetchACG(this.acgurltitle, this.acgtype)
      .then((data) => {
        this.acgContent = this.sanitizer.bypassSecurityTrustHtml(
          unescape(data.body)
        );
      })
      .catch((err) => console.error(err));
  }
}
