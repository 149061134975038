import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountBenefitsComponent } from './account-benefits.component';

@NgModule({
  declarations: [AccountBenefitsComponent],
  imports: [CommonModule],
  exports: [AccountBenefitsComponent],
})
export class AccountBenefitsModule {}
