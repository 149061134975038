import { config } from 'environments/environment';
import isEmpty from 'lodash-es/isEmpty';

/**
 * Function used to return value from local storage.
 * @param key
 * @return {any}
 */
export function getLocalStorage(key: string) {
  let value;

  try {
    value = localStorage.getItem(key);
  } catch (err) {
    console.error(`local storage is not supported; ${err}`);
    return false;
  }

  if (isStringJSON(value)) {
    return JSON.parse(value);
  }

  return value;
}
/**
 * Function validates if string is a JSON object.
 * @param  {string} value - Test string
 * @return {boolean}
 */
export function isStringJSON(value: string) {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}
/**
 * Function used to set the key/value pair to the local storage
 * @param key
 * @param value
 * @return {boolean}
 */
export function setLocalStorage(key: string, value: any) {
  let valueStringified = value;

  if (!isEmpty(value)) {
    if (typeof value === 'object') {
      valueStringified = JSON.stringify(value);
    }
  }

  try {
    localStorage.setItem(key, valueStringified);
    return true;
  } catch (err) {
    console.error(`local storage is not supported; ${err}`);
    return false;
  }
}
/**
 * Function used to delete key/value pair from local storage
 * @param key
 * @return {boolean}
 */
export function localStorageRemoveItem(key: string) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    console.error(`local storage is not supported; ${err}`);
    return false;
  }
}
/**
 * Function used to get a cookie based on the provided cookie name.
 * If the cookie does not exist, returns null.
 * Otherwise, returns the contents of the cookie.
 * @param name
 * @return {string | null}
 */
export function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}
/**
 * Function used to create a cookie.
 * Specify a number for @days for a persistent cookie.
 * The value can either be a string or an object.
 * @param key
 * @param value
 * @param days
 * @param domain
 */
export function createCookie(
  key: string,
  value: any,
  days: number,
  domain: string = ''
) {
  try {
    let expires, setValue, finalDomain;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }
    if (domain !== '') {
      finalDomain = `;domain=${domain}`;
    } else {
      finalDomain = '';
    }

    setValue = typeof value === 'object' ? JSON.stringify(value) : value;
    document.cookie = `${key}=${setValue}${expires}${finalDomain};path=/`;
  } catch (err) {
    console.error('Cookies have been disabled.');
  }
}

/**
 * Function used to delete a cookie.
 * @param {string} key
 * @param {string} domain
 */
export function removeCookie(key: string, domain: string = '') {
  createCookie(key, '', -1, domain);
}
/**
 * Function that handles local storage & cookie data retrieval.
 * Local storage data takes priority over cookie data.
 * @param key
 * @return {any}
 */
export function localStorageGetter(key: string) {
  return getLocalStorage(key) || getCookie(key) || null;
}
/**
 * Function that handles local storage & cookie data creation.
 * Local storage data takes priority over cookie data.
 * @param key
 * @param value
 */
export function localStorageSetter(key: string, value: any) {
  setLocalStorage(key, value) || createCookie(key, value, 99999);
}
/**
 * Function that handles local storage & cookie data deletion.
 * @param key
 */
export function localStorageRemover(key: string) {
  localStorageRemoveItem(key) || removeCookie(key);
}

export function getQueryParamByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Checks if a cookie for a theme exists or if the route/URL path corresponds to a specific theme
 *
 * @export
 * @returns {Promise<string>}
 */
export function checkAppThemeMode(): Promise<string> {
  // TODO: rework the entire existing theme mode system later!
  // these paths do not have industrial url paths, therefore,
  // the only way to persist theme mode is to check for theme mode cookie
  // of the previous path that navigates to them
  const specialPaths = [
    'cart.avery.com', // cart domain
    '/myaccount/', // myaccount paths
    '/checkout/', // cart checkout flow
    '/secure/', // secure paths
    '/srch', // search page
    '/custom-printing/labels/rolls', // browse/create flow that dpo redirects to
    '/articles/', // dotcms articles
  ];
  let themeMode = 'default';

  // if current path is a special path, use cookie instead of rely on url
  for (const path of specialPaths) {
    if (window.location.href.includes(path)) {
      themeMode = getCookie('avythmmde') ? 'industrial' : 'default';
      return Promise.resolve(themeMode);
    }
  }

  // if current path has /industrial/ in url string, set theme mode accordingly
  themeMode = window.location.pathname.includes('/industrial/')
    ? 'industrial'
    : 'default';

  // remove cookie for default theme, add cookie for industrial theme
  if (themeMode === 'default') {
    createCookie(config.theme.cookieKey, '', -1, '.avery.com');
  } else {
    createCookie(config.theme.cookieKey, themeMode, 7, '.avery.com');
  }

  return Promise.resolve(themeMode);
}
