import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appClickOut]',
})
export class ClickOutDirective {
  @Output()
  appClickOut = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);

    if (!target) {
      return;
    }

    if (!clickedInside) {
      this.appClickOut.emit();
    }
  }
}
