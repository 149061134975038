import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestFormComponent } from './guest-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TosNoticeModule } from '../tos-notice/tos-notice.module';

@NgModule({
  declarations: [GuestFormComponent],
  imports: [CommonModule, ReactiveFormsModule, TosNoticeModule],
  exports: [GuestFormComponent],
})
export class GuestFormModule {}
