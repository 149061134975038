import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';

export const SPECIAL_CHAR_REGEX = /[\/<>:?%*|"]/;
export const EMAIL_REGEX = /^\s*(.+)@(.+?)\s*$/;
export const USER_REGEX = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)$/;
export const IP_DOMAIN_REGEX = /^(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])$/;
export const DOMAIN_REGEX = /^(([a-zA-Z0-9][a-zA-Z0-9-]*\.)+[a-zA-Z]{2,})$/;
export const ZIP_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const NAME_REGEX = /^[a-zA-Z0-9\-' ,.]+$/;
export const MAX_USERNAME_LEN = 64;

/**
 * Validates the email address by sections. Sections: {users}@{domain}
 * By separating out the sections of an email, it makes it easier to validate rather than having to validate
 * the entire email as a whole.
 * @param {FormControl} control
 * @return {any}
 */
export function emailValidator(control: FormControl) {
  if (isEmpty(control.value)) {
    return null;
  }

  const emailMatcher = EMAIL_REGEX.exec(control.value);
  if (isEmpty(emailMatcher)) {
    return { invalidEmailAddress: true };
  }

  // here we will validate the user=
  if (!isValidUser(emailMatcher[1])) {
    return { invalidEmailAddress: true };
  }

  // here we validate the domain
  if (!isValidDomain(emailMatcher[2])) {
    return { invalidEmailAddress: true };
  }
}

export function passwordValidator(control: FormControl) {
  const password = control.get('password').value;
  const rePassword = control.get('rePassword').value;

  if (password !== rePassword) {
    control.get('rePassword').setErrors({ MatchPassword: true });
  } else {
    return null;
  }
}

export function zipCodeValidator(control: FormControl) {
  const value = control.value ? control.value.toString() : ''; // Converted to string for REGEX evaluation, just in case it is a number.
  const zipMatch = ZIP_REGEX.exec(value);

  if (isEmpty(value)) {
    return null;
  }

  if (isEmpty(zipMatch)) {
    return { invalidZip: true };
  }
}

/**
 * Validator that checks for invalid emails
 *
 * @export
 * @returns {ValidatorFn}
 */
export function invalidEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Validator.required should handle empty strings
    if (control.value === '') {
      return null;
    }
    const data = EMAIL_REGEX.exec(control.value);
    return data === null || !isValidUser(data[1]) || !isValidDomain(data[2])
      ? { invalidEmail: { value: control.value } }
      : null;
  };
}

/**
 * Validator that checks for positive integer and comma-separated positive integers
 *
 * @export
 * @returns {ValidatorFn}
 */
export function positiveIntegerValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = control.value;

    if (input === null || input === undefined || input === '') {
      return null; // Allow empty input
    }

    const regex = /^(?:\d+(,\s*)?)+$/;
    if (!regex.test(input)) {
      return { invalidInput: true };
    }

    // Check if each part is a positive integer
    const parts = input.split(',');
    for (const part of parts) {
      if (!/^\d+$/.test(part.trim())) {
        return { invalidInput: true };
      }
    }

    return null; // Input is valid
  };
}

/**
 * Returns true if the domain component of an email address is valid.
 * @param {string} domain
 * @return {boolean}
 */
function isValidDomain(domain: string): boolean {
  // see if domain is an IP address in brackets
  if (domain === null) {
    return false;
  }
  return IP_DOMAIN_REGEX.test(domain) || DOMAIN_REGEX.test(domain);
}

/**
 * Returns true if the value passed contain special character/s or if value passed is blank
 * @param {string} value
 * @return {boolean}
 */
export function containsSpecialCharacter(value: string): boolean {
  if (value === null) {
    return true;
  }

  return SPECIAL_CHAR_REGEX.test(value);
}

/**
 * Returns true if the name passed is valid
 * @param {string} name
 * @return {boolean}
 */
export function isValidName(name: string): boolean {
  if (name === null) {
    return false;
  }

  return NAME_REGEX.test(name);
}

/**
 * Returns true if the user component of an email address is valid.
 * @param {string} user
 * @return {boolean}
 */
function isValidUser(user: string): boolean {
  if (user === null || user.length > MAX_USERNAME_LEN) {
    return false;
  }
  return USER_REGEX.test(user);
}

/**
 * Validate the number input by setting the max total.
 * @param {Number} max
 * @return {ValidatorFn}
 */
export function maxValue(max: Number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value;

    if (input > max) {
      return { maxValue: { max } };
    }

    return null;
  };
}

/**
 * Validate the number input by restricting it to positive numbers only
 * @param {FormControl} control
 * @return {[key: string]: any}
 */
export function positiveNumber(control: FormControl): { [key: string]: any } {
  const input = Number(control.value);

  if (input < 0) {
    return { nonPositive: true };
  }

  return null;
}

/**
 * Validator that sets up input so that it can't match the given regular expression
 * @param {RegExp} nameRe
 * @return {ValidatorFn}
 */
export function excludedValue(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
