import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    const foundIndex = this.modals.findIndex((x) => x.id === modal.id);
    if (foundIndex === -1) {
      this.modals.push(modal);
    }
  }

  remove(id: string) {
    // remove modal from array of active modals
    const modal = this.modals.find((x) => x.id === id);
    modal.remove();
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string) {
    // open modal specified by id
    let currentModal, prevModal;
    this.modals.forEach((x, idx) => {
      if (x.id === id) {
        if (idx > 0) {
          prevModal = this.modals[idx - 1];
        }
        return (currentModal = x);
      }
    });

    // hide prev modal to avoid overlapping modals
    if (prevModal) {
      prevModal.element.style.display = 'none';
    }
    currentModal.open();
  }

  close(id: string) {
    // close modal specified by id
    let currentModal, prevModal;
    this.modals.forEach((x, idx) => {
      if (x.id === id) {
        if (idx > 0) {
          prevModal = this.modals[idx - 1];
        }
        return (currentModal = x);
      }
    });

    currentModal.close();

    // brings prev modal back
    if (prevModal) {
      prevModal.element.style.display = 'block';
    }
  }
}
