import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Helpers
import { createCookie, getCookie } from '../../helpers';

// Services
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-cookie-disclaimer',
  templateUrl: './cookie-disclaimer.component.html',
  styleUrls: ['./cookie-disclaimer.component.scss'],
})
export class CookieDisclaimerComponent implements OnInit {
  disclaimerName: string = 'cd';
  isDisplayed: boolean = false;
  subscriptions: Subscription[];
  themeMode: string = 'default';

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.subscriptions = [this.averyThemeModeSubscription()];

    this.showDisclaimer();
  }

  /**
   * Subscription to get the latest themeMode data
   *
   * @returns {Subscription}
   * @memberof CookieDisclaimerComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe(
      (type: string) => (this.themeMode = type)
    );
  }

  /**
   * Sets flag to close Disclaimer
   *
   * @memberof CookieDisclaimerComponent
   */
  closeDisclaimer() {
    this.isDisplayed = false;

    this.optInCookies();
  }

  /**
   * User Opts in Cookie Agreement, expires in a year.
   *
   * @memberof CookieDisclaimerComponent
   */
  optInCookies() {
    createCookie(this.disclaimerName, true, 365, '.avery.com');
  }

  /**
   * Sets flag to show Disclaimer
   *
   * @memberof CookieDisclaimerComponent
   */
  showDisclaimer() {
    const disclaimerCookie = getCookie(this.disclaimerName);

    if (disclaimerCookie === null) {
      setTimeout(() => {
        this.isDisplayed = true;
      }, 3000);
    }
  }
}
