import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from 'environments/environment';

import { NavigationService } from '../../../nav-shared/navigation.service';
import { HeaderService } from '../../header.service';

import { Link, NavLink } from '../../../navigation-links.interface';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { unescape } from 'lodash-es';
import has from 'lodash-es/has';
import isEqual from 'lodash-es/isEqual';
import { AveryCustomGenericInterface } from 'navigation/nav-shared/models/interfaces';

@Component({
  selector: 'nav-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnChanges, OnDestroy, OnInit {
  @Input() isTablet: boolean;
  @Input() acgs: AveryCustomGenericInterface[];
  @Input() kspAcgTitle: string;
  @Input() links: Link[];
  @Input() navLink: NavLink;

  holidayFlagUrl: string = `${environment.domain}/app/images/header-footer/holiday-flag.svg`;
  openMobileNav: boolean = false;
  selectedMenuLink: number = 0;
  subscriptions: Subscription[] = [];

  constructor(
    private elementRef: ElementRef,
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.subscriptions = [this.mobileNavActiveSubscription()];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      has(changes, 'links') &&
      !isEqual(changes.links.currentValue, changes.links.previousValue)
    ) {
      this.links = this.navigationService.sortNavData(
        this.links,
        'header_order'
      );
    }
  }

  getACGContent(urlTitle: string): SafeHtml {
    if (this.acgs && urlTitle !== '') {
      return this.sanitizer.bypassSecurityTrustHtml(
        unescape(this.acgs.find((acg) => acg.urlTitle === urlTitle).body)
      );
    }
  }

  /**
   * Return subscription for Mobile Nav Activation.
   *
   * @returns {Subscription}
   * @memberof MegaMenuComponent
   */
  mobileNavActiveSubscription(): Subscription {
    return this.headerService.mobileNavActive.subscribe((value) => {
      this.openMobileNav = value;
      if (value) {
        this.selectedMenuLink = -1;
      } else {
        this.selectedMenuLink = 0;
      }
    });
  }

  /**
   * Open the submenu (Hover)
   *
   * @param {number} i
   * @memberof MegaMenuComponent
   */
  onMegaMenuLink(i: number) {
    if (!this.isTablet && this.selectedMenuLink !== i) {
      this.selectedMenuLink = i;
    }
  }

  /**
   * Toggle the selected submenu (Click)
   *
   * @param {number} i
   * @param {Link} link
   * @memberof MegaMenuComponent
   */
  toggleMegaMenuLink(
    event: { preventDefault: () => void },
    i: number,
    link: Link
  ) {
    event.preventDefault();

    if (this.selectedMenuLink !== i) {
      this.selectedMenuLink = i;

      if (this.isTablet) {
        this.elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    } else {
      if (this.isTablet) {
        this.selectedMenuLink = -1;
      } else if (link.url.length > 0) {
        this.navigationService.navigateToRoute(link.url);
      }
    }
  }
}
