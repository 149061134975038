import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeSamplePackCompleteModalComponent } from './free-sample-pack-complete-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [FreeSamplePackCompleteModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [FreeSamplePackCompleteModalComponent],
})
export class FreeSamplePackCompleteModalModule {}
