import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInFormComponent } from './sign-in-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TosNoticeModule } from '../tos-notice/tos-notice.module';

@NgModule({
  declarations: [SignInFormComponent],
  imports: [CommonModule, ReactiveFormsModule, TosNoticeModule],
  exports: [SignInFormComponent],
})
export class SignInFormModule {}
