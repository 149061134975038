import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as bowser from 'bowser';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';

import { NavigationService } from 'navigation/nav-shared/navigation.service';

import { environment } from 'environments/environment';
import { isMobile } from 'navigation/nav-shared/responsive-design/responsive-design';
import { pushDataLayer } from 'navigation/nav-shared/helpers/googleAnalytics';

import {
  BlankLabelTypeaheadEntry,
  TemplateTypeaheadEntry,
  TypeaheadMessages,
  TypeaheadType,
} from 'navigation/nav-shared/models/interfaces/typeahead.interface';

@Component({
  selector: 'app-common-typeahead',
  templateUrl: './common-typeahead.component.html',
  styleUrls: ['./common-typeahead.component.scss'],
})
export class CommonTypeaheadComponent implements OnInit, AfterViewInit {
  @Input() blankLabels: BlankLabelTypeaheadEntry[] = [];
  @Input() templates: TemplateTypeaheadEntry[] = [];
  @Input() themeMode: string = 'default';
  @Input() type: TypeaheadType;
  @ViewChild('textFieldInput', { static: false }) textFieldInput;
  customSelected: boolean = false;
  customTemplate: TemplateTypeaheadEntry;
  customTemplateMsg: TypeaheadMessages = null;
  detectBrowser: bowser.IBowser = bowser;
  errorMsg: string = ''; // Empty string means no error
  isCallingApi: boolean = false;
  isInputFocused: boolean = false;
  isCustomTemplateId: boolean = false;
  isTrademarkProduct: boolean = false;
  isKeyBackspace: boolean = false;
  placeholder: string;
  searchInput: any;
  searchLimit: number = 5;
  showCustomTemplateQueue: boolean = false;

  constructor(private navService: NavigationService) {}

  /**
   * Observable that contains the results for the dropdown.
   *
   * @memberof CommonTypeaheadComponent
   */
  search = (text$: Observable<string>) => {
    const productLabel = this.type === 'templates' ? 'template' : 'product';

    return text$.pipe(
      tap(() => (this.errorMsg = '')),
      map((term: string) =>
        term.replace(/\\/, '') === ''
          ? []
          : !this.isCustomTemplateId
          ? this.typeaheadFilter(term)
          : this.typeaheadFilterTemplateId(term)
      ),
      tap(
        (data: BlankLabelTypeaheadEntry[] | TemplateTypeaheadEntry[] | any) => {
          if (
            !this.isCustomTemplateId &&
            !this.isTrademarkProduct &&
            this.searchInput.length === 0
          ) {
            if (productLabel === 'template') {
              this.placeholder = 'Enter product or template number';
            }
          }

          if (!this.isCustomTemplateId) {
            if (!isEmpty(this.searchInput) && isEmpty(data)) {
              this.errorMsg = `No matching ${productLabel} found`;
            }
          } else {
            const resInterval = setInterval(() => {
              if (isEmpty(this.searchInput) || this.searchInput.length === 0) {
                this.isCustomTemplateId = false;
              }

              if (
                !this.isCustomTemplateId &&
                !isEmpty(this.searchInput) &&
                isEmpty(data)
              ) {
                this.errorMsg = `No matching ${productLabel} found`;
              }

              this.errorMsg = '';
              clearInterval(resInterval);
            }, 100);
          }
        }
      )
    );
  };

  ngOnInit() {
    switch (this.type) {
      case 'blankLabels':
        this.customTemplateMsg = {
          error: 'No matching product found. Please verify and try again',
          queue: 'A valid product number requires 6 characters',
        };
        this.placeholder = 'Enter a product number';
        break;
      case 'templates':
        this.customTemplateMsg = {
          error:
            'No matching custom template found. Please verify and try again',
          queue: 'A valid custom size template number requires 6 characters',
        };
        this.placeholder = 'Enter product or template number';
        break;
      default:
        this.customTemplateMsg = {
          error: 'No matches found. Please verify and try again',
          queue: 'A valid number requires 6 characters',
        };
    }
  }

  ngAfterViewInit() {
    if (!isMobile()) {
      if (
        this.detectBrowser.name !== 'Internet Explorer' &&
        this.type === 'templates'
      ) {
        this.textFieldInput.nativeElement.focus();
      }
    }
  }

  /**
   * Logic used for typeahead filtering
   *
   * @param {string} term
   * @returns {(BlankLabelTypeaheadEntry[] | TemplateTypeaheadEntry[])}
   * @memberof CommonTypeaheadComponent
   */
  typeaheadFilter(
    term: string
  ): BlankLabelTypeaheadEntry[] | TemplateTypeaheadEntry[] {
    if (!this.isCustomTemplateId) {
      switch (this.type) {
        case 'blankLabels': {
          return this.blankLabels
            .filter((v: BlankLabelTypeaheadEntry) =>
              new RegExp(term, 'gi').test(v.sku?.slice(0, term.length))
            )
            .slice(0, this.searchLimit);
        }
        case 'templates': {
          const templates = this.isTrademarkProduct
            ? this.templates.filter((template: TemplateTypeaheadEntry) => {
                return (
                  template.templateUri !== null &&
                  template.templateUri.includes('presta')
                );
              })
            : this.templates;

          return templates
            .filter((v: TemplateTypeaheadEntry) =>
              new RegExp(term, 'gi').test(
                v.templateNumber?.slice(0, term.length)
              )
            )
            .slice(0, this.searchLimit);
        }
      }
    }
  }

  /**
   * Logic used for typeahead filtering
   *
   * @param {string} term
   * @returns {(TemplateTypeaheadEntry[])}
   * @memberof CommonTypeaheadComponent
   */
  typeaheadFilterTemplateId(term: string): TemplateTypeaheadEntry[] {
    const templates: TemplateTypeaheadEntry[] = [];
    let params: HttpParams;
    params = new HttpParams().set('search', this.searchInput);
    if (this.searchInput.length === 7) {
      this.isCallingApi = true;
      const callback = (data: any) => {
        this.errorMsg = '';
        if (!isEmpty(data)) {
          data.map((template) => {
            const obj: TemplateTypeaheadEntry = {
              alt: template.templateIdDto.id,
              orientation: template.templateIdDto?.viewOrientation,
              popularity: null,
              size: template.templateIdDto.dpoSizeDescription,
              templateNumber: template.templateIdDto.id,
              templateUri: template.uriPreview,
            };
            templates.push(obj);
            this.customTemplate = templates[0];
          });

          return templates
            .filter((v: TemplateTypeaheadEntry) =>
              new RegExp(term, 'gi').test(
                v.templateNumber?.slice(0, term.length)
              )
            )
            .slice(0, this.searchLimit);
        } else {
          return [];
        }
      };

      this.navService.getCustomTemplateId(params).subscribe(callback);
    } else {
      this.customTemplate = null;
      return [];
    }
  }

  /**
   * Add a dash after the first 3 characters and convert all to uppercase.
   *
   * @param {Event} event
   * @memberof CommonTypeaheadComponent
   */
  dashMidTemplateId(event: Event) {
    let templateId = (event.target as HTMLInputElement).value;
    if (this.isCustomTemplateId && !this.isKeyBackspace) {
      if (templateId.length === 3) {
        this.searchInput = `${templateId.substr(0, 3)}-`;
      } else if (templateId.length > 3) {
        this.searchInput = `${templateId.substr(0, 3)}-${templateId.substr(
          4,
          3
        )}`;
      }

      this.searchInput = this.searchInput.toUpperCase();
      this.searchInput = this.searchInput.replace(/O/g, '0');
      this.searchInput = this.searchInput.replace(/[LI]/g, '1');
    }
  }

  /**
   * Checks the input from a user's keyboard event
   *
   * @param {KeyboardEvent} e
   * @memberof CommonTypeaheadComponent
   */
  checkInput(e: KeyboardEvent) {
    this.isKeyBackspace = false;
    if (
      (e.key === 't' && this.searchInput.length === 0) ||
      (e.key === 'T' && this.searchInput.length === 0)
    ) {
      this.searchInput = 'T';
    } else if (e.key === 'Backspace') {
      this.isKeyBackspace = true;
    }

    switch (this.type) {
      case 'blankLabels': {
        if (
          this.navService.numericKeyboard(e) ||
          this.checkInputForCustomTemplateId(e) ||
          this.navService.numericKeyboard(e)
        ) {
          this.errorMsg = '';
        } else {
          if (!this.isCustomTemplateId) {
            e.preventDefault();
            this.errorMsg = 'Please Enter a Product Number';
          } else {
            if (isEmpty(this.templates)) {
              this.errorMsg =
                'Please enter a valid custom size product number requires 6 characters';
            }
          }
        }
        break;
      }
      case 'templates': {
        if (
          this.checkInputForTrademark(e) ||
          this.checkInputForCustomTemplateId(e) ||
          this.navService.numericKeyboard(e)
        ) {
          this.errorMsg = '';
        } else {
          if (!this.isCustomTemplateId) {
            e.preventDefault();
            this.errorMsg = 'Please Enter a Product or Template Number';
          } else {
            if (isEmpty(this.templates)) {
              this.errorMsg =
                'Please enter a valid custom size template number requires 6 characters';
            }
          }
        }
        break;
      }
    }
  }

  /**
   * Checks the input from a user's keyboard event on Key Up
   *
   * @memberof CommonTypeaheadComponent
   */
  checkInputEntered() {
    // Custom Template Check
    if (this.isCustomTemplateId) {
      this.showCustomTemplateQueue =
        this.textFieldInput.nativeElement.value.length <= 6;
    }
  }

  /**
   * Specific check for presta/trademark
   *
   * @param {KeyboardEvent} e
   * @returns {boolean}
   * @memberof CommonTypeaheadComponent
   */
  checkInputForTrademark(e: KeyboardEvent): boolean {
    // Presta Backspace Check
    if (
      (this.isTrademarkProduct || this.isCustomTemplateId) &&
      (e.key === 'Backspace' || e.keyCode === 8) &&
      this.textFieldInput.nativeElement.value === ''
    ) {
      this.isTrademarkProduct = false;
      this.placeholder = 'Enter product or template number';
    }

    // Presta "P" Trigger - only if there is no value in the input
    if (
      this.textFieldInput.nativeElement.value === '' &&
      (e.key === 'p' || e.key === 'P' || e.keyCode === 80)
    ) {
      // Prevents "p" from being entered in the input field.
      e.preventDefault();
      this.textFieldInput.nativeElement.value = '';
      this.isTrademarkProduct = true;
      this.placeholder = 'Enter template number';

      return true;
    }

    return false;
  }

  /**
   * Specific check for presta/trademark
   *
   * @param {KeyboardEvent} e
   * @returns {boolean}
   * @memberof CommonTypeaheadComponent
   */
  checkInputForCustomTemplateId(e: KeyboardEvent): boolean {
    // Custom Template Backspace Check
    this.isCallingApi = false;

    if (
      (this.isCustomTemplateId &&
        (e.key === 'Backspace' || e.keyCode === 8) &&
        this.textFieldInput.nativeElement.value === '') ||
      (e.key === 'Backspace' && this.searchInput.length === 0)
    ) {
      this.isCustomTemplateId = false;
      this.placeholder = 'Enter product or template number';
    }

    if (
      this.textFieldInput.nativeElement.value === '' &&
      (e.key === 't' || e.key === 'T')
    ) {
      // Prevents "t" from being entered in the input field.
      e.preventDefault();
      this.isCustomTemplateId = true;
      this.placeholder = 'Enter product or template number';

      return true;
    }

    if (
      this.textFieldInput.nativeElement.value === '' &&
      (e.key === 'u' || e.key === 'U' || e.key === '-')
    ) {
      e.preventDefault();
      this.textFieldInput.nativeElement.value = '';
      this.placeholder = 'Enter product or template number';
      return true;
    }

    if (e.key === 'u' || e.key === 'U' || e.key === '-') {
      // Prevents "u" and "-" from being entered in the input field.
      e.preventDefault();
      this.placeholder = 'Enter product or template number';
      return true;
    }

    if (
      this.isCustomTemplateId &&
      this.searchInput.length === 7 &&
      e.key === 'Enter' &&
      !isEmpty(this.customTemplate)
    ) {
      this.selectCustomItem();
    }

    if (e.key === 'Backspace' && this.searchInput.length <= 1) {
      this.isCustomTemplateId = false;
    }

    return false;
  }

  /**
   * Function called when typeahead is focused
   *
   * @param {Event} e
   * @memberof CommonTypeaheadComponent
   */
  onFocus(e: Event) {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    });
    this.isInputFocused = true;
  }

  /**
   * Function called when typeahead is blurred
   *
   * @memberof CommonTypeaheadComponent
   */
  onBlur() {
    this.isInputFocused = false;
    this.errorMsg = '';
    if (
      !isEmpty(this.searchInput) &&
      this.searchInput.length > 0 &&
      (this.searchInput.substr(0, 1) === 't' ||
        this.searchInput.substr(0, 1) === 'T')
    ) {
      this.customSelected = true;
    }
  }

  /**
   * Select an item from the typeahead dropdown
   *
   * @param {NgbTypeaheadSelectItemEvent} { item }
   * @memberof CommonTypeaheadComponent
   */
  selectItem({ item, preventDefault }: NgbTypeaheadSelectItemEvent) {
    preventDefault();
    switch (this.type) {
      case 'blankLabels':
        let blankLabelsURL;

        if (has(item, 'type') && item.type === 'OA') {
          blankLabelsURL =
            this.themeMode === 'industrial'
              ? `/industrial/blank-labels-and-signs/labels/${item.sku}`
              : `/blank/labels/${item.sku}`;
        } else {
          blankLabelsURL =
            this.themeMode === 'industrial'
              ? `/industrial/products/labels/${item.sku}`
              : `/products/labels/${item.sku}`;
        }

        pushDataLayer({
          event: 'x-sell-search',
          productNumber: item.sku,
        });

        window.location.href = blankLabelsURL;
        break;
      case 'templates':
        const templateURL =
          this.themeMode === 'industrial'
            ? `/industrial/templates/${item.templateUri}`
            : `/templates/${item.templateUri}`;

        pushDataLayer({
          event: 'template-search',
          templateUri: item.templateUri,
        });

        window.location.href = templateURL;
        break;
    }
  }

  /**
   * Select the item from the custom template dropdown section
   *
   * @memberof CommonTypeaheadComponent
   */
  selectCustomItem() {
    if (this.type === 'templates') {
      const templateId = `presta-${this.customTemplate.templateNumber.toUpperCase()}`;
      this.customSelected = true;

      const templateURL =
        this.themeMode === 'industrial'
          ? `/industrial/templates/custom/${templateId}`
          : `/templates/custom/${templateId}`;

      pushDataLayer({
        event: 'template-search',
        templateUri: this.customTemplate.templateNumber.toUpperCase(),
      });

      this.errorMsg = '';
      this.searchInput = '';
      window.location.href = templateURL;
    } else if (this.type === 'blankLabels') {
      const templateId = this.customTemplate.templateNumber.toUpperCase();
      this.customSelected = true;
      const templateURL =
        this.themeMode === 'industrial'
          ? `/industrial/blank-labels-and-signs/labels/custom/${templateId}`
          : `/blank/labels/custom/${templateId}`;

      pushDataLayer({
        event: 'x-sell-search',
        productNumber: this.customTemplate.templateNumber.toUpperCase(),
      });

      this.errorMsg = '';
      this.searchInput = '';
      window.location.href = templateURL;
    }
  }

  /**
   * Displays text for each typeahead result
   *
   * @param {string} str
   * @returns {SafeHtml}
   * @memberof CommonTypeaheadComponent
   */
  generateDisplayText(str: string): SafeHtml {
    // Formats Label for Custom Templates
    if (this.isCustomTemplateId && !str.includes('-')) {
      return `<span>${str.substr(0, 3)}-${str.substr(3)}</span>`;
    }

    switch (this.type) {
      case 'blankLabels': {
        return `<span>${str}</span>`;
      }
      case 'templates': {
        if (str.includes('presta')) {
          return `
            <span class="d-block h6">Presta &reg;</span>
            <span>${str.replace(/presta-/, '')}</span>
          `;
        }

        return `<span>${str}</span>`;
      }
    }
  }

  /**
   * Displays image for each typeahead result
   *
   * @param {(number | string)} value
   * @returns {string}
   * @memberof CommonTypeaheadComponent
   */
  generateImage(value: number | string): string {
    if (!this.isCustomTemplateId) {
      switch (this.type) {
        case 'blankLabels':
          return `${environment.image}/f_auto,q_auto,c_scale,w_80` + value;
        case 'templates':
          return `${environment.image}/f_auto,q_auto,c_scale,w_80/web/templates/line-art/${value}`;
      }
    } else {
      if (
        !isEmpty(this.customTemplate) &&
        !isEmpty(this.customTemplate.templateUri)
      ) {
        return this.customTemplate.templateUri;
      }
      return '';
    }
  }

  /**
   * Generates img alt value for each typeahead result
   *
   * @param {string} value
   * @returns {string}
   * @memberof CommonTypeaheadComponent
   */
  generateAlt(value: string): string {
    switch (this.type) {
      case 'blankLabels':
        return value;
      case 'templates':
        return `Template ${value}`;
    }
  }
}
