import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import unescape from 'lodash-es/unescape';
import { ModalSize } from 'navigation/nav-shared/models/types';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-static-modal',
  templateUrl: './static-modal.component.html',
  styleUrls: ['./static-modal.component.scss'],
})
export class StaticModalComponent implements OnInit, AfterViewInit {
  @Input() id: string = 'static-modal';
  @Input() size: ModalSize = 'md';

  @Input() staticModalTitle: string;
  @Input() staticModalHTMLContent: string;
  sanitizedHTML: SafeHtml;

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.sanitizedHTML = this.sanitizer.bypassSecurityTrustHtml(
      unescape(this.staticModalHTMLContent)
    );
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }
  /**
   * Closes Generic Modal
   *
   * @memberof StaticModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }
}
