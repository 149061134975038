import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGenericModalComponent } from './custom-generic-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [CustomGenericModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [CustomGenericModalComponent],
})
export class CustomGenericModalModule {}
