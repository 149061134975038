import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import isEmpty from 'lodash-es/isEmpty';

import { NavigationService } from '../../navigation.service';
import {
  CustomCard,
  CustomCardProduct,
  ModalOptionSet,
} from './custom-cards-modal.interface';
import { ModalService } from '../modal/modal.service';
import { ModalSize } from 'navigation/nav-shared/models/types';

@Component({
  selector: 'app-custom-cards',
  templateUrl: './custom-cards-modal.component.html',
  styleUrls: ['./custom-cards-modal.component.scss'],
})
export class CustomCardsModalComponent implements OnInit, AfterViewInit {
  // these are used for modal component
  @Input() id: string;
  @Input() size: ModalSize;
  // these are used for custom cards
  @Input() cardType: string;
  @Input() categoryName: string;

  currentPath: string;
  customCard: CustomCard;
  customCardProducts: CustomCardProduct[] = [];
  finalSku: string;
  flowStep: number = 0;
  maxSteps: number = 0;
  modalOptionSets: ModalOptionSet[] = [];
  nativeWindow: Window;
  selectedOptions: string[] = [];
  showArrowNav: boolean = true;

  constructor(
    private navigationService: NavigationService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.getCustomCardData(this.cardType);
    this.nativeWindow = this.navigationService.getNativeWindow();
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  /**
   * Get custom card data
   *
   * @param {string} cardType
   * @memberof ModalCustomCardsModalComponent
   */
  getCustomCardData(cardType: any) {
    this.navigationService
      .fetchCustomCardData(cardType)
      .subscribe((data: CustomCard) => {
        this.customCard = data;
        this.modalOptionSets = data.modal;
        this.customCardProducts = data.products;
        this.maxSteps = this.modalOptionSets.length;
        if (!isEmpty(this.customCardProducts)) {
          this.finalSku = this.customCardProducts[0].sku;
        }
      });
  }

  // control closing modal
  closeModal() {
    this.modalService.close(this.id);
  }

  /**
   * Build Included Option image URL
   *
   * @param {string} includeImg
   * @returns {string}
   * @memberof ModalCustomCardsModalComponent
   */
  iconSrc(includeImg: string): string {
    return `${environment.image}${includeImg}`;
  }

  /**
   * Increment the step to the next step
   * add selected option string to the selectedOptions array
   *
   * @param {string} optionName
   * @memberof ModalCustomCardsModalComponent
   */
  nextStep(optionName: string) {
    this.selectedOptions.push(optionName);
    this.flowStep++;

    if (this.flowStep === this.maxSteps) {
      this.finalSku = this.getSku();
    }
  }

  /**
   * Find the SKU by compairing the selected options to the available options
   *
   * @returns {string}
   * @memberof ModalCustomCardsModalComponent
   */
  getSku(): string {
    let customCardSku: any;

    if (this.selectedOptions.length === 1) {
      customCardSku = this.customCardProducts.find(
        (product: CustomCardProduct) =>
          product.options1 === this.selectedOptions[0]
      );
    } else if (this.selectedOptions.length === 2) {
      customCardSku = this.customCardProducts.find(
        (product: CustomCardProduct) =>
          product.options1 === this.selectedOptions[0] &&
          product.options2 === this.selectedOptions[1]
      );
    } else if (this.selectedOptions.length === 3) {
      customCardSku = this.customCardProducts.find(
        (product: CustomCardProduct) =>
          product.options1 === this.selectedOptions[0] &&
          product.options2 === this.selectedOptions[1] &&
          product.options3 === this.selectedOptions[2]
      );
    } else if (this.selectedOptions.length === 4) {
      customCardSku = this.customCardProducts.find(
        (product: CustomCardProduct) =>
          product.options1 === this.selectedOptions[0] &&
          product.options2 === this.selectedOptions[1] &&
          product.options3 === this.selectedOptions[2] &&
          product.options4 === this.selectedOptions[3]
      );
    }

    if (!isEmpty(customCardSku)) {
      return customCardSku.sku;
    }

    return '';
  }

  /**
   * Build DPO url and navigate
   *
   * @param {string} type
   * @returns {string}
   * @memberof ModalCustomCardsModalComponent
   */
  finalStep(type: string): string {
    let params: HttpParams = new HttpParams()
      .set('consumer', 'Avery')
      .append('deploymentId', environment.dpoDeploymentID)
      .append('sku', this.finalSku)
      .append('backUrl', this.nativeWindow.location.href);

    switch (type) {
      case 'designs':
        if (this.categoryName) {
          params = params.append('designCategoryName', this.categoryName);
        }
        return `${environment.dpo3}/dpo/entry/design/?${params.toString()}`;
      case 'canvas':
        params = params.append('designThemeName', 'Blank');
        return `${environment.dpo3}/dpo/entry/canvas/?${params.toString()}`;
      case 'upload':
        params = params.append('source', 'UploadDesign');
        return `${environment.dpo3}/dpo/entry/design/?${params.toString()}`;
    }
  }

  /**
   * Return to a previous step if not on step1
   * Remove # of prev selected option(s) from selectedOptions array
   *
   * @param {number} step
   * @memberof ModalCustomCardsModalComponent
   */
  stepBack(step: number) {
    const stepCount = this.flowStep - step;
    if (this.flowStep !== 0) {
      this.flowStep = step;
      this.selectedOptions.splice(step, stepCount);
    }
  }
}
