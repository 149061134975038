import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProxySignInFormComponent } from './proxy-sign-in-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TosNoticeModule } from '../tos-notice/tos-notice.module';

@NgModule({
  declarations: [ProxySignInFormComponent],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, TosNoticeModule],
  exports: [ProxySignInFormComponent],
})
export class ProxySignInFormModule {}
