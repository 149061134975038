import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../nav-shared/authentication.service';
import { NavigationService } from '../../nav-shared/navigation.service';
import { emailValidator, emitNewsletterSignUp } from '../../nav-shared/helpers';

@Component({
  selector: 'nav-footer-subscribe-form',
  templateUrl: './footer-subscribe-form.component.html',
  styleUrls: ['./footer-subscribe-form.component.scss'],
})
export class FooterSubscribeFormComponent implements OnInit {
  @ViewChild('emailAddress', { static: false }) email;
  subscribeForm: FormGroup;
  emailInvalid = false;
  focused = false;
  // is used to show or hide modal
  showModal: boolean = false;
  // subscription status
  status: 'success' | 'exists' | 'error' = 'success';

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.subscribeForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
  }

  formFocus(value) {
    this.focused = value;
    this.emailInvalid = false;
  }

  /**
   * Handles signing up the user for the newsletter.
   * @param subscribeData
   * @memberOf FooterSubscribeFormComponent
   */
  onSubmit(subscribeData: any) {
    if (this.email.nativeElement.validity.valid) {
      this.emailInvalid = false;
      this.authService
        .emailSignUp({
          email: subscribeData.email,
          optIn: true,
          messageTypes: ['promotions'],
        })
        .subscribe(
          () => {
            // emit GA event for newsletter sign-up
            emitNewsletterSignUp();

            this.showModal = true;
            this.navService.DYFooterNewsletterSignup();
          },
          (err) => {
            if (err.error === 'Email already exists.') {
              this.status = 'exists';
            } else {
              this.status = 'error';
            }
            this.showModal = true;
          }
        );
    } else {
      this.emailInvalid = true;
    }
  }

  // Handle on the modal close
  handleCloseModal(evt: boolean) {
    if (evt) {
      this.showModal = false;
    }
  }
}
