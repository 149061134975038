import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { config } from '../../../environments/environment';
import { createCookie } from '../../nav-shared/helpers';
import { NavigationService } from '../../nav-shared/navigation.service';

import has from 'lodash-es/has';

@Component({
  selector: 'nav-header-theme-tabs',
  templateUrl: './header-theme-tabs.component.html',
  styleUrls: ['./header-theme-tabs.component.scss'],
})
export class HeaderThemeTabsComponent implements OnInit, OnDestroy {
  currentThemeMode: string = null;
  loading: boolean = true;
  subscriptions: Subscription[] = [];
  themeModesConfig = config.theme.modes;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.subscriptions = [this.averyThemeModeSubscription()];
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  /**
   * Return subscription for the theme mode
   *
   * @returns {Subscription}
   * @memberof HeaderThemeTabsComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe((type: string) => {
      this.currentThemeMode = type;
      this.loading = false;
    });
  }

  /**
   * Change the current theme mode
   *
   * @memberof HeaderThemeTabsComponent
   */
  switchTheme(event: { preventDefault: () => void }, mode: string) {
    event.preventDefault();
    const cookieKey = config.theme.cookieKey;
    const themeModes = config.theme.modes;

    if (themeModes.hasOwnProperty(mode)) {
      const modeLandingPage = has(themeModes[mode], 'landingPage')
        ? themeModes[mode]['landingPage']
        : '/';
      // Save/Update theme mode cookie for 7 days
      createCookie(cookieKey, mode, 7, '.avery.com');

      window.location.href = modeLandingPage;
    }
  }

  setTitle(mode) {
    let titleString = mode.value.toggleLabel + ' mode';

    if (this.currentThemeMode !== mode.key) {
      titleString = 'activate ' + mode.value.toggleLabel + ' mode';
    }

    return titleString;
  }
}
