import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { NavigationService } from '../../../navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usr-account-benefits',
  templateUrl: './account-benefits.component.html',
  styles: [
    `
      .benefit-icon {
        width: 51px;
        height: 39px;
      }
      p {
        line-height: 18px;
      }
      .pay-icon {
        width: 35px;
        height: 25px;
      }
    `,
  ],
})
export class AccountBenefitsComponent implements OnInit, OnDestroy {
  @Input() payFlow: boolean = false;

  iconPath: string = `${environment.domain}/app/images/icons/ksp`;
  payIconPath: string = `${environment.domain}/app/images/icons/forms`;
  privacyLink: string = `${environment.domain}/about-us/legal/privacy-policy`;
  subscription: Subscription;
  themeMode: string = 'default';

  constructor(private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.subscription = this.navigationService.themeMode.subscribe(
      (themeMode: string) => (this.themeMode = themeMode)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
