import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterMultisiteDropdownComponent } from './footer-multisite-dropdown.component';
import { ClickOutDirectiveModule } from './../../nav-shared/directive/click-out/click-out.directive.module';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';

@NgModule({
  declarations: [FooterMultisiteDropdownComponent],
  imports: [CommonModule, ClickOutDirectiveModule],
  exports: [FooterMultisiteDropdownComponent],
  providers: [NavDataService],
})
export class FooterMultisiteDropdownModule {}
