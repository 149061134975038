import { Component, Input, OnInit } from '@angular/core';

import {
  BlankLabelTypeaheadEntry,
  TemplateTypeaheadEntry,
  TypeaheadType,
} from 'navigation/nav-shared/models/interfaces/typeahead.interface';

@Component({
  selector: 'app-cross-sell-search-bar',
  templateUrl: './cross-sell-search-bar.component.html',
  styleUrls: ['./cross-sell-search-bar.component.scss'],
})
export class CrossSellSearchBarComponent implements OnInit {
  @Input() crossSellImage: string = '';
  @Input() crossSellHeading: string = '';
  @Input() crossSellSeeAllProperties: any = {};
  @Input() themeMode: string;
  @Input() typeaheadBlankLabels: BlankLabelTypeaheadEntry[] = [];
  @Input() typeaheadTemplates: TemplateTypeaheadEntry[] = [];
  @Input() typeaheadType: TypeaheadType;
  seeAllLabel: string;

  constructor() {}

  ngOnInit() {
    this.seeAllLabel =
      this.typeaheadType === 'blankLabels' ? 'See in-store labels' : 'See all';
  }

  /**
   * Create link so that user can navigate to a CPG page
   *
   * @returns {string}
   * @memberof CrossSellSearchBarComponent
   */
  linkToCPG(): string {
    // TODO: x-sell doesn't exist on blank labels anymore (I think)
    if (this.typeaheadType === 'blankLabels') {
      if (this.crossSellSeeAllProperties.type === 'shape') {
        switch (this.crossSellSeeAllProperties.shape) {
          case 'rectangle-labels':
          case 'round-labels':
          case 'oval-labels':
          case 'square-labels':
            return `/products/labels/shape/${this.crossSellSeeAllProperties.shape}`;
        }

        return '/products/labels/shape/other-shape-labels';
      }

      return '/products/labels/usage/all-labels';
    } else if (this.typeaheadType === 'templates') {
      return '/templates/category/all-templates';
    }
  }
}
