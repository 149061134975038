import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { createCookie, getCookie } from './navigation/nav-shared/helpers';

// Duplicate DY cookie as a workaround for Safari's Internet Tracking Prevention (ITP) Policy
// https://support.dynamicyield.com/hc/en-us/articles/360007211797-Safari-s-Internet-Tracking-Prevention-ITP-Policy-
const _dyIdCookie = getCookie('_dyid');
if (_dyIdCookie && !getCookie('_dyid_server')) {
  createCookie('_dyid_server', _dyIdCookie, 365, '.avery.com');
}

if (environment.production || environment.environment === 'stage') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
