type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const useBreakpoint = (): Breakpoint => {
  return window
    .getComputedStyle(document.querySelector('.header'), ':before')
    .getPropertyValue('content')
    .replace(/[\"|\']/g, '') as Breakpoint;
};

const isMobileDevice = (breakpoint: Breakpoint): boolean => {
  return breakpoint == 'xs' || breakpoint == 'sm' || breakpoint == 'md'
    ? true
    : false;
};

export { useBreakpoint, isMobileDevice, Breakpoint };
