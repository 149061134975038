import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterSubscribeFormComponent } from './footer-subscribe-form.component';
import { ReactiveFormsModule } from '@angular/forms';

import { FooterSubscribeModalModule } from './footer-subscribe-modal/footer-subscribe-modal.module';
import { FooterSubscribeModalComponent } from './footer-subscribe-modal/footer-subscribe-modal.component';

@NgModule({
  declarations: [FooterSubscribeFormComponent],
  imports: [CommonModule, FooterSubscribeModalModule, ReactiveFormsModule],
  exports: [FooterSubscribeFormComponent],
})
export class FooterSubscribeFormModule {}
