import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Column, Link, NavLink } from '../../../navigation-links.interface';

import { NavigationService } from '../../../nav-shared/navigation.service';
import { HeaderService } from '../../header.service';

import { environment } from 'environments/environment';

import has from 'lodash-es/has';
import { AveryCustomGenericInterface } from 'navigation/nav-shared/models/interfaces';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { unescape } from 'lodash-es';

interface RouteInfo {
  pathname: string;
  queryParams?: Record<string, string>;
}
@Component({
  selector: 'nav-column-menu',
  templateUrl: './column-menu.component.html',
  styleUrls: ['./column-menu.component.scss'],
})
export class ColumnMenuComponent implements OnChanges {
  @Input() kspAcgTitle: string;
  @Input() acgs: AveryCustomGenericInterface[];
  @Input() link: Link;
  @Input() navLink: NavLink;

  columns: Column[];
  holidayFlagUrl: string = `${environment.domain}/app/images/icons/navigation/holiday-flag.svg`;
  newIconUrl: string = `${environment.domain}/app/images/icons/navigation/new.svg`;

  constructor(
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      has(changes, 'link') &&
      changes.link.currentValue !== changes.link.previousValue
    ) {
      this.columns = this.navigationService.sortNavData(
        this.link.columns,
        'header_order'
      );
    }
  }

  getACGContent(urlTitle: string): SafeHtml {
    if (this.acgs && urlTitle !== '') {
      return this.sanitizer.bypassSecurityTrustHtml(
        unescape(this.acgs.find((acg) => acg.urlTitle === urlTitle).body)
      );
    }
  }

  navigateToPath(event: any, link: string) {
    // get current location path
    const crtPathname = window.location.pathname;

    if (this.navigationService.routeIsInAngularApp(link)) {
      // destination is angular route
      if (this.navigationService.routeIsInAngularApp(crtPathname)) {
        // navigate to Angular route from angular route
        event.preventDefault();

        const { pathname, queryParams } = this.getRouteInfo(link);

        if (link.length > 0) {
          this.headerService.shadowOverlay.next(false);
          this.headerService.navMenuActive.next(false);

          queryParams
            ? this.navigationService.navigateToRoute(pathname, queryParams)
            : this.navigationService.navigateToRoute(pathname);
        }
      } else {
        // navigate to Angular from static route
        window.location.href = environment.appDomain + link;
      }
    } else if (link.includes('/checkout/cart')) {
      window.location.href = environment.magento + link;
    } else {
      window.location.href = environment.domain + link;
    }
  }

  /**
   * Generates href path
   *
   * @param {NavLink} route
   * @returns {string}
   * @memberof ColumnMenuComponent
   */
  generatePath(route: string): string {
    return this.navigationService.generateLink(route);
  }

  getRouteInfo(link: string): RouteInfo {
    // extract pathname and query string from current link
    const [pathname, queryString] = link.split('?');

    if (queryString) {
      // if link has query string
      const queryParams = {};

      // extract each query param in the query string
      queryString.split(/\&/g).forEach((param) => {
        // construct query params object for angular routing
        const [paramKey, paramValue] = param.split('=');
        queryParams[paramKey] = paramValue;
      });
      return { pathname, queryParams: queryParams };
    }

    return { pathname };
  }
}
