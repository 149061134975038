import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';

// Observables and rxjs
import { Subscription } from 'rxjs';

// Services
import { HeaderService } from '../../../header/header.service';
import { NavigationService } from '../../../../navigation/nav-shared/navigation.service';

// Environments
import { config } from '../../../../environments/environment';

// Helpers
import {
  createCookie,
  emailValidator,
  isValidName,
  zipCodeValidator,
} from '../../helpers';

// Interfaces
import { DropdownForm } from '../../../form-elements.interface';

@Component({
  selector: 'app-ghs-sample-pack',
  templateUrl: './ghs-sample-pack.component.html',
})
export class GhsSamplePackComponent implements OnInit, OnDestroy {
  @Input() acgconfirmationtext: string;
  @Input() acgimagegallery: string;
  @Input() acglimitone: string;
  @Input() acgoverview: string;
  @Input() acgsubtitle: string;
  @Input() btntext: string = 'Get Free Sample';
  @Input() completemessagetype: string;
  @Input() hideoverview: string;
  @Input() messagetypes: string[] = ['industrial'];
  @Input() optintext;
  @Input() samplepackid: string;
  @Input() sendemail2consumer: string;
  @Input() skipmagentoflow: string;
  @Input() skipsamplerequestdynamoinsert: string;
  @Input() skipsugarcrm: string;
  @Input() sourceid: string;
  @Input() sourceidarray: string;
  @Input() sourceidinfo1: string;
  @Input() sourceidinfo2: string;
  @Input() title: string;

  acgConfirmationText: SafeHtml;
  acgImageGallery: SafeHtml;
  acgLimitOne: SafeHtml;
  acgOverview: SafeHtml;
  arySourceId: Array<any> = [];
  acgSubtitle: SafeHtml;
  creatingAccount: boolean = false;
  dropdownAllData: any;
  dropdownIndustry: DropdownForm[];
  dropdownReferral: DropdownForm[];
  dropdownUSA: DropdownForm[];
  errorFlag: boolean = false;
  initflag: boolean = true;
  isSourceIdSelected: boolean = true;
  fspForm: FormGroup;
  samplePackType: string = 'industrial';
  showLimitFirstName: boolean;
  showLimitLastName: boolean;
  sourceIdSet: Set<string> = new Set();
  sourceIdStr: string = '';
  subscriptions: Array<Subscription> = [];
  subType: string;
  subTypeSet: Set<string> = new Set();
  subTypeStr: string = '';
  successFlag: boolean = false;
  validFirstName: boolean = true;
  validLastName: boolean = true;

  // Message Flags
  isInvalidFormMessage: boolean = false;
  isVisible: boolean = true;
  isZipFormatMessage: boolean = false;
  samplePackBtnSubmitted: boolean = false;
  samplePackLoader: boolean = false;

  // Required Field Form Flags
  isInvalidCheckAddress1: boolean = false;
  isInvalidCheckCity: boolean = false;
  isInvalidCheckEmail: boolean = false;
  isInvalidCheckLastName: boolean = false;
  isInvalidCheckName: boolean = false;
  isInvalidCheckState: boolean = false;
  isInvalidCheckZip: boolean = false;
  recaptchaToken: any;

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    const cookieKey = config.theme.cookieKey;
    createCookie(cookieKey, this.samplePackType, 7, '.avery.com');

    this.fetchAcgData();
    this.getSamplePackFormData();
    this.loadSourceIds();
    this.populateSubType();
    this.subscriptions = this.createFreeSamplePackForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the form controls and returns an array of subscription for all
   * value changes on some of the form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof GhsSamplePackComponent
   */
  createFreeSamplePackForm(): Array<Subscription> {
    this.fspForm = this.fb.group({
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      companyName: [''],
      address1: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      address2: [''],
      cityName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(150),
        ]),
      ],
      stateName: ['State', Validators.compose([Validators.required])],
      zipCode: [
        '',
        Validators.compose([Validators.required, zipCodeValidator]),
      ],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      optIn: [false],
      recaptchaToken: [null, Validators.required],
    });

    return [
      this.firstName.valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitFirstName = true;
        } else {
          this.showLimitFirstName = false;
        }
        this.validFirstName = isValidName(data);
      }),
      this.lastName.valueChanges.subscribe((data) => {
        if (data.length > 50) {
          this.showLimitLastName = true;
        } else {
          this.showLimitLastName = false;
        }
        this.validLastName = isValidName(data);
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf GhsSamplePackComponent
   */
  get newEmail() {
    return this.fspForm.get('email');
  }
  get firstName() {
    return this.fspForm.get('fName');
  }
  get lastName() {
    return this.fspForm.get('lName');
  }
  get companyName() {
    return this.fspForm.get('companyName');
  }
  get addressOne() {
    return this.fspForm.get('address1');
  }
  get addressTwo() {
    return this.fspForm.get('address2');
  }
  get city() {
    return this.fspForm.get('cityName');
  }
  get state() {
    return this.fspForm.get('stateName');
  }
  get zip() {
    return this.fspForm.get('zipCode');
  }

  /**
   * Populate subtype using source id input
   *
   * @memberof GhsSamplePackComponent
   */
  populateSubType() {
    switch (this.sourceid) {
      case '22047':
        this.subType = 'ghs';
        break;
      case '22035':
        this.subType = 'surface_safe';
        break;
      case '22088':
        this.subType = 'asset_tags';
        break;
      case '22089':
        this.subType = 'surface_safe_id_labels';
        break;
      case '22090':
        this.subType = 'self_laminating';
        break;
      case '22106':
        this.subType = 'marker_kit';
        break;
      case '22108':
        this.subType = 'hard_hat_stickers_kit';
        break;
      case '22103':
        this.subType = 'safety_sign_kit';
        break;
      case '22104':
        this.subType = 'tag_kit';
        break;
    }
  }

  /**
   * Fetch static content from ACG dotCMS contents.
   *
   * @memberof GhsSamplePackComponent
   */
  fetchAcgData() {
    if (!isEmpty(this.acgoverview)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgoverview, 'form')
        .subscribe(
          (data) =>
            (this.acgOverview = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgsubtitle)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgsubtitle, 'form')
        .subscribe(
          (data) =>
            (this.acgSubtitle = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acglimitone)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acglimitone, 'form')
        .subscribe(
          (data) =>
            (this.acgLimitOne = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgconfirmationtext)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgconfirmationtext, 'form')
        .subscribe(
          (data) =>
            (this.acgConfirmationText = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgimagegallery)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgimagegallery, 'form')
        .subscribe(
          (data) =>
            (this.acgImageGallery = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
  }

  /**
   * Checks whether to display Invalid Sample Pack form message.
   *
   * @memberof GhsSamplePackComponent
   */
  checkFormValidationMessage() {
    if (this.fspForm.valid) {
      this.isInvalidFormMessage = false;
    } else {
      this.isInvalidFormMessage = true;
    }
  }

  /**
   * Checks if the Zip Code is in the correct US foramt.
   *
   * @memberof GhsSamplePackComponent
   */
  checkZipFormat() {
    if (has(this.fspForm.get('zipCode').errors, 'invalidZip')) {
      this.isZipFormatMessage = this.fspForm.get('zipCode').errors.invalidZip;
    } else {
      this.isZipFormatMessage = false;
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   * If no parameter is supplied, it will check all Sample Pack required form fields.
   *
   * @param {string} formControlName [Optional]
   * @memberof GhsSamplePackComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'firstName':
        this.isInvalidCheckName = this.firstName.invalid;
        break;
      case 'lastName':
        this.isInvalidCheckLastName = this.lastName.invalid;
        break;
      case 'address1':
        this.isInvalidCheckAddress1 = this.addressOne.invalid;
        break;
      case 'cityName':
        this.isInvalidCheckCity = this.city.invalid;
        break;
      case 'stateName':
        this.isInvalidCheckState = !this.state.touched && this.state.invalid;
        break;
      case 'zipCode':
        this.isInvalidCheckZip = this.zip.invalid;
        break;
      case 'email':
        this.isInvalidCheckEmail = this.newEmail.invalid;
        break;
      default:
        // Check All of the the Required Fields:
        this.isInvalidCheckName = this.firstName.invalid;
        this.isInvalidCheckLastName = this.lastName.invalid;
        this.isInvalidCheckAddress1 = this.addressOne.invalid;
        this.isInvalidCheckCity = this.city.invalid;
        this.isInvalidCheckState = !this.state.touched;
        this.isInvalidCheckZip = this.zip.invalid;
        this.isInvalidCheckEmail = this.newEmail.invalid;
    }
  }

  /**
   * Sample pack submit form function using topic/queue based flow.
   *
   * @memberof GhsSamplePackComponent
   */
  fspSubmitToTopic() {
    this.checkSelectedSourceIds();
    this.checkFormValidationMessage();

    if (this.fspForm.valid && this.validFirstName && this.validLastName) {
      this.creatingAccount = true;
      if (!isEmpty(this.fspForm)) {
        this.samplePackBtnSubmitted = true;
        this.samplePackLoader = true;

        // Calls endpoint to publish this request to an SNS topic
        this.headerService
          .addSampleToTopic(this.setEmailServiceFormat(this.fspForm))
          .subscribe(
            (resp) => {
              if (resp.status === 'error') {
                this.errorFlag = true;
              } else {
                this.successFlag = true;
              }

              this.initflag = false;
              this.isVisible = false;
              setTimeout(() => (this.creatingAccount = false), 500);
            },
            (error) => {
              this.initflag = false;
              this.errorFlag = true;
              this.isVisible = false;
              setTimeout(() => (this.creatingAccount = false), 500);
            }
          ); // close bracket this.headerService.addSampleToTopic
      }
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * This function will structure the form data before publishing it to AWS topic.
   *
   * @param {FormGroup} form
   * @returns {any}
   * @memberof GhsSamplePackComponent
   */
  setEmailServiceFormat(form: FormGroup): any {
    const samplePackData = {
      email: this.newEmail.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      optIn: this.fspForm.controls['optIn'].value,
      formType: 'Industrial SampleRequest',
      sourceId: this.sourceid,
      samplePackId: this.samplepackid,
      messageTypes: this.messagetypes,
      country: 'US',
      spName: this.samplePackType,
      type: this.samplePackType,
      subtype: this.subType,
      sendEmail2Consumer: this.sendemail2consumer,
      skipSugarcrm: this.skipsugarcrm,
      skipSampleRequestDynamoInsert: this.skipsamplerequestdynamoinsert,
      skipMagentoFlow: this.skipmagentoflow,
      recaptchaToken: form.value.recaptchaToken,
    };
    return {
      ...samplePackData,
      ...this.navigationService.setSamplePackOtherFields(this.fspForm, false),
    };
  }

  /**
   * Get sample pack data for form dropdowns (state, industry, referral)
   *
   * @memberof GhsSamplePackComponent
   */
  async getSamplePackFormData() {
    try {
      this.dropdownAllData = await this.navigationService.getSamplePackData();
    } catch (e) {
      console.error(e);
    } finally {
      this.navigationService.fetchSamplePackData().subscribe((data) => {
        this.navigationService.setSamplePackData(data);
        this.dropdownAllData = data;
        this.dropdownIndustry = this.dropdownAllData.industryForm;
        this.dropdownReferral = this.dropdownAllData.referralSourcesForm;
        this.dropdownUSA = this.dropdownAllData.usStatesForm;
      });
    }
  }

  /**
   * Load values from dotCMS ACG values for the array of sourceIds, description and types.
   *
   * @memberof GhsSamplePackComponent
   */
  loadSourceIds() {
    if (this.sourceidarray !== undefined) {
      setTimeout(() => {
        const arySamplePackTitle = [];
        const arySamplePackSubType = [];
        const arySamplePackSourceId = this.sourceidarray
          .split(',')
          .filter((sourceId) => {
            switch (sourceId) {
              case '22047':
                arySamplePackTitle.push('UltraDuty® GHS Labels');
                arySamplePackSubType.push('ghs');
                return true;
              case '22035':
                arySamplePackTitle.push('Surface Safe® Sign Labels');
                arySamplePackSubType.push('surface_safe');
                return true;
              case '22088':
                arySamplePackTitle.push('PermaTrack® Asset Tags');
                arySamplePackSubType.push('asset_tags');
                return true;
              case '22089':
                arySamplePackTitle.push('Surface Safe® ID Labels');
                arySamplePackSubType.push('surface_safe_id_labels');
                return true;
              case '22090':
                arySamplePackTitle.push('Self Laminating Labels');
                arySamplePackSubType.push('self_laminating');
                return true;
              case '22104':
                arySamplePackTitle.push('Safety & LOTO Tags');
                arySamplePackSubType.push('tag_kit');
                return true;

              case '22108':
                arySamplePackTitle.push('Hard Hat Stickers');
                arySamplePackSubType.push('hard_hat_stickers_kit');
                return true;
              case '22106':
                arySamplePackTitle.push('UltraDuty™ Markers');
                arySamplePackSubType.push('marker_kit');
                return true;

              default:
                return false;
            }
          });

        if (
          !isEmpty(this.sourceidarray) &&
          arySamplePackSourceId.length === arySamplePackTitle.length &&
          arySamplePackSourceId.length === arySamplePackSubType.length
        ) {
          arySamplePackSourceId.map((obj, index) => {
            const elem = {
              id: arySamplePackSourceId[index],
              desc: arySamplePackTitle[index],
              selected: false,
              subtype: arySamplePackSubType[index],
            };
            this.arySourceId.push(elem);
          });
        }
      }, 3000);
    }
  }

  /**
   * Manages the state of the check boxes upon selection.
   *
   * @param {string} [id='']
   * @param {number} [index=100]
   * @param {Event} event
   * @memberof GhsSamplePackComponent
   */
  checkSelectedSourceIds(
    id: string = '',
    index: number = 100,
    event: any = {}
  ) {
    let isSelected = event.checked;

    if (this.sourceidarray !== undefined) {
      const newAry = this.arySourceId.map((element) => {
        if (element.id === id) {
          return { ...element, selected: isSelected };
        } else {
          return element;
        }
      });

      this.arySourceId = newAry;
      this.subTypeSet = new Set();
      this.sourceIdSet = new Set();
      this.subTypeStr = '';
      this.sourceIdStr = '';
      let initSw = true;
      this.isSourceIdSelected = false;
      this.arySourceId.map((element) => {
        if (element.selected && initSw) {
          this.isSourceIdSelected = true;
          initSw = false;
          this.subTypeSet = this.subTypeSet.add(element.subtype);
          this.sourceIdSet = this.sourceIdSet.add(element.id);
        } else if (element.selected) {
          this.subTypeSet = this.subTypeSet.add(element.subtype);
          this.sourceIdSet = this.sourceIdSet.add(element.id);
        }
      });
      this.subTypeStr = [...this.subTypeSet].toString();
      this.sourceIdStr = [...this.sourceIdSet].toString();
      this.sourceid = this.sourceIdStr;
      this.subType = this.subTypeStr;
    }
  }
}
