import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeSamplePackModalComponent } from './free-sample-pack-modal.component';
import { FreeSamplePackModule } from '../free-sample-pack/free-sample-pack.module';
import { ModalModule } from '../modal/modal.module';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';

@NgModule({
  declarations: [FreeSamplePackModalComponent],
  imports: [CommonModule, FreeSamplePackModule, ModalModule],
  exports: [FreeSamplePackModalComponent],
  providers: [AvryModalService],
})
export class FreeSamplePackModalModule {}
