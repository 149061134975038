import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { pushDataLayer } from '../../helpers/googleAnalytics';
import { environment } from '../../../../environments/environment';
import { ModalService } from '../modal/modal.service';
import { ModalSize } from 'navigation/nav-shared/models/types';

@Component({
  selector: 'app-free-sample-pack-complete-modal',
  templateUrl: './free-sample-pack-complete-modal.component.html',
  styleUrls: ['./free-sample-pack-complete-modal.component.scss'],
})
export class FreeSamplePackCompleteModalComponent
  implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() size: ModalSize;
  @Input() utilClasses: string;

  @Input() errorCode: string;
  @Input() samplePkType: string;
  @Input() modalCompletionType: string;

  mediaUrl: string;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.mediaUrl = `url(${environment.image}/f_auto,q_auto,c_scale,w_450,h_420/web/weprint/home/wpr_sample_form.jpg)`;

    pushDataLayer({
      event: 'weprint-sample-request',
      sampleType: this.samplePkType,
    });
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  dismissModal() {
    this.modalService.close(this.id);
  }
}
