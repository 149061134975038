import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCardsModalComponent } from './custom-cards-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [CustomCardsModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [CustomCardsModalComponent],
})
export class CustomCardsModalModule {}
