import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteLogoComponent } from './site-logo.component';
import { AcgModule } from '../acg/acg.module';

@NgModule({
  declarations: [SiteLogoComponent],
  imports: [CommonModule, AcgModule],
  exports: [SiteLogoComponent],
})
export class SiteLogoModule {}
