import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Modules
import { FooterModule } from '../navigation/footer/footer.module';
import { HeaderModule } from '../navigation/header/header.module';

// Services and reducer configs
import { AuthenticationService } from '../navigation/nav-shared/authentication.service';
import { reducerToken, reducerProvider } from '../ngrx/reducers/index';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HeaderModule,
    FooterModule,
    NgbModule,
    StoreModule.forRoot(reducerToken),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthenticationService, reducerProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
