import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModalComponent } from './generic-modal.component';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [GenericModalComponent],
  imports: [CommonModule, ContentLoaderModule, ModalModule],
  exports: [GenericModalComponent],
})
export class GenericModalModule {}
