import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { config, environment } from '../../environments/environment';
import {
  defaultTitles,
  industrialTitles,
} from '../nav-shared/search-filter-titles';

@Injectable()
export class HeaderService {
  magentoUrl: string = environment.magento;

  navMenuActive = new BehaviorSubject<boolean>(false);
  megaMenuActive = new BehaviorSubject<boolean>(false);
  mobileNavActive = new BehaviorSubject<boolean>(false);
  myAccountActive = new BehaviorSubject<boolean>(false);
  searchActive = new BehaviorSubject<boolean>(false);
  shadowOverlay = new BehaviorSubject<boolean>(false);
  accordionActive = new BehaviorSubject<boolean>(false);
  pushMenu = new BehaviorSubject<boolean>(false);

  // emit any data to this BS so SearchComponent can display results
  searchData = new Subject<any>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  /**
   * Fetches cart item from magento to display in the header
   *
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  fetchCartItemCount(): Observable<any> {
    return this.http.get(
      `${this.magentoUrl}/avery_connect/checkout_cart/itemsCount`,
      {
        withCredentials: true,
        responseType: 'text',
      }
    );
  }

  /**
   * Makes an Elastic Search call to get site search data. Used for both the tiles and the links (individually)
   *
   * @param {string} query
   * @param {number} [from=0]
   * @param {number} [size=12]
   * @param {string} [category='indices']
   * @param {string} [themeMode='default']
   * @param {boolean} [skuSearch=false]
   * @param {boolean} [links=false]
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  siteSearch(
    query: string,
    from: number = 0,
    size: number = 12,
    category: string = 'indices',
    themeMode: string = 'default',
    skuSearch: boolean = false,
    links: boolean = false
  ): Observable<any> {
    const searchFilters =
      themeMode === 'industrial' ? industrialTitles : defaultTitles;
    const url = `${environment.elasticSearch}/${config.env}_${category}/_search`;
    const body: any = {
      from,
      size,
      query: {
        bool: {
          must: [
            {
              multi_match: {
                query,
                analyzer: 'avery',
                type: 'most_fields',
                fields: [
                  'contentType^2',
                  'description',
                  'difyProductNumber',
                  'metaTitle',
                  'productDisplayName^2',
                  'productNumber^2',
                  'searchKeywords',
                  'stockNumber',
                  'title',
                  'trademarkTemplateNumber',
                  'upcNumber',
                ],
              },
            },
            // TODO: remove this when industrial is phased out
            { terms: { configType: [themeMode, 'all'] } },
          ],
        },
      },
      aggs: {
        interactions: {
          adjacency_matrix: {
            filters: {
              [`${searchFilters.BlankLabels}`]: {
                bool: {
                  must: [
                    { terms: { _index: [`${config.env}_blanklabels`] } },
                    { terms: { configType: [themeMode] } },
                  ],
                },
              },
              [`${searchFilters.Ideas}`]: {
                terms: {
                  _index: [`${config.env}_articles`],
                },
              },
              [`${searchFilters.Predesigns}`]: {
                terms: {
                  _index: [`${config.env}_predesigns`],
                },
              },
              [`${searchFilters.Products}`]: {
                terms: {
                  _index: [`${config.env}_products`],
                },
              },
              [`${searchFilters.Templates}`]: {
                bool: {
                  must: [
                    { terms: { _index: [`${config.env}_templates`] } },
                    { terms: { configType: [themeMode, 'all'] } },
                  ],
                },
              },
            },
          },
        },
      },
      post_filter: {
        bool: {
          should: [
            {
              bool: {
                must: [
                  { terms: { _index: [`${config.env}_templates`] } },
                  { terms: { configType: [themeMode, 'all'] } },
                ],
              },
            },
            {
              bool: {
                must: [
                  { terms: { _index: [`${config.env}_blanklabels`] } },
                  { terms: { configType: [themeMode] } },
                ],
              },
            },
            {
              bool: {
                must: [{ terms: { _index: [`${config.env}_products`] } }],
              },
            },
            {
              bool: {
                must: [{ terms: { _index: [`${config.env}_predesigns`] } }],
              },
            },
            {
              bool: {
                must: [{ terms: { _index: [`${config.env}_articles`] } }],
              },
            },
            {
              bool: {
                must: [{ terms: { _index: [`${config.env}_searchtiles`] } }],
              },
            },
          ],
        },
      },
      indices_boost: [{ prd_searchtiles: 3 }],
    };

    if (!skuSearch) {
      // if search type is "keyword" (and not "sku"), then detect if there is a dimension in the search term
      body.query.bool.must[0].multi_match['fuzziness'] = 1;
      const decimal = /(\d+.?\d*("|”| ?in)?)[^a-z]?/gi;
      const fraction = /(\d*[ |-]?\d+[/]\d+("|”| ?in)?)[^a-z]?/gi;
      const decimal2D = /(\d+(.?\d*)?("|”| ?in)?)( ?x ?)?(\d+(.?\d*)?("|”| ?in[^a-z])?)/gi;
      const fraction2D = /((\d?[ | -]?\d+\/\d+)|\d+)("|”|( ?in))?( ?x ?)?(((\d?[ | -]?\d+\/\d+)|\d+)("|”| ?in[^a-z])?)/gi;

      if (decimal.test(query) || fraction.test(query)) {
        const match = query.includes('/')
          ? fraction2D.exec(query)
          : decimal2D.exec(query);
        if (match) {
          const [, nonDimensionalQuery] = query.split(match[0].trim());

          if (nonDimensionalQuery) {
            body.query.bool.must[0].multi_match.query = nonDimensionalQuery.trim();
          } else if (query.includes('x')) {
            // separate h x w if no spaces
            const [x, y] = query.split('x');
            body.query.bool.must[0].multi_match.query = `${x.trim()} x ${y.trim()}`;
          }

          let [x, y]: any = match[0].trim().split(/ ?x ?/);
          if (!y) {
            y = x;
          }

          // Height/width properties in dotcms content types are in decimal
          x = this.fractionToDecimal(x);
          y = this.fractionToDecimal(y);

          body.query.bool.filter = [
            {
              bool: {
                should: [
                  {
                    bool: {
                      must: [
                        {
                          range: {
                            width: {
                              from: x - 0.125,
                              to: x + 0.125,
                            },
                          },
                        },
                        {
                          range: {
                            height: {
                              from: y - 0.125,
                              to: y + 0.125,
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ];

          // If height and width differ, then we need to add a second set of ranges
          if (x !== y) {
            body.query.bool.filter[0].bool.should.push({
              bool: {
                must: [
                  {
                    range: {
                      width: {
                        from: y - 0.125,
                        to: y + 0.125,
                      },
                    },
                  },
                  {
                    range: {
                      height: {
                        from: x - 0.125,
                        to: x + 0.125,
                      },
                    },
                  },
                ],
              },
            });
          }
        }
      }
    }

    if (links) {
      // add more properties to a link search
      body['indices_boost'] = [
        { [`${config.env}_articles`]: 0 },
        { [`${config.env}_products`]: 2.0 },
      ];
    } else {
      // add more properties to an indices search, searchtiles required for tile data
      body['indices_boost'] = [{ [`${config.env}_searchtiles`]: 3.0 }];
    }

    if (
      themeMode === 'industrial' &&
      (category.includes('links') || !skuSearch)
    ) {
      body.query.bool = {
        ...body.query.bool,
        must_not: [{ term: { configType: 'default' } }],
      };
    }

    return this.http.post(url, body);
  }

  /**
   * Turns fraction into a number
   *
   * @param {string} num
   * @returns {number}
   * @memberof HeaderService
   */
  fractionToDecimal(num: string): number {
    if (num.includes('/')) {
      const dimension = num.split(/-| /);

      // Fraction
      if (dimension.length === 1) {
        const [numerator, denominator] = dimension[0].split('/');
        return Number(numerator) / Number(denominator);
      }

      // Whole number + fraction
      const [numerator, denominator] = dimension[1].split('/');
      return Number(dimension[0]) + Number(numerator) / Number(denominator);
    }

    return Number(num);
  }

  /**
   * This function sanitizes the input URL using Angular core SecurityContext URL enum.
   *
   * @param {string} urlInput
   * @returns {SafeUrl}
   * @memberof HeaderService
   */
  sanitizeURL(urlInput: string): SafeUrl {
    return this.sanitizer.sanitize(SecurityContext.URL, urlInput);
  }

  /**
   * This function makes an API call to magento which is fulfilling the customer request
   *  for free sample pack.
   *
   * @param {string} order
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  samplePackOrderService(order: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers };
    return this.http.post(
      `${environment.magento}/samplepack/order/submit`,
      order,
      options
    );
  }

  /**
   * This function makes an API call to persist the customer information into the dynamoDb.
   *
   * @param {any} order
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  samplePackCustomerService(order: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/create-sample-pack`,
      order,
      options
    );
  }

  /**
   * Fetches the passed identifier data and all related contents data.
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  getContentRelationshipData(id: string): Observable<any> {
    const options = {
      withCredentials: true,
    };

    return this.http.get(
      `${environment.domain}/api/v1/contentrelationships/id/${id}`,
      options
    );
  }

  /**
   *  This function request an access token with sugarcrm api.
   *
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  getSugarCrmToken(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/sugar-crm-api-login`,
      options
    );
  }

  /**
   * This function posts a sample pack request with sugarcrm api.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  postSugarCrmSampleRequest(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/sugar-crm-api-post-sample-request`,
      obj,
      options
    );
  }

  /**
   * This function posts a quote request with sugarcrm api.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  postSugarCrmQuoteRequest(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/post-quote-request`,
      obj,
      options
    );
  }

  /**
   * This function posts a web case email us request with sugarcrm api.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  postSugarCrmWebCaseRequest(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/post-case-request`,
      obj,
      options
    );
  }

  /**
   * This function publishes a message to a sample pack queue.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  addSampleQueue(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/publish-to-queue`,
      obj,
      options
    );
  }

  /**
   * This function publishes a message to a sample pack sns topic.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  addSampleToTopic(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/publish-to-topic`,
      obj,
      options
    );
  }

  /**
   * This function publishes a message to a screening product topic.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  postScreeningRequest2Topic(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/pub-screening-product-topic`,
      obj,
      options
    );
  }

  /**
   * This function publishes a message to a sample pack queue.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  pubSugarcrmStandaloneQueue(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${environment.sampleService}/pub-sugarcrm-standalone-queue`,
      obj,
      options
    );
  }

  /**
   * Get custom template from DPO
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof HeaderService
   */
  getTemplateId(id: string): Observable<any> {
    const params = new HttpParams()
      .set('search', id)
      .append('consumer', 'Avery')
      .append('deploymentId', environment.dpoDeploymentID);

    return this.http.get(
      `${environment.dpoDomain}/dpp/public/v4/template-ids/search`,
      { params }
    );
  }

  /**
   * Remove presta and strip out &reg and &trademark characters in search term
   *
   * @param {string} query
   * @returns {string}
   * @memberof SearchService
   */
  sanitizePrestaSearch(query: string): string {
    const regex = /(presta)[™|®|©|&trade;|&reg;|&copy;|&#8482;|&#174;|&#169;]* /;
    return query.replace(regex, '');
  }
}
