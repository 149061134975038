import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
import * as localForage from 'localforage';

// Services
import { HeaderService } from '../header.service';
import { NavigationService } from 'navigation/nav-shared/navigation.service';

// Helpers, Environment, etc.
import { config, environment } from 'environments/environment';
import { getQueryParamByName } from 'navigation/nav-shared/helpers';
import { AutoNavigationConfig } from 'navigation/nav-shared/models/interfaces/generic';
import { HttpClient } from '@angular/common/http';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';

@Component({
  selector: 'header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnChanges, OnInit, OnDestroy {
  @Input() mobileSearchField: boolean;

  @ViewChild('headerSearchInput', { static: false })
  headerSearchInput: ElementRef;

  private averyCacheInst: LocalForage = localForage.createInstance({
    name: 'Avery Cache',
  });
  category: string = 'indices';
  keySearch: object[];
  keywords: string[] = [];
  myAccountOpen: boolean = false;
  navMenuActive: boolean = false;
  searchTerm: string;
  subscriptions: Subscription[];
  themeMode: string = 'default';

  constructor(
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private http: HttpClient,
    private navDataService: NavDataService
  ) {}

  @HostListener('window:popstate')
  onPopState() {
    // reload header search when user click back button to go back to search page
    if (window.location.pathname.includes('/srch')) {
      location.reload();
    }
  }

  ngOnChanges() {
    if (this.mobileSearchField) {
      this.headerSearchInput.nativeElement.focus();
      this.searchTerm = '';
    }
  }

  ngOnInit() {
    // FIXME: temp solution to resolve angular routing bug within non-angular apps
    // This along with the entire header needs to be refactored!
    if (window.location.pathname.includes('/srch')) {
      setTimeout(() => {
        this.headerSearch();
      }, 1000);
    }
    this.subscriptions = [
      this.navMenuActiveSubscription(),
      this.myAccountActiveSubscription(),
      this.themeModeSubscription(),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  /**
   * Initial operation to sanitize search term and route checking before submitting search
   */
  headerSearch() {
    if (!window.location.pathname.includes('/srch')) {
      this.searchTerm = '';
    } else {
      this.searchTerm = getQueryParamByName('q');
      if (this.searchTerm.includes('presta')) {
        this.searchTerm = this.headerService.sanitizePrestaSearch(
          this.searchTerm
        );
      }
      if (!isEmpty(this.searchTerm)) {
        this.submitSearch(this.searchTerm);
      }
    }
  }

  /**
   * Check if the Mega Menu is active
   *
   * @returns {subscription}
   * @memberof HeaderSearchComponent
   */
  navMenuActiveSubscription(): Subscription {
    return this.headerService.navMenuActive.subscribe((value) => {
      this.navMenuActive = value;
    });
  }

  /**
   * Return subscription for My Account Menu Activation.
   *
   * @returns {Subscription}
   * @memberof HeaderSearchComponent
   */
  myAccountActiveSubscription(): Subscription {
    return this.headerService.myAccountActive.subscribe(
      (value) => (this.myAccountOpen = value)
    );
  }

  /**
   * Subscription to get the theme mode
   *
   * @returns {Subscription}
   * @memberof HeaderSearchComponent
   */
  themeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe((theme: string) => {
      this.themeMode = theme;
      this.category = theme === 'industrial' ? 'indices_industrial' : 'indices';
    });
  }

  /**
   * Get/update keyword redirect values
   *
   * @memberof HeaderSearchComponent
   */
  fetchKeywordRedirects() {
    const fetchData = (): Promise<AutoNavigationConfig[] | null> => {
      return this.http
        .get<AutoNavigationConfig[]>(
          `${environment.domain}/rest/auto-navigation-config`
        )
        .pipe(
          take(1),
          tap((payload: AutoNavigationConfig[]) =>
            this.averyCacheInst
              .setItem('keywordRedirects', payload)
              .catch((err) => {
                console.error('Error caching data');
                console.error(err);
              })
          ),
          map((payload: AutoNavigationConfig[]) => {
            return payload;
          })
        )
        .toPromise<AutoNavigationConfig[]>();
    };

    this.navDataService
      .fetchDotCMSData<AutoNavigationConfig[]>('keywordRedirects', fetchData)
      .then((data) => {
        this.keySearch = data;
        this.keywords = data.map((pair: { key: any }) => pair.key);
      })
      .catch((err) => console.error(err));
  }

  /**
   * Determines whether the search should be a sku search, a keyword search, or a redirect.
   *
   * @param {string} searchTerm
   * @memberof HeaderSearchComponent
   */
  submitSearch(searchTerm: string) {
    const trimmedTerm = searchTerm.trim();

    if (!isEmpty(trimmedTerm)) {
      this.checkRedirect(trimmedTerm);
      const templateIdRegex = /(t|s)([a-z]|\d){2}-?([a-z]|\d){3}/i;

      if (templateIdRegex.test(trimmedTerm)) {
        const templateId = trimmedTerm
          .replace(/i|l/gi, '1')
          .replace(/o/gi, '0');
        this.headerService
          .getTemplateId(templateId)
          .pipe(filter((data) => !isEmpty(data)))
          .subscribe((_data) => {
            window.location.href = `/templates/custom/presta-${templateId}`;
            return;
          });
      }

      const term = trimmedTerm
        .replace(/avery/gi, '')
        .replace(/"/g, '')
        .replace(/@/g, ' ')
        .replace(/\s+/, ' ')
        .toLowerCase()
        .trim();
      const possibleSkus = term.match(/[^.][0-9]{3,5}/g);
      let skus = [];

      if (!isEmpty(possibleSkus)) {
        skus = possibleSkus.filter((sku) => sku.length >= 3 && sku.length <= 5);
      }

      if (!isEmpty(skus)) {
        this.skuSearch(skus.shift(), term);
      } else {
        this.keywordSearch(term);
      }

      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Checks if a search term is a part of the redirect keywords. Will navigate if so
   *
   * @param {string} searchTerm
   * @memberof HeaderSearchComponent
   */
  checkRedirect(searchTerm: string): Promise<boolean> {
    const searchIndex = this.keywords.indexOf(searchTerm.toLowerCase());

    if (searchIndex > -1) {
      window.location.href = `${environment.domain}${this.keySearch[searchIndex]['value']}`;
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }

  /**
   * Makes a request for a sku search and then will navigate to the according page. If there are no
   * results then a keyword search is utilized instead
   *
   * @param {string} sku
   * @param {string} term
   * @memberof HeaderSearchComponent
   */
  skuSearch(sku: string, term: string) {
    this.headerService
      .siteSearch(sku, 0, 12, this.category, this.themeMode, true)
      .subscribe((results) => {
        const searchResults = results.hits.hits.filter((result: any) => {
          return (
            result._index === `${config.env}_products` ||
            result._index === `${config.env}_blanklabels` ||
            result._index === `${config.env}_templates`
          );
        });

        if (!isEmpty(searchResults) || sku.length === 3) {
          results.type = {
            originalType: 'sku',
            finalType: 'sku',
          };
          this.headerService.searchData.next(results);
        } else {
          this.keywordSearch(term, true);
        }
      });
  }

  /**
   * Makes the request for a keyword search and then will navigate to the according page. Also used
   * as a fallback for sku search
   *
   * @param {string} term
   * @param {boolean} [sku=false]
   * @memberof HeaderSearchComponent
   */
  keywordSearch(term: string, sku: boolean = false) {
    this.headerService
      .siteSearch(term, 0, 12, this.category, this.themeMode)
      .subscribe((results) => {
        if (
          !isEmpty(results.hits.hits) &&
          (results.hits.hits[0]._source.category === 'International' ||
            results.hits.hits[0]._source.category === 'AveryPro')
        ) {
          // TODO: use a service to handle the window object (for ssr)
          window.location.href = results.hits.hits[0]._source.link;
        }

        results.type = sku
          ? { originalType: 'sku', finalType: 'keyword' }
          : { originalType: 'keyword', finalType: 'keyword' };
        this.headerService.searchData.next(results);
      });
  }

  /**
   * close other nav elements that might be open
   *
   * @memberof HeaderSearchComponent
   */
  closeNav() {
    this.headerService.myAccountActive.next(false);
    this.headerService.navMenuActive.next(false);
  }

  /**
   * Navigates to search page
   *
   * @param {string} term
   * @memberof HeaderSearchComponent
   */
  async navigateToSearchPage(term: string) {
    if (!isEmpty(term.trim())) {
      term = term.toLowerCase().replace('@', ' ');
      const containsRedirect = await this.checkRedirect(term);

      if (containsRedirect) {
        return;
      }
      window.location.href = `${environment.domain}/srch?q=${term}`;
    }
  }

  fetchAutoNavigationConfig() {
    this.fetchKeywordRedirects();
  }
}
