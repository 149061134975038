import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';

import { environment } from '../../../../../environments/environment';
import { HeaderService } from '../../../../header/header.service';
import { addDashesToPhone } from '../../../helpers';
import { emailValidator, isValidName } from '../../../helpers/customValidators';
import { WebcaseEmailusCompleteModalComponent } from './webcase-emailus-complete-modal/webcase-emailus-complete-modal.component';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import { ModalService } from '../../modal/modal.service';
import { ModalSize } from 'navigation/nav-shared/models/types';

@Component({
  selector: 'app-webcase-email-us-form',
  templateUrl: './webcase-email-us-form.component.html',
})
export class WebcaseEmailUsFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string = 'webcase-email-us-form-modal';
  @Input() size: ModalSize = 'lg';

  @Input() type: string;
  @Output() oCompleteStatus = new EventEmitter<string>();

  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer;

  descText: string;
  imgUrl: string = `${environment.image}/f_auto,q_auto,c_scale,w_300/web/about-us/tile-avery-logo-cropped`;
  phone: string;
  showLimitContactName: boolean;
  subscriptions: Array<Subscription> = [];
  validContactName: boolean = true;
  webCaseEmailUsForm: FormGroup;

  // Message Flags
  formBtnSubmitted: boolean = false;
  formLoader: boolean = false;
  isInvalidFormMessage: boolean = false;
  isVisible: boolean = false;

  // Required Field Form Flags
  isInvalidCheckName: boolean = false;
  isInvalidCheckEmail: boolean = false;
  isInvalidCheckPhone: boolean = false;

  // For Phone Numbers
  isPhoneNumberFormatMessage: boolean = false;

  // is used to pass data to modal
  modalCompletionType: string = '';

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private avryModalService: AvryModalService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.subscriptions = this.createWebCaseEmailUsForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  /**
   * Initializes the form controls and returns an array of subscription for all
   * value changes on some of the form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof WebcaseEmailUsFormComponent
   */
  createWebCaseEmailUsForm(): Array<Subscription> {
    this.webCaseEmailUsForm = this.fb.group({
      contactName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ]),
      ],
      emailAddress: [
        '',
        Validators.compose([Validators.required, emailValidator]),
      ],
      phoneNumber: ['', Validators.compose([Validators.required])],
      subject: [''],
      descriptionInput: [''],
    });
    return [
      this.emailUsContactName.valueChanges.subscribe((data) => {
        if (data.length > 100) {
          this.showLimitContactName = true;
        } else {
          this.showLimitContactName = false;
        }
        this.validContactName = isValidName(data);
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive email us form
   *
   * @return {AbstractControl | null}
   */
  get emailUsContactName() {
    return this.webCaseEmailUsForm.get('contactName');
  }
  get emailUsEmailAddress() {
    return this.webCaseEmailUsForm.get('emailAddress');
  }
  get emailUsPhoneNumber() {
    return this.webCaseEmailUsForm.get('phoneNumber');
  }
  get emailUsSubject() {
    return this.webCaseEmailUsForm.get('subject');
  }

  /**
   * Checks whether to display Invalid Email Us form message.
   *
   * @memberof WebcaseEmailUsFormComponent
   */
  checkFormValidationMessage() {
    if (this.webCaseEmailUsForm.valid) {
      this.isInvalidFormMessage = false;
    } else {
      this.isInvalidFormMessage = true;
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   * If no parameter is supplied, it will check all Sample Pack required form fields.
   *
   * @param {string} formControlName [Optional]
   * @memberof WebcaseEmailUsFormComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'contactName':
        this.isInvalidCheckName = !this.emailUsContactName.valid;
        break;
      case 'emailAddress':
        this.isInvalidCheckEmail = !this.emailUsEmailAddress.valid;
        break;
      case 'phoneNumber':
        this.isInvalidCheckPhone = !this.emailUsPhoneNumber.valid;
        break;
      default:
        // Check All of the the Required Fields:
        this.isInvalidCheckName = !this.emailUsContactName.valid;
        this.isInvalidCheckEmail = !this.emailUsEmailAddress.valid;
        this.isInvalidCheckPhone = !this.emailUsPhoneNumber.valid;
    }
  }

  /**
   * This function adds dashes for the entered phone number which in this case 2 dashes.
   *
   * @param {Event} event
   * @memberof WebcaseEmailUsFormComponent
   */
  dashPhone(event: Event) {
    let phone = (event.target as HTMLInputElement).value;
    this.phone = addDashesToPhone(phone);

    // Updates the form with the latest value of phone after the reformat.
    this.emailUsPhoneNumber.setValue(this.phone);
  }

  /**
   * Verifies that the Phone number follows the specified format.
   * It will clear out the user input if invalid, because the phone number is optional.
   *
   * @memberof WebcaseEmailUsFormComponent
   */
  formatPhone() {
    const phone = this.emailUsPhoneNumber.value;
    if (phone.length === 0 || phone.length === 12) {
      this.isPhoneNumberFormatMessage = false;
    } else if (phone.length < 12) {
      this.isPhoneNumberFormatMessage = true;
    }
  }

  /**
   * Form Submit Method
   *
   * @memberof WebcaseEmailUsFormComponent
   */
  wceuSubmit() {
    this.checkFormValidationMessage();

    if (
      this.webCaseEmailUsForm.valid &&
      this.validContactName &&
      this.validContactName
    ) {
      if (!isEmpty(this.webCaseEmailUsForm)) {
        this.formBtnSubmitted = true;
        this.formLoader = true;
        this.isVisible = true;

        const reqObj = this.setEmailServiceFormat(this.webCaseEmailUsForm);
        this.headerService.postSugarCrmWebCaseRequest(reqObj).subscribe(
          () => {
            this.openCompleteModal('ok');
          },
          (err: HttpErrorResponse) => {
            console.error(err.error);
            this.openCompleteModal('error');
          }
        );
      }
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * Gets the value entered from the description textarea field.
   *
   * @param {*} descInput
   * @memberof WebcaseEmailUsFormComponent
   */
  getDescription(descInput) {
    this.descText = descInput;
  }

  /**
   * This function opens a completion modal to render the status of the request.
   *
   * @param {string} modalCompletionType
   * @memberof WebcaseEmailUsFormComponent
   */
  openCompleteModal(modalCompletionType: string) {
    // turns off the spinner
    this.isVisible = false;

    if (isEmpty(this.type)) {
      const modalRef = this.avryModalService.init(
        this.modalContainer,
        WebcaseEmailusCompleteModalComponent,
        'webcase-email-us-complete-modal',
        'md'
      );
      modalRef.instance.modalCompletionType = modalCompletionType;
      this.dismissModal();
    } else {
      this.oCompleteStatus.emit(modalCompletionType);
    }
  }

  /**
   * Formats data for sugarcrm.
   *
   * @param {FormGroup} form
   * @returns {any}
   * @memberof WebcaseEmailUsFormComponent
   */
  setEmailServiceFormat(form: FormGroup): any {
    return {
      contactname: this.emailUsContactName.value,
      email: this.emailUsEmailAddress.value,
      phone: this.emailUsPhoneNumber.value || '',
      subject: this.emailUsSubject.value || '',
      descriptionInput: this.descText || '',
    };
  }

  dismissModal() {
    this.modalService.close(this.id);
  }
}
