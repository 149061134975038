import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnMenuComponent } from './column-menu.component';
import { AcgModule } from '../../../nav-shared/components/acg/acg.module';

@NgModule({
  declarations: [ColumnMenuComponent],
  imports: [AcgModule, CommonModule],
  exports: [ColumnMenuComponent],
})
export class ColumnMenuModule {}
