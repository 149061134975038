import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '../../../helpers';
import isEmpty from 'lodash-es/isEmpty';
import { AuthenticationService } from '../../../authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../../navigation.service';

@Component({
  selector: 'app-proxy-sign-in-form',
  templateUrl: './proxy-sign-in-form.component.html',
  styles: [
    `
      .cta {
        width: 200px;
      }
      .tooltip {
        padding: 13px 15px;
        width: 120px;
      }
    `,
  ],
})
export class ProxySignInFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() formType: string = '';
  @Input() guestForm: boolean;
  @Input() queryParams: any;
  @Input() registerOnSignIn: boolean = false;
  @Input() showResetMsg: boolean;

  @Output() formMode = new EventEmitter();
  @Output() forwardEmail = new EventEmitter<string>();

  emailFocus: boolean = false;
  formTitle: string = 'Enter Email';
  signInModalForm: FormGroup;
  subscriptions: Subscription[] = [];
  themeMode: string = null;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private navService: NavigationService
  ) {}

  ngOnChanges() {
    if (!isEmpty(this.formType) && this.formType === 'proxy-download') {
      this.formTitle = 'Enter your email address';
    }
  }

  ngOnInit() {
    this.createSignInModalForm();

    this.subscriptions = [this.averyThemeModeSubscription()];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Return subscription for the theme mode
   *
   * @returns {Subscription}
   * @memberof ProxySignInFormComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navService.themeMode.subscribe((type: string) => {
      this.themeMode = type;
    });
  }

  /**
   * Handles creating the sign-in modal form. This form is a proxy to the sign-in form.
   *
   * @memberOf ProxySignInFormComponent
   */
  createSignInModalForm() {
    this.signInModalForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf ProxySignInFormComponent
   */
  get signInEmail() {
    return this.signInModalForm.get('email');
  }

  /**
   * Handles updating the sign-in form with a valid email.
   *
   * @memberOf ProxySignInFormComponent
   */
  onSubmit() {
    if (this.signInModalForm.valid) {
      this.authenticationService
        .validateUserExists(this.signInEmail.value.trim())
        .subscribe(
          (results) => {
            this.forwardEmail.emit(this.signInEmail.value);
            this.renderForm('sign-in');
          },
          (err: HttpErrorResponse) => {
            console.error(err.error);
            this.forwardEmail.emit(this.signInEmail.value);
            this.renderForm('register-user');
          }
        );
    }
  }

  /**
   * Sets the form mode for the download modal.
   *
   * @param {string} formMode
   * @memberOf ProxySignInFormComponent
   */
  renderForm(formMode: string) {
    this.formMode.emit(formMode);
  }

  /**
   * Toggles the boolean variable that keeps track of the email input focus.
   *
   * @memberOf ProxySignInFormComponent
   */
  changeFocus() {
    if (!this.emailFocus) {
      this.emailFocus = true;
    } else {
      this.emailFocus = false;
    }
  }
}
