export const defaultTitles = {
  BlankLabels: 'Blank Labels',
  Ideas: 'Ideas',
  Help: 'Help',
  Predesigns: 'Template Galleries',
  Products: 'In-Store Products',
  Templates: 'Templates',
};

export const industrialTitles = {
  BlankLabels: 'Blank Labels',
  Ideas: 'Articles',
  Help: 'Help',
  Predesigns: 'Template Galleries',
  Products: 'In-Store Products',
  Templates: 'Templates',
};
