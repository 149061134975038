import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import has from 'lodash-es/has';
import unescape from 'lodash-es/unescape';
import { ModalSize } from 'navigation/nav-shared/models/types';

import { environment } from '../../../../environments/environment';

// Interfaces
import { GenericModal } from '../../models/interfaces/modal.interface';

// Services
import { NavigationService } from '../../navigation.service';
import { ModalService } from '../modal/modal.service';

interface DataACG {
  name: string;
  title: string;
  body: string;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() size: ModalSize;
  @Input() genericModalContent: GenericModal;
  @Input() utilClasses: string;

  isLoading: boolean = true;
  modalTitle: string = '';
  modalContent: SafeHtml;

  constructor(
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    // If `ACG type and URL` is only supplied (without `img` and `imgTitle`), then show ACG in the modal.
    // NOTE: `null` values are assigned in dotCMS end.
    if (
      this.genericModalContent.acgType !== null &&
      this.genericModalContent.acgURL !== null &&
      (!this.genericModalContent.hasOwnProperty('img') ||
        this.genericModalContent.img === null) &&
      (!this.genericModalContent.hasOwnProperty('imgTitle') ||
        this.genericModalContent.imgTitle === null)
    ) {
      this.navigationService
        .fetchGeneralModalData(
          this.genericModalContent.acgType,
          this.genericModalContent.acgURL
        )
        .subscribe((data: DataACG) => {
          this.modalContent = this.generateModalContent(data);
        });
      // Else If `img` is only supplied (without both `ACG type and URL`), then show an image and title[optional] in the modal.
    } else if (
      this.genericModalContent.img !== null &&
      this.genericModalContent.acgType === null &&
      this.genericModalContent.acgURL === null
    ) {
      this.generateModalImg(
        this.genericModalContent.img,
        this.genericModalContent.imgTitle
      );
      // Else show a "No Content Found" message in the modal.
    } else {
      this.modalContent = this.sanitizer.bypassSecurityTrustHtml(
        `<div class="text-center"><span>No Content Found</span></div>`
      );
    }

    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  /**
   * Closes Generic Modal
   *
   * @memberof GenericModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }

  /**
   * Generates Generic Modal Content and returns as Sanitized HTML.
   *
   * @param {DataACG} data
   * @return {SafeHtml}
   * @memberof GenericModalComponent
   */
  generateModalContent(data: DataACG): SafeHtml {
    let result = '';

    if (data && has(data, ['title']) && has(data, ['body'])) {
      this.modalTitle = data.title;
      result = unescape(data.body.toString());
    }

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  /**
   * Generates Generic Modal Content as an img with optional title
   *
   * @param {string} imgPath - image path in DAM.
   * @param {string} [imgTitle] - [optional] Title shown in modal.
   * @memberof GenericModalComponent
   */
  generateModalImg(imgPath: string, imgTitle: string = '') {
    this.modalContent = this.sanitizer.bypassSecurityTrustHtml(
      `<div class="p-3 text-center"><img class="img-fluid" src="${environment.image}${imgPath}" alt="${imgTitle}" loading="lazy" /></div>`
    );
    this.modalTitle = imgTitle;
  }
}
