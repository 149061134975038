import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';

// Observables and rxjs
import { BehaviorSubject, Subscription } from 'rxjs';

// Services
import { HeaderService } from '../../../header/header.service';
import { NavigationService } from '../../navigation.service';

// Helpers
import {
  addDashesToPhone,
  emailValidator,
  isValidName,
  zipCodeValidator,
} from '../../helpers';

@Component({
  selector: 'app-industrial-screening-product',
  templateUrl: './industrial-screening-product.component.html',
  styleUrls: ['./industrial-screening-product.component.scss'],
})
export class IndustrialScreeningProductComponent implements OnInit, OnDestroy {
  @Input() acgconfirmationtext: string;
  @Input() acgimagegallery: string;
  @Input() acgproductdesc: string;
  @Input() acgsubtitle: string;
  @Input() completemessagetype: string;
  @Input() greenyellowtxt1: string;
  @Input() greenyellowtxt2: string;
  @Input() messagetypes: string[] = ['industrial'];
  @Input() optintext;
  @Input() post2magento: string;
  @Input() post2samplerequest: string;
  @Input() post2sugarcrm: string;
  @Input() requesttype: string;
  @Input() sendemail2consumer: string;
  @Input() showorigqty: string = 'Y';
  @Input() sourceid: string;
  @Input() subtitle: string;
  @Input() title: string;

  acgConfirmationText: SafeHtml;
  acgImageGallery: SafeHtml;
  acgSubtitle: SafeHtml;
  acgProductDesc: SafeHtml;
  creatingAccount: boolean = false;
  displayError = false;
  emptyPhoneNum: boolean;
  enterPositiveNumberMsg: string = 'Please enter only positive numbers';
  errorBS = new BehaviorSubject<any>({ display: false, message: '' });
  errorFlag: boolean = false;
  fspForm: FormGroup;
  initflag: boolean = true;
  phone: string = '';
  properPhoneLength: boolean = false;
  showLimitFirstName: boolean;
  showLimitLastName: boolean;
  subscriptions: Array<Subscription> = [];
  successFlag: boolean = false;
  thememode: string = 'industrial';
  userFocus: string;
  validFirstName: boolean = true;
  validLastName: boolean = true;

  // Message Flags
  isInvalidFormMessage: boolean = false;
  isVisible: boolean = true;
  isZipFormatMessage: boolean = false;
  samplePackBtnSubmitted: boolean = false;
  samplePackLoader: boolean = false;

  // Required Field Form Flags
  isInvalidCheckName: boolean = false;
  isInvalidCheckLastName: boolean = false;
  isInvalidCheckQuantity: boolean = false;
  isInvalidCheckGreenQuantity: boolean = false;
  isInvalidCheckYellowQuantity: boolean = false;
  isInvalidCheckZip: boolean = false;
  isInvalidCheckEmail: boolean = false;
  isInvalidPhone: boolean = false;

  constructor(
    private fb: FormBuilder,
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fetchAcgData();
    this.subscriptions = this.createFreeSamplePackForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the form controls and returns an array of subscription for all
   * value changes on some of the form controls.
   *
   * @returns {Array<Subscription>}
   * @memberof IndustrialScreeningProductComponent
   */
  createFreeSamplePackForm(): Array<Subscription> {
    this.fspForm = this.fb.group({
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      companyName: [''],
      questionsComments: [''],
      zipCode: [
        '',
        Validators.compose([Validators.required, zipCodeValidator]),
      ],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      optIn: [true],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9,-]*'),
        ]),
      ],
      quantity: [
        '',
        Validators.compose([Validators.required, Validators.minLength(1)]),
      ],
      greenQuantity: ['', Validators.compose([])],
      yellowQuantity: ['', Validators.compose([])],
    });
    return [
      this.firstName.valueChanges.subscribe((data) => {
        this.showLimitFirstName = data.length > 50;
        this.validFirstName = isValidName(data);
      }),
      this.lastName.valueChanges.subscribe((data) => {
        this.showLimitLastName = data.length > 50;
        this.validLastName = isValidName(data);
      }),
      this.phoneNumber.valueChanges.subscribe((data) => {
        this.properPhoneLength = data.length === 12;
        this.emptyPhoneNum = data.length === 0;
      }),
      this.errorBS.subscribe((data: any) => {
        this.displayError = data.display;
      }),
    ];
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf IndustrialScreeningProductComponent
   */
  get newEmail() {
    return this.fspForm.get('email');
  }
  get firstName() {
    return this.fspForm.get('fName');
  }
  get lastName() {
    return this.fspForm.get('lName');
  }
  get companyName() {
    return this.fspForm.get('companyName');
  }
  get questionsComments() {
    return this.fspForm.get('questionsComments');
  }
  get optInCheck() {
    return this.fspForm.get('optIn');
  }
  get phoneNumber() {
    return this.fspForm.get('phone');
  }
  get qtyNumber() {
    return this.fspForm.get('quantity');
  }
  get greenQtyNumber() {
    return this.fspForm.get('greenQuantity');
  }
  get yellowQtyNumber() {
    return this.fspForm.get('yellowQuantity');
  }
  get zip() {
    return this.fspForm.get('zipCode');
  }

  /**
   * Prevent in focus input from validateing until focus changes
   *
   * @param formControl
   * @memberof IndustrialScreeningProductComponent
   */
  formFocus(formControl: string = '') {
    this.userFocus = formControl;
  }

  /**
   * Check input value of quantity Input Field.
   *
   * @param {event}
   * @memberof IndustrialScreeningProductComponent
   */
  checkFormQuantityInput(e: Event) {
    const isInputValid = this.navigationService.numericKeyboard(e);
    this.verifyKeyInput(e, isInputValid);
  }

  /**
   * Validate quantiy on `ngModelChange`.
   *
   * @param {number} quantity
   * @memberof IndustrialScreeningProductComponent
   */
  onQuantityInput(quantity: number, type: string) {
    if (quantity === 0 || quantity < 0 || quantity === null) {
      this.errorBS.next({
        display: true,
        message: this.enterPositiveNumberMsg,
      });
    } else {
      if (type === 'qty1') {
        if (this.qtyNumber.value > 0) {
          this.isInvalidCheckQuantity = false;
        }
        this.errorBS.next({ display: false, message: '' });
      } else if (type === 'qty2') {
        if (this.yellowQtyNumber.value > 0) {
          this.isInvalidCheckYellowQuantity = false;
        } else {
          this.isInvalidCheckYellowQuantity = true;
        }
      } else if (type === 'qty3') {
        if (this.greenQtyNumber.value > 0) {
          this.isInvalidCheckGreenQuantity = false;
        } else {
          this.isInvalidCheckGreenQuantity = true;
        }
      }
    }
  }

  /**
   * Prevent input if key is not a number.
   *
   * @param {event}
   * @param {boolean} isValidKey
   * @memberof IndustrialScreeningProductComponent
   */
  verifyKeyInput(event, isValidKey: boolean) {
    if (isValidKey) {
      this.errorBS.next({ display: false, message: '' });
    } else {
      event.preventDefault();
      this.errorBS.next({
        display: true,
        message: this.enterPositiveNumberMsg,
      });
    }
  }

  /**
   * Fetch static content from ACG dotCMS contents.
   *
   * @memberof IndustrialScreeningProductComponent
   */
  fetchAcgData() {
    if (!isEmpty(this.acgimagegallery)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgimagegallery, 'form')
        .subscribe(
          (data) =>
            (this.acgImageGallery = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgsubtitle)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgsubtitle, 'form')
        .subscribe(
          (data) =>
            (this.acgSubtitle = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgproductdesc)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgproductdesc, 'form')
        .subscribe(
          (data) =>
            (this.acgProductDesc = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
    if (!isEmpty(this.acgconfirmationtext)) {
      this.navigationService
        .fetchAveryCustomGenericContent(this.acgconfirmationtext, 'form')
        .subscribe(
          (data) =>
            (this.acgConfirmationText = this.navigationService.sanitizeContent(
              data.body.toString()
            ))
        );
    }
  }

  /**
   * Checks whether to display Invalid Sample Pack form message.
   *
   * @memberof IndustrialScreeningProductComponent
   */
  checkFormValidationMessage() {
    if (this.fspForm.valid) {
      this.isInvalidFormMessage = false;
    } else {
      this.isInvalidFormMessage = true;
    }
    if (this.sourceid === '22084') {
      // Initialize either yellow or green quantity for invalid.
      this.fspForm.controls['greenQuantity'].setErrors({ incorrect: true });
      this.fspForm.controls['yellowQuantity'].setErrors({ incorrect: true });
      this.fspForm.controls['quantity'].setErrors(null);
      if (this.yellowQtyNumber.value > 0) {
        this.isInvalidCheckYellowQuantity = false;
      }
      if (this.greenQtyNumber.value > 0) {
        this.isInvalidCheckGreenQuantity = false;
      }
      if (
        !this.isInvalidCheckGreenQuantity ||
        !this.isInvalidCheckYellowQuantity
      ) {
        this.isInvalidFormMessage = false;
        this.fspForm.controls['greenQuantity'].setErrors(null);
        this.fspForm.controls['yellowQuantity'].setErrors(null);
      }
    }
  }

  /**
   * Add dashes for entered phone number
   * in this case 2 dashes
   *
   * @param {Event} event
   * @memberof IndustrialScreeningProductComponent
   */
  dashPhone(event: Event) {
    let phone = (event.target as HTMLInputElement).value;
    this.phone = addDashesToPhone(phone);

    // Updates the form with the latest value of phone after the reformat.
    this.fspForm.get('phone').setValue(this.phone);

    this.ref.detectChanges();
  }

  /**
   * Checks if the Zip Code is in the correct US foramt.
   *
   * @memberof IndustrialScreeningProductComponent
   */
  checkZipFormat() {
    if (has(this.fspForm.get('zipCode').errors, 'invalidZip')) {
      this.isZipFormatMessage = this.fspForm.get('zipCode').errors.invalidZip;
    } else {
      this.isZipFormatMessage = false;
    }
  }

  /**
   * Verifies if the Specified Form Control is valid
   * If no parameter is supplied, it will check all Sample Pack required form fields.
   *
   * @param {string} formControlName [Optional]
   * @memberof IndustrialScreeningProductComponent
   */
  checkCurrentRequired(formControlName: string = '') {
    switch (formControlName) {
      case 'firstName':
        this.isInvalidCheckName = !this.firstName.valid;
        break;
      case 'lastName':
        this.isInvalidCheckLastName = !this.lastName.valid;
        break;
      case 'zipCode':
        this.isInvalidCheckZip = !this.zip.valid;
        break;
      case 'email':
        this.isInvalidCheckEmail = !this.newEmail.valid;
        break;
      case 'phone':
        this.isInvalidPhone = !this.phoneNumber.valid;
        break;
      case 'quantity':
        this.isInvalidCheckQuantity = !this.qtyNumber.valid;
        break;
      case 'yellowQuantity':
        this.isInvalidCheckYellowQuantity =
          this.yellowQtyNumber.value > 0 ? false : true;
        break;
      case 'greenQuantity':
        this.isInvalidCheckGreenQuantity =
          this.greenQtyNumber.value > 0 ? false : true;
        break;
      default:
        // Check All of the the Required Fields:
        this.isInvalidCheckName = !this.firstName.valid;
        this.isInvalidCheckLastName = !this.lastName.valid;
        this.isInvalidCheckZip = !this.zip.valid;
        this.isInvalidCheckEmail = !this.newEmail.valid;
        this.isInvalidPhone = !this.phoneNumber.valid;
        this.isInvalidCheckQuantity = !this.qtyNumber.valid;
        this.isInvalidCheckYellowQuantity =
          this.yellowQtyNumber.value > 0 ? false : true;
        this.isInvalidCheckGreenQuantity =
          this.greenQtyNumber.value > 0 ? false : true;
    }
  }

  /**
   * Sample pack submit form function using topic/queue based flow.
   *
   * @memberof IndustrialScreeningProductComponent
   */
  fspSubmitToTopic() {
    this.checkFormValidationMessage();

    if (this.fspForm.valid && this.validFirstName && this.validLastName) {
      this.creatingAccount = true;
      if (!isEmpty(this.fspForm)) {
        this.samplePackBtnSubmitted = true;
        this.samplePackLoader = true;

        // Calls endpoint to publish this request to an SQS queue
        this.headerService
          .postScreeningRequest2Topic(this.setEmailServiceFormat(this.fspForm))
          .subscribe(
            (resp) => {
              if (resp.status === 'error') {
                this.errorFlag = true;
              } else {
                this.successFlag = true;
              }

              this.initflag = false;
              this.isVisible = false;
              setTimeout(() => (this.creatingAccount = false), 500);
            },
            (error) => {
              this.initflag = false;
              this.errorFlag = true;
              this.isVisible = false;
              setTimeout(() => (this.creatingAccount = false), 500);
            }
          );
      }
    } else {
      this.checkCurrentRequired();
    }
  }

  /**
   * This function will structure the form data before publishing it to AWS topic.
   *
   * @param {FormGroup} form
   * @returns {any}
   * @memberof IndustrialScreeningProductComponent
   */
  setEmailServiceFormat(form: FormGroup): any {
    const samplePackData = {
      email: this.newEmail.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      optIn: this.optInCheck.value,
      formType: 'Industrial OrderRequest',
      sourceId: this.sourceid,
      messageTypes: this.messagetypes,
      country: 'US',
      post2Sugarcrm: this.post2sugarcrm,
      post2SampleRequest: this.post2samplerequest,
      post2Magento: this.post2magento,
      quantity: this.qtyNumber.value,
      greenQuantity: this.greenQtyNumber.value,
      yellowQuantity: this.yellowQtyNumber.value,
      questionsComments: this.questionsComments.value,
      phone: this.phoneNumber.value,
      company: this.companyName.value,
      postcode: this.zip.value,
      requestType: this.requesttype,
      sendEmail2Consumer: this.sendemail2consumer,
    };

    return samplePackData;
  }
}
