import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSearchComponent } from './header-search.component';
import { FormsModule } from '@angular/forms';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';

@NgModule({
  declarations: [HeaderSearchComponent],
  imports: [CommonModule, FormsModule],
  exports: [HeaderSearchComponent],
  providers: [NavDataService],
})
export class HeaderSearchModule {}
