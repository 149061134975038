import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import unescape from 'lodash-es/unescape';
import { ModalSize } from 'navigation/nav-shared/models/types';

import { AveryCustomGenericInterface } from '../../models/interfaces';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-custom-generic-modal',
  template: `
    <avry-modal [id]="id" [size]="size" [utilClasses]="utilClasses">
      <div class="modal-content">
        <div class="modal-header" [ngClass]="{ 'border-0': noTitle }">
          <div
            class="h3 modal-title user-no-select"
            id="genericModalTitle"
            *ngIf="!noTitle"
          >
            {{ customGenericModalTitle }}
          </div>

          <button
            type="button"
            class="close fnt-sze-16"
            data-dismiss="modal"
            aria-label="Close"
            (click)="dismissModal()"
          >
            <i class="fonticon fonticon--close" aria-hidden="true"></i>
          </button>
        </div>
        <div
          class="modal-body p-0"
          [innerHTML]="customGenericModalHTMLContent"
        ></div>
      </div>
    </avry-modal>
  `,
})
export class CustomGenericModalComponent implements OnInit, AfterViewInit {
  // specificly used for modal component
  @Input() id: string;
  @Input() size: ModalSize;
  @Input() utilClasses: string;

  @Input() customGenericModalData: AveryCustomGenericInterface;
  @Input() noTitle: boolean = false;

  @Output() onCloseModal = new EventEmitter<boolean>();

  customGenericModalTitle: string;
  customGenericModalHTMLContent: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.sanitizeData();
  }

  ngAfterViewInit() {
    this.modalService.open(this.id);
  }

  /**
   * Get the title and sanitize the html content.
   *
   * @memberof CustomGenericModalComponent
   */
  sanitizeData() {
    this.customGenericModalTitle = this.customGenericModalData.title;
    this.customGenericModalHTMLContent = this.sanitizer.bypassSecurityTrustHtml(
      unescape(this.customGenericModalData.body)
    );
  }

  /**
   * Close the modal
   *
   * @memberof CustomGenericModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }
}
