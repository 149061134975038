import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import { FreeSamplePackCompleteModalComponent } from '../free-sample-pack-complete-modal/free-sample-pack-complete-modal.component';
import { FreeSamplePackageCompleteModal } from '../free-sample-pack-complete-modal/free-sample-pack-complete-modal.model';

import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-free-sample-pack-modal',
  templateUrl: './free-sample-pack-modal.component.html',
})
export class FreeSamplePackModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer;

  @Input() id: string;
  @Input() utilClassess: string = 'free-sample-pack-modal';

  constructor(
    private modalService: ModalService,
    private avryModalService: AvryModalService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.modalService.open(this.id);
  }

  /**
   * Closes Sample Pack Modal
   *
   * @memberof FreeSamplePackModalComponent
   */
  dismissModal() {
    this.modalService.close(this.id);
  }

  onOpenedCompleteModal(evt: FreeSamplePackageCompleteModal) {
    // Opens completion modal
    this.modalService.close(this.id);
    const modalRefInst = this.avryModalService.init(
      this.modalContainer,
      FreeSamplePackCompleteModalComponent,
      'modal-for_free_sample_pack_complete',
      'md'
    ).instance;
    modalRefInst.id = 'modal-for_free_sample_pack_complete';
    modalRefInst.size = 'md';
    modalRefInst.utilClasses = 'modal-avry';

    modalRefInst.modalCompletionType = evt.modalCompletionType;
    modalRefInst.errorCode = evt.errorCode;
    modalRefInst.samplePkType = evt.samplePkType;
  }
}
