import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeSamplePackComponent } from './free-sample-pack.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import { AcgModule } from '../acg/acg.module';

import { FreeSamplePackCompleteModalModule } from '../free-sample-pack-complete-modal/free-sample-pack-complete-modal.module';
import { SafeHtmlModule } from 'navigation/nav-shared/pipes/safe-html/safe-html.module';

@NgModule({
  declarations: [FreeSamplePackComponent],
  imports: [
    AcgModule,
    CommonModule,
    ContentLoaderModule,
    FreeSamplePackCompleteModalModule,

    ReactiveFormsModule,
    SafeHtmlModule,
  ],
  exports: [FreeSamplePackComponent],
})
export class FreeSamplePackModule {}
