import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailQuoteComponent } from './email-quote.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AcgModule } from '../acg/acg.module';
import { ContentLoaderModule } from '../content-loader/content-loader.module';
import { EmailQuoteCompletionModule } from '../email-quote-completion/email-quote-completion.module';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  declarations: [EmailQuoteComponent],
  imports: [
    AcgModule,
    CommonModule,
    ContentLoaderModule,
    EmailQuoteCompletionModule,
    ModalModule,
    ReactiveFormsModule,
  ],
  exports: [EmailQuoteComponent],
})
export class EmailQuoteModule {}
