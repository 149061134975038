import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountFormComponent } from './create-account-form.component';
import { ContentLoaderModule } from '../../content-loader/content-loader.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CreateAccountFormComponent],
  imports: [
    CommonModule,
    ContentLoaderModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [CreateAccountFormComponent],
})
export class CreateAccountFormModule {}
